import React from "react";
import styles from "./ContactsiteConfirm.module.scss";
import { IUploadViewProps, Contents, ContentsValue, industryList, purposeList } from "./Contactsite";
import { useNavigate, useLocation } from "react-router-dom";

import {
  BreadcrumbHeader,
  // Breadcrumb,
  // BreadcrumbList,
} from "../common/components/BreadcrumbHeader";

/* rocketchat 関連 */
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { AwsAmplifyUtils } from "../common/components/AwsAmplifyUtils";
import defInf from "../FAQ/json/define.json";

export var purposeListR = [
  "",
  "APR"
]
var mlogindataList = "";
let xAuthToken: string;
let xAuthUserId: string;
let retLogin: number;
let retCreateUser: number;
let retCreateChannel: number;
let retKickChannel: number;
let retFetchUserId: number;
let rocketUserId: string;
let retFetchRoomId: number;
let rocketRoomId: string;
let retSendMessage: number;
let retSendFile: number;
let retLogOut: number;
let awsuserid = "";


const rocketLogin = async function (user: string, password: string): Promise<string> {
  retLogin = -1;
  const qs = require('qs')
  var data = qs.stringify({
    'user': user,
    'password': password
  });
  // login
  const options: AxiosRequestConfig = {
    method: 'post',
    url: defInf.define1.awsurl + '/api/v1/login',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data: data
  };

  console.log(options);
  await axios(options)
    .then((res: AxiosResponse) => {
      console.log(res);
      console.log(res.data.status);
      xAuthToken = res.data.data.authToken;
      xAuthUserId = res.data.data.userId;
      retLogin = 1;
      return (res.data.status);
    })
    .catch(function (error) {
      console.log(error);
      return ("error");
    });
  return ("");
}


const rocketCreateUser = async function (name: string, email: string, password: string, username: string) {
  retCreateUser = -1;
  const qs = require('qs')
  //var data = qs.stringify({
  var data = ({
    'name': name,
    'email': email,
    'password': password,
    'username': username,
    'roles': ['guest']
  });

  const options: AxiosRequestConfig = {
    method: 'post',
    url: defInf.define1.awsurl + '/api/v1/users.create',
    headers: {
      'X-Auth-Token': xAuthToken,
      'X-User-Id': xAuthUserId,
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    },
    data: data
  };

  console.log(options);
  await axios(options)
    .then((res: AxiosResponse) => {
      console.log(res);
      retCreateUser = 1;
      return res;
    })
    .catch(function (error) {
      console.log(error);
      return error;
    });
}

const rocketCreateChannels = async function (name: string, meluser: string, members: string) {
  retCreateChannel = -1;
  var qs = require('qs');
  //var data = qs.stringify({
  var data = ({
    "name": name,
    "members": [members, meluser]
  });

  console.log(members);

  const config: AxiosRequestConfig = {
    method: 'post',
    url: defInf.define1.awsurl + '/api/v1/channels.create',
    headers: {
      'X-Auth-Token': xAuthToken,
      'X-User-Id': xAuthUserId,
      //'Content-Type': 'application/x-www-form-urlencoded'
      'Content-Type': 'application/json'
    },
    data: data
  };
  console.log(config);

  await axios(config)
    .then((res: AxiosResponse) => {
      console.log(res);
      retCreateChannel = 1;
      return res;
    })
    .catch(function (error) {
      console.log(error);
      return error;
    });
}

const rocketKickChannels = async function (roomName: string, userName: string) {
  retKickChannel = -1;
  var qs = require('qs');
  var roomId = "";
  var userId = ""
  // Room名->RoomID
  await rocketFetchRoomId(roomName);
  if (retFetchRoomId > 0) {
    roomId = rocketRoomId;
    console.log(rocketRoomId);
  }
  // ユーザ名->ユーザID
  await rocketFetchUserId(userName);
  if (retFetchUserId > 0) {
    userId = rocketUserId;
    console.log(rocketUserId);
  }
  var data = qs.stringify({
    'roomId': roomId,
    'userId': userId
  });
  const config: AxiosRequestConfig = {
    method: 'post',
    url: defInf.define1.awsurl + '/api/v1/channels.kick',
    headers: {
      'X-Auth-Token': xAuthToken,
      'X-User-Id': xAuthUserId,
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data: data
  };
  console.log(config);

  await axios(config)
    .then((res: AxiosResponse) => {
      console.log(res);
      retKickChannel = 1;
      return res;
    })
    .catch(function (error) {
      console.log(error);
      return error;
    });
}

const rocketFetchUserId = async function (userName: string) {
  retFetchUserId = -1;
  rocketUserId = ""
  var qs = require('qs');

  const config: AxiosRequestConfig = {
    method: 'GET',
    url: defInf.define1.awsurl + '/api/v1/users.info?username=' + userName,
    headers: {
      'X-Auth-Token': xAuthToken,
      'X-User-Id': xAuthUserId,
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    //data: data
  };
  console.log(config);

  await axios(config)
    .then((res: AxiosResponse) => {
      console.log(res);
      console.log(res.data.user._id);
      rocketUserId = res.data.user._id;
      retFetchUserId = 1;
      return res;
    })
    .catch(function (error) {
      console.log(error);
      return error;
    });
}

const rocketFetchRoomId = async function (roomName: string) {
  retFetchRoomId = -1;
  rocketRoomId = ""
  var qs = require('qs');
  //var data = qs.stringify({
  var data = ({
    'roomName': roomName,
  });
  const config: AxiosRequestConfig = {
    method: 'GET',
    url: defInf.define1.awsurl + '/api/v1/channels.info?roomName=' + encodeURI(roomName),
    headers: {
      'X-Auth-Token': xAuthToken,
      'X-User-Id': xAuthUserId,
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    },
    //data: data
  };
  console.log(config);

  await axios(config)
    .then((res: AxiosResponse) => {
      console.log("channels.info res:", res);
      rocketRoomId = res.data.channel._id;
      console.log(rocketRoomId);
      retFetchRoomId = 1;
      return res;
    })
    .catch(function (error) {
      console.log(error);
      return error;
    });
}

const rocketSendMessage = async function (roomName: string, msg: string): Promise<string> {
  retSendMessage = -1;

  const qs = require('qs')
  var roomId = "";

  // Room名->RoomID
  await rocketFetchRoomId(roomName);
  if (retFetchRoomId > 0) {
    roomId = rocketRoomId;
    console.log(rocketRoomId);
  }

  var data = ({
    "message": {
      "rid": roomId,
      "msg": msg,
    }
  });
  // SendMessage
  const options: AxiosRequestConfig = {
    method: 'post',
    url: defInf.define1.awsurl + '/api/v1/chat.sendMessage',
    headers: {
      'X-Auth-Token': xAuthToken,
      'X-User-Id': xAuthUserId,
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    },
    data: data
  };

  console.log(options);
  await axios(options)
    .then((res: AxiosResponse) => {
      console.log(res);
      retSendMessage = 1;
      return (res.data.status);
    })
    .catch(function (error) {
      console.log(error);
      return ("error");
    });
  return ("");
}

const rocketSendFile = async function (roomName: string, sendFile: any): Promise<string> {
  retSendFile = -1;
  const FormData = require('form-data');
  var roomId = "";

  // Room名->RoomID
  await rocketFetchRoomId(roomName);
  if (retFetchRoomId > 0) {
    roomId = rocketRoomId;
    console.log(rocketRoomId);
  }
  console.log(sendFile);
  var ext = getExt(sendFile.name);
  const data = new FormData()
  data.append('file', sendFile, "data." + ext);

  const header = {
    headers: {
      //'Content-Type': 'application/json;charset=UTF-8',
      //'X-Requested-With': 'XMLHttpRequest',
      'Content-Type': 'multipart/form-data; charset=UTF-8',
      //'Content-Type': 'application/x-www-form-urlencoded',
      'X-Auth-Token': xAuthToken,
      'X-User-Id': xAuthUserId,
    }
  }

  console.log(data);
  console.log(header);

  const postImageUri = defInf.define1.awsurl + '/api/v1/rooms.upload/' + roomId

  await axios.post(postImageUri, data, header)
    .then(res => {
      //任意の処理
      console.log(res);
      retSendFile = 1;
    }).catch(err => {
      //任意の処理
      console.log(err);
    })

  return ("");
}
const rocketLogOut = async function (): Promise<string> {
  retLogOut = -1;
  const qs = require('qs')

  // logOut
  const options: AxiosRequestConfig = {
    method: 'GET',
    url: defInf.define1.awsurl + '/api/v1/logout',
    headers: {
      'X-Auth-Token': xAuthToken,
      'X-User-Id': xAuthUserId,
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    //data: data
  };

  console.log(options);
  await axios(options)
    .then((res: AxiosResponse) => {
      console.log(res);
      xAuthToken = "";
      xAuthUserId = "";
      retLogOut = 1;
      return (res.data.status);
    })
    .catch(function (error) {
      console.log(error);
      return ("error");
    });
  return ("");
}
function twoDigit(num: number): string {
  var ret: string;
  if (num < 10) {
    ret = "0" + num.toString();
  }
  else {
    ret = num.toString();
  }
  return ret;
}
// hookを使用するためクラスを関数でラップ
export default function ContactsiteConfirm(props: any) {

  // useNavigationを取得し、クラスにnavigationを渡す
  const navigate = useNavigate();
  // useLocationを取得し、クラスにlocationを渡す
  const location = useLocation();

  return <ContactsiteConfirmClass {...props} navigate={navigate} location={location} />;
}

class ContactsiteConfirmClass extends React.Component<any, IUploadViewProps> {
  constructor(props: any) {
    super(props);

    this.state = props.location.state;
    window.scrollTo(0, 0)
  }

  /**
 * 初期データロード
 */
  componentDidMount() {
    // configure
    AwsAmplifyUtils.configure();
    // ユーザ名取得
    AwsAmplifyUtils.checkAuthenticated().then((value) => {
      console.log(value.username);
      awsuserid = value.username;
      // ユーザ情報取得
      // this.getUserInfo(value.username);
    });
  }

  // /**
  //  * ユーザ情報を取得する
  //  * @param userId ユーザID
  //  */
  // getUserInfo = (userId: any) => {
  //   const headers = { "Content-Type": "application/json" };
  //   let data = {
  //     userId: userId,
  //   };

  //   AwsAmplifyUtils.callAPI()
  //     .then((api) => {
  //       return api.API.post(
  //         "api",
  //         api.apiPrefix + "/userinfomanager/getuserinfo",
  //         {
  //           headers: headers,
  //           body: data,
  //         }
  //       );
  //     })
  //     .then((res) => {
  //       if (res.errorCode == 0) {
  //         var data = res.data;
  //         console.log(data);
  //       } else {
  //         if (res.errorCode == 1) {
  //           alert(res.errorMessage);
  //         } else {
  //           alert(res.errorMessage);
  //         }
  //       }
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //       alert(e);
  //     })
  //     .finally(() => {
  //       //何もしない
  //     });
  // };

  set = async () => {

    var formDataValue = this.state.formDataValue[0];
    var purposeText = "";
    var createErr = 0;

    if (this.props.logged) {
      // 現在ステータスを取得
      let httpBody = {
        "purpose": purposeList[Number(formDataValue.purpose)],
        "title": formDataValue.title,
        "upFile": formDataValue.upFile ? formDataValue.upFile : "未入力",
        // "text": formDataValue.text ? formDataValue.text : "未入力",
      }
      this.componentDidMount();

      const rocketAll = async function () {
        // ログイン時のチャット登録処理
        // ログイン
        var mel_user = defInf.define1.root_us;
        var mel_pass = defInf.define1.root_pw;

        let nowTime = new Date();
        let nowYear = nowTime.getFullYear();
        let nowMonth = twoDigit(nowTime.getMonth() + 1);
        let nowDay = twoDigit(nowTime.getDate());
        let nowHour = twoDigit(nowTime.getHours());
        let nowMin = twoDigit(nowTime.getMinutes());
        let nowSec = twoDigit(nowTime.getSeconds());
        let msg = nowYear + "/" + nowMonth + "/" + nowDay + " " + nowHour + ":" + nowMin;

        let i: number;
        for (i = 0; i < purposeList.length; i++) {
          if (httpBody.purpose == purposeList[i]) {
            purposeText = purposeListR[i] + " - " + formDataValue.title + " (" + awsuserid + ") " + msg;
          }
        }

        // ログイン（三菱管理者）
        await rocketLogin(mel_user, mel_pass);
        if (retLogin == 1) {
          console.log("login成功");
        }
        else {
          console.log("login失敗");
        }

        await new Promise(resolve => setTimeout(resolve, 100)) // 0.1秒待つ

        // ユーザID検索
        await rocketFetchUserId(awsuserid);
        if (retFetchUserId > 0) {
          // 実在しているので作成する必要なし
          console.log("userid is :", rocketUserId);
        }
        else {
          // ユーザ追加
          await rocketCreateUser(awsuserid, awsuserid + "@" + awsuserid + ".com", awsuserid, awsuserid);
          if (retCreateUser == 1) {
            console.log("CreateUser成功");
          }
          else {
            console.log("CreateUser失敗");
            await new Promise(resolve => setTimeout(resolve, 100)) // 0.1秒待つ
            // 再チャレンジ
            await rocketCreateUser(awsuserid, awsuserid + "@" + awsuserid + ".com", awsuserid, awsuserid);
            if (retCreateUser == 1) {
              console.log("CreateUser成功");
            }
            else {
              console.log("CreateUser失敗");
            }
          }

          await new Promise(resolve => setTimeout(resolve, 100)) // 0.1秒待つ

          // ユーザID検索
          await rocketFetchUserId(awsuserid);
          if (retFetchUserId > 0) {
            // ユーザの作成に成功した
          }
          else {
            // ユーザの作成に失敗した
            alert("問い合わせの登録でエラーが発生しました。\nもう一度お試しください。");
            return;
          }
        }

        // GENERALからキック（チャンネルユーザーキック処理）
        await rocketKickChannels("general", awsuserid);
        if (retKickChannel > 0) {
          console.log("キック成功");
        } else {
          console.log("キック失敗");
        }

        // チャンネルがすでに登録されているか検索する
        await rocketFetchRoomId(purposeText);
        if (retFetchRoomId > 0) {
          // すでに登録されている
          createErr = 1;
          alert("誠に申し訳ございません。\n問い合わせ表題が既に登録されていますので、\n変更をお願い致します。");
          return;
        }
        // チャンネル作成
        await rocketCreateChannels(purposeText, mel_user, awsuserid);
        if (retCreateChannel == 1) {
          console.log("CreateChannel成功");
        }
        else {
          console.log("CreateChannel失敗");
        }
        await new Promise(resolve => setTimeout(resolve, 100)) // 0.1秒待つ

        // ログアウト
        await rocketLogOut()
        if (retLogOut > 0) {
          console.log("ログアウト成功");
        } else {
          console.log("ログアウト失敗");
        }

        // ログイン（awsuserid）
        await rocketLogin(awsuserid, awsuserid);
        if (retLogin == 1) {
          console.log("login成功");
        }
        else {
          console.log("login失敗");
        }

        // メッセージ作成
        await rocketSendMessage(purposeText, formDataValue.text);
        if (retSendMessage > 0) {
          console.log("メッセージ作成成功");
        } else {
          console.log("メッセージ作成失敗");
        }

        // ファイルが添付されているときだけファイルを送信する
        if (formDataValue.upFile != "") {
          // ファイル送信
          await rocketSendFile(purposeText, formDataValue.upFile);
          console.log(retSendFile);
          if (retSendFile > 0) {
            console.log("ファイル送信成功");
          } else {
            console.log("ファイル送信失敗");
          }
        }

        // ログアウト
        await rocketLogOut()
        if (retLogOut > 0) {
          console.log("ログアウト成功");
        } else {
          console.log("ログアウト失敗");
        }
      }

      await rocketAll()
      // チャットURLをここで生成する
      this.state.formDataValue[0].chaturl = defInf.define1.url + 'channel/' + rocketRoomId;
      if (createErr != 1) {
        // 登録時エラーがなければ次に遷移する
        this.windowopen()
      }
    } else {
      // 非ログイン時のチャット登録処理
      // 現在ステータスを取得
      let httpBody = {
        "industry": industryList[Number(formDataValue.industry)],
        "company": formDataValue.company,
        "postcode": formDataValue.postCode ? formDataValue.postCode : "未入力",
        "address": formDataValue.address,
        "department": formDataValue.department,
        "position": formDataValue.position,
        "name": formDataValue.name,
        "kana": formDataValue.kana ? formDataValue.kana : "未入力",
        "email": formDataValue.email,
        "telephone": formDataValue.telephone,
        "fax": formDataValue.FAX ? formDataValue.FAX : "未入力",
        "purpose": purposeList[Number(formDataValue.purpose)],
        "title": formDataValue.title,
        "upFile": formDataValue.upFile ? formDataValue.upFile : "未入力",
        "text": formDataValue.text ? formDataValue.text : "未入力",
        "term": formDataValue.term,
      }

      mlogindataList = "業種　：　" + httpBody.industry + "\n" +
        "会社・団体名　：　" + httpBody.company + "\n" +
        "郵便番号　：　" + httpBody.postcode + "\n" +
        "ご住所　：　" + httpBody.address + "\n" +
        "部署名　：　" + httpBody.department + "\n" +
        "役職名　：　" + httpBody.position + "\n" +
        "お名前　：　" + httpBody.name + "\n" +
        "フリガナ　：　" + httpBody.kana + "\n" +
        "メールアドレス　：　" + httpBody.email + "\n" +
        "お電話番号　：　" + httpBody.telephone + "\n" +
        "FAX番号　：　" + httpBody.fax + "\n" +
        "ご相談製品+問い合わせ表題　：　" + httpBody.purpose + "\n" +
        "問い合わせ内容　：　" + httpBody.text + "\n";

      const rocketAll = async function () {
        // ログイン
        var mel_user = defInf.define1.root_us;
        var mel_pass = defInf.define1.root_pw;
        // ログイン
        await rocketLogin(mel_user, mel_pass);
        if (retLogin == 1) {
          console.log("login成功");
        }
        else {
          console.log("login失敗");
        }

        await new Promise(resolve => setTimeout(resolve, 100)) // 0.1秒待つ

        // userId取得
        // 実在
        await rocketFetchUserId(mel_user);
        if (retFetchUserId > 0) {
          console.log("userid is :", rocketUserId);
        }
        await new Promise(resolve => setTimeout(resolve, 100)) // 0.1秒待つ

        // メッセージ作成
        await rocketSendMessage("お問い合わせ総合センター", mlogindataList);
        if (retSendMessage > 0) {
          console.log("メッセージ作成成功");
        } else {
          console.log("メッセージ作成失敗");
        }

        // ファイルが添付されているときだけファイルを送信する
        if (formDataValue.upFile != "") {
          // ファイル送信
          await rocketSendFile("お問い合わせ総合センター", formDataValue.upFile);
          console.log(retSendFile);
          if (retSendFile > 0) {
            console.log("ファイル送信成功");
          } else {
            console.log("ファイル送信失敗");
          }
        }

        // ログアウト
        await rocketLogOut()
        if (retLogOut > 0) {
          console.log("ログアウト成功");
        } else {
          console.log("ログアウト失敗");
        }


      }

      await rocketAll();
      this.windowopen()
    }
  }

  windowopen = async () => {
    //window.location.href = "../ContactsiteComplete";
    console.log(this.state.formValid);

    if (this.state.formValid) {
      this.props.navigate("../ContactsiteComplete", {
        state: this.state
      })
    } else {
      this.returnTop();
    }
  }

  changeInput = () => {
    this.returnTop();
    this.props.navigate("../Contactsite", {
      state: this.state
    })
  }

  returnTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  render() {
    var loginRenderList = [];

    if (this.props.logged) {
      loginRenderList.push(
        <div className={styles.entry_div_outside}>
          <div className={styles.entry_title}>
            お問い合わせフォーム
          </div>
          <div className={styles.entry_menu}>
            <div className={styles.entry_menu_title}>2.確認</div>
            <div className={styles.entry_menu_info}>
              <p>
                ご入力いただいた内容をご確認のうえ、よろしければページ下の「登録する」ボタンを押してください。
              </p>
              <p>
                入力内容を変更したい場合は、ページ下の「入力内容を変更する」ボタンを押して下さい。
              </p>
            </div>
            {/* 変更点 */}
            <table className={styles.entry_menu_select}>
              <tbody>
                <tr className={styles.entry_menu_tr_2line}>
                  <td className={styles.entry_menu_td_left}>ご相談の製品について</td>
                  <td className={styles.entry_menu_td_right}>
                    <label>{
                      purposeList[Number(this.state.formDataValue[0].purpose)]
                    }</label>
                  </td>
                </tr>
                <tr className={styles.entry_menu_tr_2line}>
                  <td className={styles.entry_menu_td_left}>
                    問い合わせ表題
                  </td>
                  <td className={styles.entry_menu_td_right}>
                    <label>{this.state.formDataValue[0].title}</label>
                  </td>
                </tr>
                <tr className={styles.entry_menu_tr_2line}>
                  <td className={styles.entry_menu_td_left}>
                    問い合わせ資料
                  </td>
                  <td className={styles.entry_menu_td_right}>
                    <label>{
                      this.state.formDataValue[0].upFile.name ?
                        this.state.formDataValue[0].upFile.name :
                        "添付なし"
                    }</label>
                  </td>
                </tr>
                <tr className={styles.entry_menu_tr_2line}>
                  <td className={styles.entry_menu_td_left}>
                    問い合わせ内容
                  </td>
                  <td className={styles.entry_menu_td_right}>
                    <label>{
                      this.state.formDataValue[0].text ?
                        this.state.formDataValue[0].text :
                        "未入力"
                    }</label>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className={styles.entry_menu_confirm}>
              <button className={styles.set} onClick={this.set}>
                登録する
              </button>
              <button className={styles.change} onClick={this.changeInput}>
                入力内容を変更する
              </button>
            </div>
            <div className={styles.entry_menu_pageTop}>
              <div>
                <input
                  type="button"
                  className={styles.pageTopButton}
                  value="▲ページトップへ戻る"
                  onClick={this.returnTop}
                />
              </div>
            </div>
          </div>
        </div>
      )
    } else {
      loginRenderList.push(
        <div className={styles.entry_div_outside}>
          <div className={styles.entry_title}>
            お問い合わせフォーム
          </div>
          <div className={styles.entry_menu}>
            <div className={styles.entry_menu_title}>2.確認</div>
            <div className={styles.entry_menu_info}>
              <p>
                ご入力いただいた内容をご確認のうえ、よろしければページ下の「登録する」ボタンを押してください。
              </p>
              <p>
                入力内容を変更したい場合は、ページ下の「入力内容を変更する」ボタンを押して下さい。
              </p>
            </div>
            <table className={styles.entry_menu_name}>
              <tbody>
                <tr>
                  <td className={styles.entry_menu_td_left}>業種</td>
                  <td className={styles.entry_menu_td_right}>
                    <label>{
                      this.state.formDataValue[0].industry == "0" ?
                        "未入力" :
                        industryList[Number(this.state.formDataValue[0].industry)]
                    }</label>
                  </td>
                </tr>
                <tr>
                  <td className={styles.entry_menu_td_left}>会社・団体名</td>
                  <td className={styles.entry_menu_td_right}>
                    <label>{this.state.formDataValue[0].company}</label>
                  </td>
                </tr>
                <tr className={styles.entry_menu_tr_2line}>
                  <td className={styles.entry_menu_td_left}>郵便番号</td>
                  <td className={styles.entry_menu_td_right}>
                    <label>{
                      this.state.formDataValue[0].postCode ?
                        this.state.formDataValue[0].postCode :
                        "未入力"
                    }</label>
                  </td>
                </tr>
                <tr>
                  <td className={styles.entry_menu_td_left}>ご住所</td>
                  <td className={styles.entry_menu_td_right}>
                    <label>{this.state.formDataValue[0].address}</label>
                  </td>
                </tr>
              </tbody>
            </table>
            <table className={styles.entry_menu_address}>
              <tbody>
                <tr>
                  <td className={styles.entry_menu_td_left}>部署名</td>
                  <td className={styles.entry_menu_td_right}>
                    <label>{this.state.formDataValue[0].department}</label>
                  </td>
                </tr>
                <tr>
                  <td className={styles.entry_menu_td_left}>役職名</td>
                  <td className={styles.entry_menu_td_right}>
                    <label>{this.state.formDataValue[0].position}</label>
                  </td>
                </tr>
                <tr>
                  <td className={styles.entry_menu_td_left}>お名前</td>
                  <td className={styles.entry_menu_td_right}>
                    <label>{this.state.formDataValue[0].name}</label>
                  </td>
                </tr>
                <tr className={styles.entry_menu_tr_2line}>
                  <td className={styles.entry_menu_td_left}>フリガナ</td>
                  <td className={styles.entry_menu_td_right}>
                    <label>{
                      this.state.formDataValue[0].kana ?
                        this.state.formDataValue[0].kana :
                        "未入力"
                    }</label>
                  </td>
                </tr>

                <tr className={styles.entry_menu_tr_2line}>
                  <td className={styles.entry_menu_td_left}>メールアドレス</td>
                  <td className={styles.entry_menu_td_right}>
                    <label>{this.state.formDataValue[0].email}</label>
                  </td>
                </tr>
                <tr className={styles.entry_menu_tr_2line}>
                  <td className={styles.entry_menu_td_left}>お電話番号</td>
                  <td className={styles.entry_menu_td_right}>
                    <label>{this.state.formDataValue[0].telephone}</label>
                  </td>
                </tr>
                <tr className={styles.entry_menu_tr_2line}>
                  <td className={styles.entry_menu_td_left}>FAX番号</td>
                  <td className={styles.entry_menu_td_right}>
                    <label>{
                      this.state.formDataValue[0].FAX ?
                        this.state.formDataValue[0].FAX :
                        "未入力"
                    }</label>
                  </td>
                </tr>
              </tbody>
            </table>
            {/* 変更点 */}
            <table className={styles.entry_menu_select}>
              <tbody>
                <tr className={styles.entry_menu_tr_2line}>
                  <td className={styles.entry_menu_td_left}>ご相談の製品について</td>
                  <td className={styles.entry_menu_td_right}>
                    <label>{
                      purposeList[Number(this.state.formDataValue[0].purpose)]
                    }</label>
                  </td>
                </tr>
                <tr className={styles.entry_menu_tr_2line}>
                  <td className={styles.entry_menu_td_left}>
                    問い合わせ表題
                  </td>
                  <td className={styles.entry_menu_td_right}>
                    <label>{this.state.formDataValue[0].title}</label>
                  </td>
                </tr>
                <tr className={styles.entry_menu_tr_2line}>
                  <td className={styles.entry_menu_td_left}>
                    問い合わせ資料
                  </td>
                  <td className={styles.entry_menu_td_right}>
                    <label>{
                      this.state.formDataValue[0].upFile.name ?
                        this.state.formDataValue[0].upFile.name :
                        "添付なし"
                    }</label>
                  </td>
                </tr>
                <tr className={styles.entry_menu_tr_2line}>
                  <td className={styles.entry_menu_td_left}>
                    問い合わせ内容
                  </td>
                  <td className={styles.entry_menu_td_right}>
                    <label>{
                      this.state.formDataValue[0].text ?
                        this.state.formDataValue[0].text :
                        "未入力"
                    }</label>
                  </td>
                </tr>
              </tbody>
            </table>
            {/* 変更点 */}
            <table className={styles.entry_menu_privacy}>
              <tbody>
                <tr className={styles.entry_menu_tr_3line}>
                  <td className={styles.entry_menu_td_left}>
                    個人情報のお取り扱い
                  </td>
                  <td className={styles.entry_menu_td_right}>
                    <label>{
                      this.state.formDataValue[0].term ?
                        "同意する" :
                        "同意しない"
                    }</label>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className={styles.entry_menu_confirm}>
              <button className={styles.set} onClick={this.set}>
                登録する
              </button>
              <button className={styles.change} onClick={this.changeInput}>
                入力内容を変更する
              </button>
            </div>
            <div className={styles.entry_menu_pageTop}>
              <div>
                <input
                  type="button"
                  className={styles.pageTopButton}
                  value="▲ページトップへ戻る"
                  onClick={this.returnTop}
                />
              </div>
            </div>
          </div>
        </div>
      )
    }
    return (
      <div
        style={{
          fontSize: "16px !important",
          height: "100%",
          maxWidth: "1440px !important",
          margin: "0 auto",
          textAlign: "center",
        }}
      >
        {/*パンクズリスト className={}*/}
        <div>
          <BreadcrumbHeader
            breadcrumb={this.state.breadcrumb}
          ></BreadcrumbHeader>
        </div>
        {loginRenderList}
      </div>
    );
  }
}
//ファイル名から拡張子を取得する関数
function getExt(filename: string) {
  var pos = filename.lastIndexOf('.');
  if (pos === -1) return '';
  return filename.slice(pos + 1);
}