import React from "react";
import InputEvent from "../common/utility/InputEvent";
import styles from "./Register.module.scss";
interface eneportalProps {
  logged: boolean;
}
/**
 * トップページ：Register部分
 */
export class Register extends React.Component<eneportalProps, any, number> {
  /**
   * コンストラクタ
   * @param props 親コンポーネントから渡される情報
   */
  constructor(props: eneportalProps) {
    super(props);
    this.state = {
      count: 0,
      active: false,
    };
  }

  /**
   * 工事中メッセージを表示
   */
  showUnderConstructionMessage() {
    alert("coming soon");
  }

  /**
   * ログイン画面に遷移
   */
  login() {
    window.open("./login", "_blank");
  }

  /**
   * 会員申し込みページに遷移
   *
   */
  registerAccount(e: any) {
    if (InputEvent.checkClickEnter(e)) {
      window.location.href = "../entry";
    }
  }

  /**
   *
   */
  demo() {
    window.open("./demo", "_blank");
  }
  componentDidMount() {
    // アニメーション表示
    const observer = new IntersectionObserver(
      ([entries]) => {
        if (entries.isIntersecting) {
          this.setState({ active: true });
        }
      },
      { threshold: 0.25 }
    );
    observer.observe(document.getElementById("register") as any);
  }

  /**
   * 描画処理
   * @returns 描画するJSX
   */
  render() {
    /*let e = App2();*/
    let regStyles = "";
    let regButtonStyles = "";
    var url = "./";
    let tabIndex = 0;
    // ログイン済みのとき
    if (this.props.logged) {
      regStyles = styles.eventNone;
      regButtonStyles = styles.eventNoneButton;
      url = "./mypage";
      tabIndex = -1;
    } else {
      url = "./login?page=/mypage";
    }
    const animationb = (v: boolean) => ` animation_bottom${v ? " active" : ""}`;
    const animationl = (v: boolean) => ` animation_left${v ? " active" : ""}`;
    return (
      <div id="register" className={styles.register_area}>
        <div className={styles.register__title}>
          <div
            className={styles.ttl_title + animationb(this.state.active)}
            lang="en"
          >
            Register
          </div>
          <div
            className={styles.ttl_underLine + animationb(this.state.active)}
          ></div>
          <div></div>
        </div>
        <div className={styles.register_div}>
          <div className={styles.register_div_area}>
            <div
              className={
                styles.register_div_area_title + animationb(this.state.active)
              }
            >
              {/* 既に登録済みの方は<a>こちら</a>からログイン*/}
            </div>
            {/**ここから始まる */}
            <div className={styles.register}>
              <ul className={styles.register__list}>
                <li
                  className={
                    styles.register__item + animationl(this.state.active)
                  }
                >
                  <dl className={styles.register__def}>
                    <dt className={styles.register__def_ttl} lang="en">
                      STEP 01
                    </dt>
                    <dd className={styles.register__def_data}>
                      <a
                        className={regStyles}
                        href="/entry"
                        tabIndex={tabIndex}
                      >
                        サイト会員申し込み
                      </a>
                      より
                      <br />
                      会員登録のご希望をご連絡ください
                    </dd>
                  </dl>
                </li>
                <li
                  className={
                    styles.register__item +
                    animationl(this.state.active) +
                    " animation_step02"
                  }
                >
                  <dl className={styles.register__def}>
                    <dt className={styles.register__def_ttl} lang="en">
                      STEP 02
                    </dt>
                    <dd className={styles.register__def_data}>
                      営業担当者が
                      <br />
                      ご登録内容の確認をさせていただきます
                    </dd>
                  </dl>
                </li>
                <li
                  className={
                    styles.register__item +
                    animationl(this.state.active) +
                    " animation_step03"
                  }
                >
                  <dl className={styles.register__def}>
                    <dt className={styles.register__def_ttl} lang="en">
                      STEP 03
                    </dt>
                    <dd className={styles.register__def_data}>
                      お客様の情報を登録し
                      <br />
                      ログインに必要なIDをご連絡します
                    </dd>
                  </dl>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/** 新規会員登録ボタン */}
        <div
          className={
            styles.entry_div +
            animationl(this.state.active) +
            " animation_registerButton"
          }
        >
          <div
            className={styles.entry_button + " " + regButtonStyles}
            tabIndex={tabIndex}
            role="button"
            onClick={(e) => this.registerAccount(e)}
            onKeyDown={(e) => this.registerAccount(e)}
          >
            サイト会員申し込みは <label>こちら{/*から→*/}</label>
          </div>
          <div
            className={styles.register_div_area_title_bot + " animation_bottom"}
          >
            ご契約者様専用ページは
            <a href={url} target="mypage" ref="noopener">
              こちら
            </a>
          </div>
        </div>
      </div>
    );
  }
}
