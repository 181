import { CognitoUser } from "amazon-cognito-identity-js";
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AprService } from "./apr/AprService";
import { CheckAuth } from "./CheckAuth";
import { AwsAmplifyUtils } from "./common/components/AwsAmplifyUtils";
import Config from "./common/config/config.json";
import { Mypage } from "./mypage/Mypage";
import { Agreement } from "./mypage/parts/Agreement";
import { DescriptionApr } from "./top/description/apr/DescriptionApr";
import { EeneportalTopPage } from "./top/EneportalTopPage";
import Entry from "./top/Entry";
import { EntryComplete } from "./top/EntryComplete";
import EntryConfirm from "./top/EntryConfirm";
import Login from "./top/Login";
import { Logout } from "./top/Logout";
import { PasswordChange } from "./top/PasswordChange";
import PasswordCompleteLocation from "./top/PasswordComplete";
import FAQ from "./FAQ/FAQ";
import Contactsite from "./Contactsite/Contactsite";
import ContactsiteConfirm from "./Contactsite/ContactsiteConfirm";
import ContactsiteComplete from "./Contactsite/ContactsiteComplete";

import ResetPasswordQuestionNavigate from "./top/ResetPasswordQuestion";
import ResetPasswordMailLocation from "./top/ResetPasswordMail";
import { ResetPasswordAccept } from "./top/ResetPasswordAccept";

/**
 * 内部コンテキスト
 */
interface AppState {
  /** 認証状態 */
  isLogged: boolean;

  /** 初回パスワード要求状態 */
  isCompleteRequired: boolean;
}

/**
 * URLとコンポーネントの紐づけを行う
 */
export class App extends React.Component<any, AppState> {
  // 認証中のユーザ情報(初回パスワード設定に使用)
  private cognitoUser!: CognitoUser;

  /**
   * ユーザ情報取得(初回パスワード設定に使用)
   * @return CognitoUser
   */
  getCognitoUserInfo() {
    if (this.state.isCompleteRequired) {
      return this.cognitoUser;
    } else {
      return null;
    }
  }

  /**
   * ユーザ情報設定(初回パスワード設定に使用)
   * @param props Props
   */
  setCognitoUserInfo(user: CognitoUser) {
    this.cognitoUser = user;
    this.setState({ isCompleteRequired: true });
  }

  /**
   * コンストラクタ
   * @param props Props
   */
  constructor(props: any) {
    super(props);

    this.state = {
      isLogged: true,
      isCompleteRequired: false,
    };
    // 認証情報を繰り返す
    const getLoginStatus = () => {
      //N秒ごとに実行
      setTimeout(() => {
        //console.log("認証チェック");
        AwsAmplifyUtils.currentUserInfo().then((res) => {
          // ログアウト済みなら
          if (res == null || res.id == null) {
            // ログアウト処理(セッションストレージの削除)
            AwsAmplifyUtils.signOut();
            // ステータスを変更
            this.setState({ isLogged: false });
          } else {
            this.setState({ isLogged: true });
          }
          getLoginStatus();
        });
      }, Config.application.LoginStatusReloadTime);
    };
    getLoginStatus();
  }

  /**
   * コンポーネント読み込み時処理
   * @returns Promise
   */
  componentDidMount() {
    // Cognitoログイン状況を得る
    AwsAmplifyUtils.configure();
    return AwsAmplifyUtils.isLogged().then((isLogged) =>
      this.setState({ isLogged: isLogged })
    );
  }
  async componentDidMount3() {
    // Cognitoログイン状況を得る
    AwsAmplifyUtils.configure();

    await AwsAmplifyUtils.isLogged().then((isLogged) =>
      this.setState({ isLogged: isLogged })
    );
  }

  /**
   * 描画処理
   * @returns 描画するJSX
   */
  render() {
    return (
      <div>
        <BrowserRouter>
          <Routes>
            // トップページ
            <Route
              path="/"
              element={<EeneportalTopPage logged={this.state.isLogged} />}
            />
            // ログイン画面
            <Route
              path="/login"
              element={
                <CheckAuth
                  isLogged={false}
                  component={
                    <Login setUserInfo={this.setCognitoUserInfo.bind(this)} />
                  }
                  redirect="/"
                />
              }
            />
            // ログアウト画面
            <Route
              path="/logout"
              element={
                <CheckAuth
                  isLogged={true}
                  component={<Logout />}
                  redirect="/"
                />
              }
            />
            // パスワード変更画面
            <Route
              path="/passwordChange"
              element={
                <CheckAuth
                  isLogged={this.state.isLogged}
                  component={<PasswordChange />}
                  redirect="/"
                />
              }
            />
            // 初回パスワード設定画面
            <Route
              path="/passwordComplete"
              element={
                <CheckAuth
                  isLogged={false}
                  component={
                    <PasswordCompleteLocation
                      getUserInfo={this.getCognitoUserInfo.bind(this)}
                    />
                  }
                  redirect="/"
                />
              }
            />
            // パスワードリセットユーザ入力画面
            <Route
              path="/resetpasswordquestion"
              element={
                <CheckAuth
                  isLogged={false}
                  component={<ResetPasswordQuestionNavigate />}
                  redirect="/"
                />
              }
            />
            // パスワードリセット秘密の質問入力画面
            <Route
              path="/resetpasswordmail"
              element={
                <CheckAuth
                  isLogged={false}
                  component={<ResetPasswordMailLocation />}
                  redirect="/"
                />
              }
            />
            // パスワードリセットメール送信画面
            <Route
              path="/resetpasswordaccept"
              element={
                <CheckAuth
                  isLogged={false}
                  component={<ResetPasswordAccept />}
                  redirect="/"
                />
              }
            />
            // 申し込み画面
            <Route path="/entry" element={<Entry />} />
            // 申し込み確認画面
            <Route path="/entryconfirm" element={<EntryConfirm />} />
            // 申し込み完了画面
            <Route path="/entrycomplete" element={<EntryComplete />} />
            // サービス概要(APR)
            <Route
              path="/service/apr/description"
              element={<DescriptionApr logged={this.state.isLogged} />}
            />
            // マイページ画面
            <Route
              path={"/mypage" + ""}
              element={
                <CheckAuth
                  isLogged={this.state.isLogged}
                  component={<Mypage logged={this.state.isLogged} />}
                  redirect="/login?page=/mypage"
                />
              }
              /*element={<Mypage logged={this.state.isLogged} />}*/
            ></Route>
            // 契約更新
            <Route
              path={"/" + "agreement"}
              element={
                <CheckAuth
                  isLogged={this.state.isLogged}
                  component={
                    <div>
                      <Agreement></Agreement>
                    </div>
                  }
                  redirect="/login?page=/agreement"
                />
              }
            ></Route>
            // APR分析サービス
            <Route
              path="/service/apr"
              element={
                <CheckAuth
                  isLogged={this.state.isLogged}
                  component={<AprService logged={this.state.isLogged} />}
                  redirect="/login?page=/service/apr"
                />
              }
            />
            // FAQ
            <Route path="/FAQ" element={<FAQ logged={this.state.isLogged} />} />
            <Route
              path="/Contactsite"
              element={<Contactsite logged={this.state.isLogged} />}
            />
            <Route
              path="/Contactsiteconfirm"
              element={<ContactsiteConfirm logged={this.state.isLogged} />}
            />
            <Route
              path="/ContactsiteComplete"
              element={<ContactsiteComplete logged={this.state.isLogged} />}
            />
            <Route element={alert} />
          </Routes>
        </BrowserRouter>
      </div>
    );
  }
}
