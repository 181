import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ChatIcon from "@mui/icons-material/Chat";
import HelpIcon from "@mui/icons-material/Help";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import {
  ClickAwayListener,
  Paper,
  Popper, TableContainer
} from "@mui/material";
import React from "react";
import Modal from "react-modal";
import "react-pro-sidebar/dist/css/styles.css";
import { AwsAmplifyUtils } from "../../common/components/AwsAmplifyUtils";
import CommonConfig from "../../common/config/config.json";
import InputEvent from "../../common/utility/InputEvent";
import Config from ".././config.json";
import "./ContentHederTitle.css";
import styles from "./ContentHederTitle.module.scss";
import CommonDialog from "./Dialog";

type Props = {};
interface SlidebarState {
  title: string;
  icon: any;
  loading: boolean;
  notice: number;
  getNoticeMessage: Array<NoticeMessage>;
  showNoticeMessage: Array<NoticeMessage>;
  openList: boolean;
  showMessage: boolean;
  msgtitle?: string;
  message?: string;
}
type NoticeMessage = {
  id: number;
  timestamp: number;
  title: string;
  text: string;
};
export class ContentHederTitle extends React.Component<any, SlidebarState> {
  constructor(props: any) {
    super(props);
    this.state = {
      title: props.title,
      icon: props.icon,
      loading: false,
      notice: 0,
      getNoticeMessage: [],
      showNoticeMessage: [],
      openList: false,
      showMessage: false,
    };
    this.getNotice();
    setInterval(
      () => this.getNotice(),
      Config.application.notificationUpdatePeriod
    );
  }
  /***
   * Ｑ＆Ａ画面の呼出
   */
  faq() {
    window.open(CommonConfig.faq.url, "_blank");
  }
  /**
   * APR概要画面の呼び出し
   */
  aprDescription() {
    window.open("./service/apr/description#aprUse", "_blank");
  }

  /***
   * マイページ画面の呼出
   */
  mypage() {
    window.open("../mypage", "_blank");
  }

  /**
   * 問い合わせ画面の呼び出し
   */
  inquiry() {
    window.open(CommonConfig.inquiry.url, "_blank");
  }

  /**
   * 工事中メッセージを表示
   */
  showUnderConstructionMessage() {
    alert("coming soon");
  }

  /***
   * 通知メッセージの取得
   */
  async getNotice() {
    const headers = { "Content-Type": "application/json" };
    const data = { serviceType: "apr-simple", from: 0, to: 9999999999 };

    AwsAmplifyUtils.callAPI()
      .then((api) => {
        return api.API.post(
          "api",
          api.apiPrefix + "/notificationmanager/notification/get",
          {
            headers: headers,
            body: data,
          }
        );
      })
      .then(async (res) => {
        if (res.errorCode === "OK") {
          const n = res;
          const ndata = new Array<NoticeMessage>();
          n.message.forEach((m: NoticeMessage) => {
            ndata.push(m);
          });
          this.setState({
            notice: n.message.length,
            getNoticeMessage: ndata,
          });
        } else {
          const msgtitle = "通知メッセージ取得エラー";
          console.log(msgtitle);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }
  /***
   * データの設定
   */
  createData(
    id: number,
    type: string,
    title: string,
    date: Date,
    message: string
  ) {
    return { id, type, title, date, message };
  }

  /***
   * 通知メッセージの削除
   */
  async deleteNoticeMessage() {
    const headers = { "Content-Type": "application/json" };
    let idlist = new Array();
    this.state.showNoticeMessage.forEach(n => {
      idlist.push(n.id);
    })
    const data = { serviceType: "apr-simple", id: idlist };

    AwsAmplifyUtils.callAPI()
      .then((api) => {
        return api.API.post(
          "api",
          api.apiPrefix + "/notificationmanager/notification/delete",
          {
            headers: headers,
            body: data,
          }
        );
      }).then(async (res) => {
        if (res.errorCode === "OK") {
          this.getNotice();
        } else {
          this.showMessage(
            "通知メッセージ削除エラー",
            "メッセージの削除に失敗しました。"
          );
        }
      });
  }
  /***
   * 日付形式の変換
   * long -> Y/M/D h:m:s ゼロ埋めなし
   */
  convDate(datetime: number) {
    let date = new Date(datetime * 1000);
    let format = "Y/M/D h:m";
    format = format.replace(/Y/g, date.getFullYear() as any);
    format = format.replace(/M/g, (date.getMonth() + 1) as any);
    format = format.replace(/D/g, date.getDate() as any);
    format = format.replace(/h/g, date.getHours() as any);
    format = format.replace(/m/g, (("0" + date.getMinutes()) as any).slice(-2));
    return format;
  }
  /***
   * メッセージダイアログの設定
   */
  showMessage(msgtitle: string, message: string) {
    this.setState({ showMessage: true, msgtitle: msgtitle, message: message });
  }
  /**
   * キー押下時処理
   * 
   * @param e キーイベント
   * @param f 実行ファンクション
   */
  keyDown(e: any, f: any) {
    if (InputEvent.checkClickEnter(e)) {
      f();
    }
  }

  render(): React.ReactNode {
    // 通知0件のときbadge非表示
    let stylebadge = { display: "" };
    if (this.state.notice == 0) {
      stylebadge = { display: "none" };
    }
    return (
      <div>
        <div className={styles.ContentHeder}>
          <div className={styles.flexArea}>
            <div className={styles.serchInput}>
              <this.props.icon sx={{ fontSize: 40 }}></this.props.icon>
              <div className={styles.title}> {this.props.title}</div>{" "}
            </div>
            <div className={styles.iconArea + " " + styles.iconArea_left}>
              <div title="使い方">
                <HelpIcon
                  onClick={
                    (e) => {
                      e.currentTarget.blur();
                      this.aprDescription();
                    }
                  }
                  onKeyDown={
                    (e) => {
                      e.currentTarget.blur();
                      this.keyDown(e, this.aprDescription);
                    }
                  }
                  className={styles.icon}
                  sx={{ color: "#404040" }}
                  tabIndex={0}
                ></HelpIcon></div>
            </div>
            <div className={styles.iconArea}>
              <div title="FAQ">
                <ChatIcon
                  onClick={
                    (e) => {
                      e.currentTarget.blur();
                      this.faq();
                    }
                  }
                  onKeyDown={
                    (e) => {
                      e.currentTarget.blur();
                      this.keyDown(e, this.faq);
                    }
                  }
                  className={styles.icon}
                  sx={{ color: "#404040" }}
                  tabIndex={0}
                ></ChatIcon></div>
            </div>
            <div className={styles.iconArea}>
              <button
                id="noticepopper"
                className={styles.setting}
                aria-controls={
                  this.state.openList ? "notice-list-grow" : undefined
                }
                aria-haspopup="true"
                onClick={() => {
                  this.setState({
                    showNoticeMessage: this.state.getNoticeMessage,
                    openList: !this.state.openList
                  });
                  setTimeout(() => {
                    if (this.state.openList) {
                      this.deleteNoticeMessage();
                    }
                  }, 0);
                }}
              >
                <div title="通知">
                  <NotificationsNoneIcon
                    className={styles.icon}
                    sx={{ color: "#404040" }}
                  ></NotificationsNoneIcon></div>
                <span className={styles.badge} style={stylebadge}>
                  {this.state.notice}
                </span>
              </button>
              <Popper
                open={
                  this.state.openList && this.state.showNoticeMessage?.length > 0
                }
                anchorEl={document.getElementById("noticepopper")}
              >
                <Paper className={styles.noticepopper}>
                  <ClickAwayListener
                    onClickAway={() => this.setState({ openList: false })}
                  >
                    <TableContainer sx={{ maxHeight: 200 }}>
                      <table className={styles.table} id="notice-list-grow">
                        <tbody className={styles.tbody}>
                          {this.state.showNoticeMessage?.sort((a, b) => b.id - a.id).map((row) => (
                            <tr
                              className={styles.tr}
                              key={row.id}
                            >
                              <td className={styles.td} style={{ verticalAlign: "top" }} align="left">
                                <div className={styles.td_date}>
                                  {this.convDate(row.timestamp)}
                                </div>
                              </td>
                              <td className={styles.td} align="left">
                                <div className={styles.td_title}>
                                  {row.title}
                                </div>
                                <div className={styles.td_message}>
                                  {row.text}
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </TableContainer>
                  </ClickAwayListener>
                </Paper>
              </Popper>
            </div>
            <div className={styles.iconArea}>
              <div title="マイページ">
                <AccountCircleIcon
                  onClick={this.mypage}
                  onKeyDown={
                    (e) => {
                      this.keyDown(e, this.mypage);
                    }
                  }
                  className={styles.icon}
                  sx={{ color: "#404040" }}
                  tabIndex={0}
                ></AccountCircleIcon>
              </div></div>
          </div>
          <div className={styles.line}></div>
        </div>
        <Modal
          isOpen={this.state.loading}
          className="loader-dialog"
          ariaHideApp={false}
        >
          <div className="loader-modal">
            <div className="loader"></div>
          </div>
        </Modal>
        <CommonDialog
          open={this.state.showMessage}
          title={this.state.msgtitle as string}
          message={this.state.message as string}
          doOk={() => this.setState({ showMessage: false })}
        ></CommonDialog>
      </div>
    );
  }
}

export default ContentHederTitle;

//
{
  /*options={top100Films.map((option) => option.title)}*/
}
{
  /*InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon className={styles.serchIcon}></SearchIcon>
                      </InputAdornment>
                    ),
                  }}*/
}
