import BarChartIcon from "@mui/icons-material/BarChart";
import DeleteIcon from "@mui/icons-material/Delete";
import DoneIcon from "@mui/icons-material/Done";
import ErrorIcon from "@mui/icons-material/Error";
import HelpIcon from "@mui/icons-material/Help";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import { Alert, TextField } from "@mui/material";
// ダイアログ
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Tooltip from "@mui/material/Tooltip";
import { Grid, _ } from "gridjs-react";
import "gridjs/dist/theme/mermaid.css";
import React, { ReactHTMLElement } from "react";
import Dropzone from "react-dropzone";
import Modal from "react-modal";
import { AwsAmplifyUtils } from "../../../../common/components/AwsAmplifyUtils";
import { FileReaderEx } from "../../../../common/lib/FileReaderEx";
import ContentHederTitle from "../../ContentHederTitle";
import CommonDialog from "../../Dialog";
import "./AnalysisPage.css";
import styles from "./AnalysisPage.module.scss";
import InputEvent from "../../../../common/utility/InputEvent";

/**
 * Props
 */
type Props = {};
interface SlidebarState {
  close: boolean;
}

/**
 * State
 */
interface AnalysisPageState {
  dragOver: boolean;
  dragOver1: boolean;
  dragOver2: boolean;
  files: Array<any>;
  files1: Array<any>;
  files2: Array<any>;
  titleError: number;
  error: number;
  error1: number;
  error2: number;
  movingButton: boolean;
  reportTitle: string;
  inputError: boolean;
  startAnalysis: boolean;
  loading: boolean;
  showMessage: boolean;
  title?: string;
  message?: string;
  learningData: Array<any>;
  AnalisysData: Array<any>;
  contractId: string;
  analysisStart: boolean;
  openConfDialog: boolean;
  confMessage: string;
}

/**
 * 波形比較
 */
export class AnalysisPage extends React.Component<any, AnalysisPageState> {
  /**
   * コンストラクタ
   * @param props Props
   */
  constructor(props: any) {
    super(props); // stateを初期化
    this.state = {
      dragOver: false,
      dragOver1: false,
      dragOver2: false,
      files: new Array(),
      files1: new Array(),
      files2: new Array(),
      titleError: -1,
      error: -1,
      error1: -1,
      error2: -1,
      movingButton: true,
      reportTitle: "",
      inputError: false,
      startAnalysis: false,
      loading: false,
      showMessage: false,
      learningData: new Array(),
      AnalisysData: new Array(),
      contractId: "",
      analysisStart: false,
      openConfDialog: false,
      confMessage: "",
    };
  }

  /***
   * 分析開始ボタン（小）
   * 表示/非表示
   */
  componentDidMount() {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          this.setState({ movingButton: false });
        } else {
          this.setState({ movingButton: true });
        }
      },
      { threshold: 0.25 }
    );
    observer.observe(document.getElementById("analysisStart") as any);
    // configure
    AwsAmplifyUtils.configure();
    // 契約ID（=cognitoグループ名称)
    AwsAmplifyUtils.getCognitoGroup().then((value) => {
      console.log(value);
      this.setState({ contractId: value });
    });
  }

  /***
   * ページトップへ
   */
  returnTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  /***
   * 画面遷移
   * @param endPoint 遷移先
   */
  openWindow(endPoint: string) {
    // windows.href = "/model";
    let trialURL = "";
    window.location.href = "./" + endPoint;
  }

  /***
   * 分析開始API
   */
  async startAnalysis() {
    this.setState({ loading: true });
    this.props.setLoading(true);
    const successTitle = "分析開始";
    const errorTitle = "分析開始エラー";
    const headers = { "Content-Type": "application/json" };
    let data = {
      type: "apr-simple",
      envId: 999,
      params: {
        contractId: this.state.contractId,
        title: this.state.reportTitle,
        learningDataCSV: this.state.learningData,
        detectionDataCSV: this.state.AnalisysData,
      },
    };

    console.log("分析開始", "分析が開始されました。");
    console.log(data);

    AwsAmplifyUtils.callAPI()
      .then((api) => {
        return api.API.post("api", api.apiPrefix + "/portalscheduler/start", {
          headers: headers,
          body: data,
        });
      })
      .then(async (res) => {
        if (res.errorCode === "OK") {
          // 遷移理由を設定
          this.props.setTransReason("startingAnalysis");
          // サブメニューを閉じておく
          this.props.setSideSubMenuOpenStatus(false);
          // レポート画面へ遷移する
          this.props.onMenuSelect("report");
        } else {
          this.props.setLoading(false);
          if (res.erroCode === "ArgumentError") {
            this.showMessage(errorTitle, "分析の開始に失敗しました。", false);
          } else {
            this.showMessage(errorTitle, "分析の開始に失敗しました。", false);
          }
        }
      })
      .catch((e) => {
        console.log(e);
        this.props.setLoading(false);
        this.showMessage(errorTitle, "分析の開始に失敗しました。", false);
      })
      .finally(() => {
        this.setState({
          loading: false,
          startAnalysis: false,
        });
      });
  }
  /***
   * 入力文字数チェック
   * タイトル
   * @param title タイトル
   * @returns true:OK / false: NG
   */
  checkInputText(title: string) {
    let len = 0;
    for (let i = 0; i < title.length; i++) {
      if (title[i].match(/[ -~]/)) {
        len += 0.5;
      } else {
        len += 1;
      }
    }
    if (len > 16) {
      return false;
    }
    return true;
  }
  /***
   * 分析開始ボタンクリック時
   */
  startAnalysisStatusTrue = () => {
    // 入力項目のチェック
    let titleError = -1;
    let learningError = -1;
    let analysisError = -1;
    if (this.state.reportTitle == "") {
      titleError = 1;
    } else {
      const result = this.checkInputText(this.state.reportTitle);
      if (!result) {
        titleError = 2;
      }
    }
    if (this.state.files.length == 0) {
      learningError = 1;
    } else if (this.state.files.find((f) => f.err) != undefined) {
      learningError = 2;
    }
    if (this.state.files1.length == 0) {
      analysisError = 1;
    } else if (this.state.files1.find((f) => f.err) != undefined) {
      analysisError = 2;
    }
    if (titleError + learningError + analysisError > -3) {
      this.returnTop();
      this.setState({
        titleError: titleError,
        error: learningError,
        error1: analysisError,
        inputError: true,
      });
    } else {
      // エラーメッセージを初期化
      this.setState({
        titleError: titleError,
        error: learningError,
        error1: analysisError,
        inputError: false,
      });

      // 確認ウィンドウ
      this.showConfirm("分析を開始します。よろしいでしょうか。");
    }
  };
  /***
   * エラーメッセージの編集
   * @returns res エラーメッセージ
   */
  createError() {
    let isNoTitle =
      this.state.titleError == 1 ? { display: "" } : { display: "none" };
    let isOverDigits =
      this.state.titleError == 2 ? { display: "" } : { display: "none" };
    let isNoLerningData =
      this.state.error == 1 ? { display: "" } : { display: "none" };
    let isBadLernignData =
      this.state.error == 2 ? { display: "" } : { display: "none" };
    let isNoAnalisysData =
      this.state.error1 == 1 ? { display: "" } : { display: "none" };
    let isBadAnalisysData =
      this.state.error1 == 2 ? { display: "" } : { display: "none" };
    let res = (
      <div>
        <Alert
          style={isNoTitle}
          sx={{ paddingTop: "0px", paddingBottom: "0px" }}
          severity="error"
        >
          タイトルが未入力です。
        </Alert>
        <Alert
          style={isOverDigits}
          sx={{ paddingTop: "0px", paddingBottom: "0px" }}
          severity="error"
        >
          タイトルの文字数オーバーです。（全角16文字以内）
        </Alert>
        <Alert
          style={isNoLerningData}
          sx={{ paddingTop: "0px", paddingBottom: "0px" }}
          severity="error"
        >
          学習データが未入力です。
        </Alert>
        <Alert
          style={isBadLernignData}
          sx={{ paddingTop: "0px", paddingBottom: "0px" }}
          severity="error"
        >
          学習データが不正です。
        </Alert>
        <Alert
          style={isNoAnalisysData}
          sx={{ paddingTop: "0px", paddingBottom: "0px" }}
          severity="error"
        >
          分析データが未入力です。
        </Alert>
        <Alert
          style={isBadAnalisysData}
          sx={{ paddingTop: "0px", paddingBottom: "0px" }}
          severity="error"
        >
          分析データが不正です。
        </Alert>
      </div>
    );
    return res;
  }
  /***
   * 列作成（学習用データ）
   * @returns hedItem ヘッダー
   */
  createColums() {
    let hedItem = [
      { id: "no", name: "No.", width: "40px" },
      { id: "filename", name: "ファイル名", width: "380px" },
      { id: "upload", name: "アップロード日時", width: "200px" },
      { id: "period", name: "データ期間", width: "295px" },
      { id: "judge", name: "", width: "40px" },
      { id: "del", name: "", width: "40px" },
    ];
    return hedItem;
  }
  /***
   * 列作成（分析用データ）
   * @returns hedItem ヘッダー
   */
  createColums1() {
    let hedItem = [
      { id: "no", name: "No.", width: "40px" },
      { id: "filename", name: "ファイル名", width: "380px" },
      { id: "upload", name: "アップロード日時", width: "200px" },
      { id: "period", name: "データ期間", width: "295px" },
      { id: "judge", name: "", width: "40px" },
      { id: "del", name: "", width: "40px" },
    ];
    return hedItem;
  }
  /***
   * 列作成（条件設定）
   * @returns hedItem ヘッダー
   */
  createColums2() {
    let hedItem = [
      { id: "no", name: "No.", width: "40px" },
      { id: "filename", name: "ファイル名", width: "380px" },
      { id: "upload", name: "アップロード日時", width: "200px" },
      { id: "period", name: "グループ数", width: "295px" },
      { id: "judge", name: "", width: "40px" },
      { id: "del", name: "", width: "40px" },
    ];
    return hedItem;
  }
  /***
   * アップロードファイル情報作成（学習用データ）
   * @returns Data ファイル情報
   */
  createRows() {
    let state = new Array();
    state = this.state.files;
    let Data = new Array();
    for (let index: number = 0; index < state.length; index++) {
      let styleDoneIcon = { display: "none" };
      let styleErrorIcon = { display: "none" };
      if (state[index].err) {
        styleErrorIcon = { display: "" };
      } else {
        styleDoneIcon = { display: "" };
      }
      let fromto = "";
      if (!state[index].err) {
        fromto = state[index].datastart + "　～　" + state[index].dataend;
      }
      let a = (
        <div>
          <DoneIcon
            sx={{ color: "green" }}
            className={styles.moreIcon}
            style={styleDoneIcon}
          ></DoneIcon>
        </div>
      );
      if (state[index].err) {
        a = (
          <div>
            <Tooltip
              title={
                <span style={{ whiteSpace: "pre-line" }}>
                  {this.state.files[index].errmsg}
                </span>
              }
            >
              <ErrorIcon
                sx={{ color: "red" }}
                className={styles.moreIcon}
                style={styleErrorIcon}
              ></ErrorIcon>
            </Tooltip>
          </div>
        );
      }
      Data.push([
        index + 1,
        state[index].name,
        state[index].upload,
        fromto,
        _(a),
        _(
          <div>
            <DeleteIcon
              sx={{ color: "gray" }}
              className={styles.icon}
              onClick={(event) => this.deleteFile(index)}
              onKeyDown={(e) => {
                this.keyDown(e, () => this.deleteFile(index));
              }}
              tabIndex={0}
            ></DeleteIcon>
          </div>
        ),
        ,
      ]);
    }
    return Data;
  }
  /***
   * アップロードファイル情報作成（分析用データ）
   * @returns Data ファイル情報
   */
  createRows1() {
    let state = new Array();
    state = this.state.files1;
    let Data = new Array();
    for (let index: number = 0; index < state.length; index++) {
      let styleDoneIcon = { display: "none" };
      let styleErrorIcon = { display: "none" };
      if (state[index].err) {
        styleErrorIcon = { display: "" };
      } else {
        styleDoneIcon = { display: "" };
      }
      let fromto = "";
      if (!state[index].err) {
        fromto = state[index].datastart + "　～　" + state[index].dataend;
      }
      let a = (
        <div>
          <DoneIcon
            sx={{ color: "green" }}
            className={styles.moreIcon}
            style={styleDoneIcon}
          ></DoneIcon>
        </div>
      );
      if (state[index].err) {
        a = (
          <div>
            <Tooltip
              title={
                <span style={{ whiteSpace: "pre-line" }}>
                  {this.state.files1[index].errmsg}
                </span>
              }
            >
              <ErrorIcon
                sx={{ color: "red" }}
                className={styles.moreIcon}
                style={styleErrorIcon}
              ></ErrorIcon>
            </Tooltip>
          </div>
        );
      }
      Data.push([
        index + 1,
        state[index].name,
        state[index].upload,
        fromto,
        _(a),
        _(
          <div>
            <DeleteIcon
              sx={{ color: "gray" }}
              className={styles.icon}
              onClick={(event) => this.deleteFile1(index)}
              onKeyDown={(e) => {
                this.keyDown(e, () => this.deleteFile1(index));
              }}
              tabIndex={0}
            ></DeleteIcon>
          </div>
        ),
        ,
      ]);
    }
    return Data;
  }
  /***
   * アップロードファイル情報作成（条件設定）
   * @returns Data ファイル情報
   */
  createRows2() {
    let state = new Array();
    state = this.state.files2;
    let Data = new Array();
    for (let index: number = 0; index < state.length; index++) {
      let a = (
        <div>
          <ErrorIcon
            sx={{ color: "red" }}
            className={styles.moreIcon}
          ></ErrorIcon>
        </div>
      );
      if (index == 1) {
        a = (
          <div>
            <DoneIcon
              sx={{ color: "green" }}
              className={styles.moreIcon}
            ></DoneIcon>
          </div>
        );
      }
      Data.push([
        index + 1,
        state[index].name,
        "2022/0/18 14:19:58",
        12 + index,
        _(a),
        _(
          <div>
            <DeleteIcon
              sx={{ color: "gray" }}
              className={styles.icon}
            //onClick={(event) => this.ttt()}
            ></DeleteIcon>
          </div>
        ),
        ,
      ]);
    }
    return Data;
  }
  /***
   * 日付 YYYY/MM/DD hh:mm:ss
   * ゼロ詰めなし
   * @param datetime 日時
   * @returns format(yyyy/M/d h:m:ss)
   */
  convDate(datetime: Date) {
    let format = "Y/M/D h:m:s";
    format = format.replace(/Y/g, datetime.getFullYear() as any);
    format = format.replace(/M/g, (datetime.getMonth() + 1) as any);
    format = format.replace(/D/g, datetime.getDate() as any);
    format = format.replace(/h/g, datetime.getHours() as any);
    format = format.replace(
      /m/g,
      String(datetime.getMinutes()).padStart(2, "0") as any
    );
    format = format.replace(
      /s/g,
      String(datetime.getSeconds()).padStart(2, "0") as any
    );
    return format;
  }
  /***
   * ファイルデータの
   * フォーマットチェック
   * @param data ファイルデータ
   * @returns -1：OK / i: NG
   */
  checkFileData(data: any) {
    for (let i: number = 0; i < data.length; i++) {
      let line = data[i];
      if (line.match(/^\n/)) {
        if (i != 4) {
          // 行にデータがない(null)
          return {
            index: i,
            msg: "空行があります。",
          };
        }
        continue;
      }
      let array = line.split(",");
      if (array[0] == null) {
        // 項目名がない
        return {
          index: i,
          msg: "データがありません。",
        };
      }
      switch (i) {
        case 0:
          if (
            array[0].length == 0 ||
            !array[0].startsWith("タグ番号(TAG番号)")
          ) {
            // エラー：タグ番号
            return {
              index: i,
              msg: "タグ番号(TAG番号)のデータがありません。",
            };
          }
          break;
        case 1:
          if (array[0].length == 0 || !array[0].startsWith("信号名称")) {
            // エラー：信号名称
            return {
              index: i,
              msg: "信号名称のデータがありません。",
            };
          }
          break;
        case 2:
          if (array[0].length == 0 || !array[0].startsWith("単位")) {
            // エラー：単位
            return {
              index: i,
              msg: "単位のデータがありません。",
            };
          }
          break;
        default:
          if (i === data.length - 1 && array[0] === "") {
            // 最終行に何も入っていない場合はスルー
            break;
          }
          // 日付形式チェック true: OK, false: NG
          let result = this.checkDateFormat(array[0]);
          if (!result) {
            return {
              index: i,
              msg: "日付フォーマットが不正です。",
            };
          }
          // プロセス値チェック
          for (let j = 1; j < array.length; j++) {
            if (array[j] == "-----" || array[j] == "*****") {
              // 欠測------,不良*****
              continue;
            }

            // 整数または小数点数
            if (Number.isNaN(array[j])) {
              return {
                index: i,
                msg: "不正なデータがあります。",
              };
            }
            if (
              !array[j].match(/^-?[0-9]+$/) &&
              !array[j].match(/^-?[0-9]+\.[0-9]+$/)
            ) {
              return {
                index: i,
                msg: "数値以外のデータがあります。",
              };
            }
          }
      }
    }
    return {
      index: -1,
      msg: "",
    };
  }
  /***
   * 日時フォーマットチェック
   * @param datetime 日時
   * @returns true：OK / false: NG
   */
  checkDateFormat(datetime: string) {
    if (datetime == null || datetime.length < 13 || datetime.length > 19) {
      return false;
    }
    let idx = datetime.indexOf(" ");
    let date = datetime.substring(0, idx);
    let time = datetime.substring(idx + 1, datetime.length - 1);
    let d = date.match(/^(\d+)\/(\d+)\/(\d+)$/);
    if (d) {
      let year = parseInt(d[1]);
      let month = parseInt(d[2]) - 1;
      let day = parseInt(d[3]);
      let ymd = new Date(year, month, day);
      if (
        year != ymd.getFullYear() ||
        month != ymd.getMonth() ||
        day != ymd.getDate()
      ) {
        return false;
      }
    } else {
      return false;
    }
    let t = time.match(/^(\d+):(\d+):(\d+)$/);
    if (t) {
      let hour = parseInt(t[1]);
      let min = parseInt(t[2]);
      let sec = parseInt(t[3]);
      if (hour < 0 || hour > 23 || min < 0 || min > 59 || sec < 0 || sec > 59) {
        return false;
      }
    }
    return true;
  }
  /***
   * ファイルアップロード（学習用データ）
   * @param file ファイル情報
   */
  async fileGet(file: any) {
    let list = this.state.files;
    let filedata = this.state.learningData;
    for (let index: number = 0; index < file.length; index++) {
      let err = false;
      let errdata = "";
      let errmsg = "";
      let upload = this.convDate(new Date());
      let datastart = "";
      let dataend = "";
      const reader = new FileReaderEx();
      await reader.readAsText(file[index], "shift-jis");
      if (reader.result == null) {
        console.log("ファイルが読込めませんでした。");
        err = true;
        errmsg = "ファイルが読込めませんでした。";
      } else {
        filedata.push(reader.result as any);
        let data = (reader.result as any).split("\r\n");
        let res = this.checkFileData(data);
        if (res.index == -1) {
          //エラーなし
          let i = data[5].indexOf(" ");
          datastart = data[5].substring(0, i);
          // 最終行が空行の場合を考慮
          let hosei = data[data.length - 1] === "" ? 2 : 1;
          i = data[data.length - hosei].indexOf(" ");
          dataend = data[data.length - hosei].substring(0, i);
        } else {
          //エラーあり
          err = true;
          errdata = res.index + "," + data[res.index];
          errmsg =
            res.msg +
            "\n" +
            "Line: " +
            res.index +
            ", Data: " +
            data[res.index];
        }
      }

      list.push({
        name: file[index].name,
        err: err,
        errdata: errdata,
        errmsg: errmsg,
        upload: upload,
        datastart: datastart,
        dataend: dataend,
      });
    }
    this.setState({
      dragOver: false,
      files: list,
      learningData: filedata,
    });
  }
  /***
   * ファイルアップロード（分析用データ）
   * @param file ファイル情報
   */
  async fileGet1(file: any) {
    let list = this.state.files1;
    let filedata = this.state.AnalisysData;
    for (let index: number = 0; index < file.length; index++) {
      let err = false;
      let errdata = "";
      let errmsg = "";
      let upload = this.convDate(new Date());
      let datastart = "";
      let dataend = "";
      const reader = new FileReaderEx();
      await reader.readAsText(file[index], "shift-jis");
      if (reader.result == null) {
        console.log("ファイルが読込めませんでした。");
        err = true;
        errmsg = "ファイルが読込めませんでした。";
      } else {
        filedata.push(reader.result as any);
        let data = (reader.result as any).split("\r\n");
        let res = this.checkFileData(data);
        if (res.index == -1) {
          // エラーなし
          let i = data[5].indexOf(" ");
          datastart = data[5].substring(0, i);
          // 最終行が空行の場合を考慮
          let hosei = data[data.length - 1] === "" ? 2 : 1;
          i = data[data.length - hosei].indexOf(" ");
          dataend = data[data.length - hosei].substring(0, i);
        } else {
          // エラーあり
          err = true;
          errdata = res.index + "," + data[res.index];
          errmsg =
            res.msg +
            "\n" +
            "Line: " +
            res.index +
            ", Data: " +
            data[res.index];
        }
      }

      list.push({
        name: file[index].name,
        err: err,
        errdata: errdata,
        errmsg: errmsg,
        upload: upload,
        datastart: datastart,
        dataend: dataend,
      });
    }
    this.setState({
      dragOver1: false,
      files1: list,
      AnalisysData: filedata,
    });
  }
  /***
   * ファイルアップロード（条件設定）
   * @param file ファイル情報
   */
  fileGet2(file: any) {
    let list = this.state.files2;
    for (let index: number = 0; index < file.length; index++) {
      list.push({
        name: file[index].name,
      });
    }
    this.setState({
      dragOver2: false,
      files2: list,
    });
  }

  dragLeave() {
    this.setState({
      dragOver: false,
    });
  }
  dragLeave1() {
    this.setState({
      dragOver1: false,
    });
  }
  dragLeave2() {
    this.setState({
      dragOver2: false,
    });
  }
  dragOver() {
    this.setState({
      dragOver: true,
    });
  }
  dragOver1() {
    this.setState({
      dragOver1: true,
    });
  }
  dragOver2() {
    this.setState({
      dragOver2: true,
    });
  }
  /***
   * アップロードデータ削除（学習用データ）
   * @param index
   */
  deleteFile(index: number) {
    let state = new Array();
    let data = new Array();
    state = this.state.files;
    state.splice(index, 1);
    data = this.state.learningData;
    data.splice(index, 1);
    this.setState({
      dragOver: false,
      files: state,
      learningData: data,
    });
  }
  /***
   * アップロードデータ削除（分析用データ）
   * @param index
   */
  deleteFile1(index: number) {
    let state = new Array();
    let data = new Array();
    state = this.state.files1;
    state.splice(index, 1);
    data = this.state.AnalisysData;
    data.splice(index, 1);
    this.setState({
      dragOver1: false,
      files1: state,
      AnalisysData: data,
    });
  }
  /***
   * アップロードデータ削除（条件設定）
   * @param index
   */
  deleteFile2(index: number) {
    let state = new Array();
    state = this.state.files2;
    state.splice(index, 1);
    this.setState({
      dragOver2: false,
      files2: state,
    });
  }
  /***
   * メッセージダイアログの設定
   * @param title ダイアログタイトル
   * @param message メッセージ
   * @param start 分析開始成功:true,分析開始エラー:false
   */
  showMessage(title: string, message: string, start: boolean) {
    this.setState({
      showMessage: true,
      title: title,
      message: message,
      analysisStart: start,
    });
  }

  /***
   * 分析開始時のダイアログでokを押したときの動作
   */
  okDialog() {
    this.setState({ showMessage: false });

    // 分析開始に成功した場合
    if (this.state.analysisStart) {
      //レポート画面に遷移
      this.props.onMenuSelect("report");
    }
  }

  /***
   * 分析確認ダイアログの設定
   * @param message メッセージ
   */
  showConfirm(message: string) {
    this.setState({
      openConfDialog: true,
      confMessage: message,
    });
  }

  /**
   * キー押下時
   *
   * @param e
   */
  keyDown(e: any, f: any) {
    if (InputEvent.checkClickEnter(e)) {
      f();
    }
  }

  /**
   * フォーマットCSVのダウンロード
   * @returns
   */
  downloadforamtCSV() {
    const errorTitle = "データフォームダウンロード失敗";
    const headers = { "Content-Type": "application/json" };

    AwsAmplifyUtils.callAPI()
      .then((api) => {
        return api.API.get(
          "api",
          api.apiPrefix + "/aprmanager/simple/format/sample.csv",
          {
            responseType: "blob",
            headers: headers,
          }
        );
      })
      .then(async (res) => {
        console.log("データフォームダウンロード成功");
        const link = document.createElement("a");
        document.body.appendChild(link);
        link.download = "sample.csv";
        link.href = URL.createObjectURL(res);
        link.click();
        link.remove();
        URL.revokeObjectURL(link.href);
      })
      .catch((e) => {
        console.log(e);
        this.showMessage(
          errorTitle,
          "データフォームダウンロードに失敗しました。",
          false
        );
      });
  }

  render(): React.ReactNode {
    let active = "";
    let active1 = "";
    let active2 = "";
    if (this.state.dragOver) {
      active = styles.dropZoneActive;
    }
    if (this.state.dragOver1) {
      active1 = styles.dropZoneActive;
    }
    if (this.state.dragOver2) {
      active2 = styles.dropZoneActive;
    }
    let columns = this.createColums();
    let rows = this.createRows();
    let columns1 = this.createColums1();
    let rows1 = this.createRows1();
    let columns2 = this.createColums2();
    let rows2 = this.createRows2();

    // 必須項目入力チェック
    let errorDiv;
    // if (this.state.demoError) {
    //   errorDiv = this.createError();
    // }
    if (this.state.inputError) {
      errorDiv = this.createError();
    }
    return (
      <div>
        <div className={styles.AnalysisDiv}>
          <div className={styles.ReportPage}>
            <ContentHederTitle
              title={"波形比較サービス"}
              icon={BarChartIcon}
            ></ContentHederTitle>
          </div>
          <div className={styles.ErrorDiv}>{errorDiv}</div>
          <div className={styles.SetteingDiv}>
            {/**タイトルエリア */}
            <div className={styles.SettingTitleDiv}>
              <div className={styles.SettingTitle}>基本情報</div>
              <div className={styles.SettingTitleComeent}>
                レポートのタイトルを入力します。
              </div>
              <div className={styles.interval_before}></div>
              <div className={styles.inputTitleArea}>
                <div className={styles.lerningCsvTileDiv}>
                  <div>タイトル</div>
                  <div className={styles.required_icon}>必須</div>
                </div>
                <TextField
                  style={{ width: "350px", background: "white" }}
                  variant="outlined"
                  defaultValue=""
                  size="small"
                  value={this.state.reportTitle}
                  onChange={(event) =>
                    this.setState({ reportTitle: event.target.value })
                  }
                /*helperText="Incorrect entry."*/
                ></TextField>
              </div>
              <div className={styles.interval_after}></div>
            </div>
            {/**csvデータアップロードエリア */}
            <div className={styles.SettingTitleDiv}>
              <div className={styles.SettingTitle}>
                <span lang="en">CSV</span>
                データアップロード
              </div>
              <div className={styles.SettingTitleComeent}>
                異常分析に必要となる学習用データ・分析用データをアップロードします。
                <a
                  className={styles.link}
                  href="javascript:void(0)"
                  onClick={() => this.downloadforamtCSV()}
                >
                  データフォームダウンロード
                </a>
              </div>
              <div className={styles.interval_before}></div>
              {/**　学習用データ */}
              <div className={styles.lerningCsvArea}>
                <div className={styles.lerningCsvTileDiv}>
                  <div>学習用データ</div>
                  <div className={styles.required_icon}>必須</div>
                </div>
                <Dropzone
                  onDrop={
                    (acceptedFiles) =>
                      this.fileGet(acceptedFiles) /*console.log(acceptedFiles)*/
                  }
                  onDragOver={(event) => {
                    event.preventDefault();
                    this.dragOver();
                  }}
                  onDragLeave={(event) => this.dragLeave()}
                >
                  {({ getRootProps, getInputProps }) => (
                    <section className={styles.dropZone + " " + active}>
                      <div  className={styles.maxSize} {...getRootProps()}>
                        <input {...getInputProps()} />
                        <p className={styles.dropZoneComment + " " + styles.maxSize}>
                          ここに指定のファイルをドロップ or
                          クリックでファイルを指定してください。
                        </p>
                      </div>
                    </section>
                  )}
                </Dropzone>
              </div>
              <div className={styles.tableDiv}>
                <Grid sort={false} data={rows} columns={columns} />
              </div>
              <div className={styles.interval}></div>
              {/**　分析用データ */}
              <div className={styles.lerningCsvArea}>
                <div className={styles.lerningCsvTileDiv}>
                  <div>分析用データ</div>
                  <div className={styles.required_icon}>必須</div>
                </div>
                <Dropzone
                  onDrop={
                    (acceptedFiles1) =>
                      this.fileGet1(
                        acceptedFiles1
                      ) /*console.log(acceptedFiles)*/
                  }
                  onDragOver={(event) => {
                    event.preventDefault();
                    this.dragOver1();
                  }}
                  onDragLeave={(event) => this.dragLeave1()}
                >
                  {({ getRootProps, getInputProps }) => (
                    <section className={styles.dropZone + " " + active1}>
                      <div className={styles.maxSize} {...getRootProps()}>
                        <input {...getInputProps()} />
                        <p className={styles.dropZoneComment + " " + styles.maxSize}>
                          ここに指定のファイルをドロップ or
                          クリックでファイルを指定してください。
                        </p>
                      </div>
                    </section>
                  )}
                </Dropzone>
              </div>
              <div className={styles.tableDiv}>
                <Grid sort={false} data={rows1} columns={columns1} />
              </div>
              {/*<div className={styles.interval_after}></div>*/}
            </div>
            {/**条件設定エリア }
            <div className={styles.SettingTitleDiv}>
              <div className={styles.SettingTitle}>条件設定</div>
              <div className={styles.SettingTitleComeent}>
                設定データを追加することで、より精度の高い異常兆候分析が可能です。
                <Link className={styles.link}>データフォームダウンロード</Link>
              </div>
              <div className={styles.interval_before}></div>
              {/**　設定データ }
              <div className={styles.lerningCsvArea}>
                <div className={styles.lerningCsvTileDiv}>
                  <div>設定</div>
                  <HelpIcon sx={{ color: "gray" }}></HelpIcon>
                </div>
                <Dropzone
                  onDrop={
                    (acceptedFiles) =>
                      this.fileGet2(
                        acceptedFiles
                      ) /*console.log(acceptedFiles)
                  }
                  onDragOver={(event) => this.dragOver2()}
                  onDragLeave={(event) => this.dragLeave2()}
                >
                  {({ getRootProps, getInputProps }) => (
                    <section className={styles.dropZone + " " + active2}>
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <p className={styles.dropZoneComment}>
                          ここに指定のフィルをドロップ or
                          クリックでファイルを指定してください。
                        </p>
                      </div>
                    </section>
                  )}
                </Dropzone>
              </div>
              <div className={styles.tableDiv}>
                <Grid sort={false} data={rows2} columns={columns2} />
              </div> 
              <div className={styles.interval_after}></div>
            </div>*/}
            <div className={styles.interval_after}></div>
          </div>
          {/**ページトップ */}
          <div
            className={styles.pageTopDiv}
            onClick={this.returnTop}
            tabIndex={0}
            onKeyDown={(e) => this.keyDown(e, () => this.returnTop())}
          >
            <div>▲</div>
            <div>
              <span lang="en">PAGE TOP</span>
            </div>
          </div>
          {/**分析開始ボタン */}
          <div id="analysisStart" className={styles.addButonArea}>
            <button
              className={styles.addButon}
              onClick={this.startAnalysisStatusTrue}
            >
              分析を開始
            </button>
          </div>
          <div className={styles.interval_after}></div>
          {/**分析開始ボタン　動的のやつ */}
          {/* <div className={styles.StartMoveDiv} onClick={this.demoError}> */}
          <div
            className={styles.StartMoveDiv}
            style={
              this.state.movingButton ? { display: "" } : { display: "none" }
            }
            onClick={this.startAnalysisStatusTrue}
          >
            <div className={styles.StartMoveDivIcon}>
              <ShowChartIcon
                className={styles.StartMoveIcon}
                sx={{
                  fontSize: "46px",
                  color: "white",
                  top: "5px",
                  position: "relative",
                }}
              ></ShowChartIcon>
              <div className={styles.sideButton}>分析開始</div>
            </div>
            {/*<div className={styles.StartMoveText}>分析を開始</div>*/}
          </div>
          <div id="startButton" style={{ display: "none" }}>
            <button onClick={this.startAnalysis}></button>
          </div>
          <Modal
            isOpen={this.state.loading}
            className="loader-dialog"
            ariaHideApp={false}
          >
            <div className="loader-modal">
              <div className="loader"></div>
            </div>
          </Modal>
          <CommonDialog
            open={this.state.showMessage}
            title={this.state.title as any}
            message={this.state.message as any}
            doOk={() => this.okDialog()}
          ></CommonDialog>
          <Dialog
            open={this.state.openConfDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {this.state.confMessage}
            </DialogTitle>
            <DialogActions>
              <Button
                onClick={() => {
                  this.startAnalysis();
                  this.setState({
                    openConfDialog: false,
                  });
                }}
              >
                はい
              </Button>
              <Button
                autoFocus
                onClick={() => {
                  this.setState({
                    openConfDialog: false,
                  });
                }}
              >
                いいえ
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    );
  }
}

export default AnalysisPage;
