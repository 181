import React from "react";
import styles from "./Mypage.module.scss";
import { Profile } from "./parts/Profile";
import { Service } from "./parts/Service";
import { AwsAmplifyUtils } from "../common/components/AwsAmplifyUtils";
export class Mypage extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      userId: null,
      profile: {
        icon: null,
        nickname: null,
        date: null,
        duration: null,
      },
      getData: false,
    };
  }

  /**
   * 初期データロード
   */
  componentDidMount() {
    // configure
    AwsAmplifyUtils.configure();
    // ユーザ名取得
    AwsAmplifyUtils.checkAuthenticated().then((value) => {
      this.setState({ userId: value.username });
      console.log(this.state.userId);
      // ユーザ情報取得
      this.getUserInfo(this.state.userId);
    });
  }

  /**
   * ユーザ情報を取得する
   * @param userId ユーザID
   */
  getUserInfo = (userId: any) => {
    const headers = { "Content-Type": "application/json" };
    let data = {
      userId: userId,
    };

    AwsAmplifyUtils.callAPI()
      .then((api) => {
        return api.API.post(
          "api",
          api.apiPrefix + "/userinfomanager/getuserinfo",
          {
            headers: headers,
            body: data,
          }
        );
      })
      .then((res) => {
        if (res.errorCode == 0) {
          var data = res.data;

          this.setState({
            userId: userId,
            profile: {
              icon: data.photoData,
              nickname: data.nickName,
              date: data.registDate,
              duration: this.calcDuration(data.registDate),
            },
            getData: true,
          });
          console.log(this.state);
        } else {
          if (res.errorCode == 1) {
            alert(res.errorMessage);
          } else {
            alert(res.errorMessage);
          }
        }
      })
      .catch((e) => {
        console.log(e);
        alert(e);
      })
      .finally(() => {
        //何もしない
      });
  };

  /**
   * iconのstateを変更
   * @param base64Data base64エンコードした画像
   */
  setIcon = (base64Data: any) => {
    this.setState({ profile: { icon: base64Data } });
  };

  /**
   * ご利用期間を計算
   * @param resistDate
   */
  calcDuration = (resistDate: any) => {
    // ページを読み込んだ日時=現在日時
    var loadDate = new Date();
    // 計算の基準となる日時
    var distDate = new Date(resistDate);
    // 日時の差を取得
    var diffMilliSec = loadDate.getTime() - distDate.getTime();
    //　何年に変換
    var diffDays = Math.floor(diffMilliSec / 1000 / 60 / 60 / 24 / 30 / 12);
    return diffDays;
  };

  render(): React.ReactNode {
    var nicknameVal =
      this.state.profile.nickname != null && this.state.profile.nickname != ""
        ? this.state.profile.nickname
        : "ゲスト";

    return (
      <div className={styles.outside}>
        <div className={styles.src_content}>
          <div className={styles.src_Main_Content}>
            <div className={styles.App_App}>
              <div>
                <div
                  className={
                    this.state.getData
                      ? styles.title
                      : styles.title + " " + styles.hidden
                  }
                >
                  {nicknameVal}さん マイページへようこそ
                </div>
                <div className={styles.link}>
                  <span lang="en">ANALYSIS PORTAL&nbsp;&lt;X-chAIn&gt;</span> ページは
                  <a href="." target="top">
                    こちら
                  </a>
                </div>
              </div>
              <div className={styles.App_AppArea}>
                <div className={styles.App_flex}>
                  <div>
                    <Profile
                      userId={this.state.userId}
                      profile={this.state.profile}
                      setIcon={this.setIcon}
                      getUserInfo={this.getUserInfo}
                      nicknameVal={nicknameVal}
                      getData={this.state.getData}
                    ></Profile>
                  </div>
                  <div>
                    <Service
                      userId={this.state.userId}
                      profile={this.state.profile}
                      nicknameVal={nicknameVal}
                    ></Service>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
