import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { AwsAmplifyUtils } from "./common/components/AwsAmplifyUtils";

interface Props {
  component: React.ReactNode;
  isLogged: boolean;
  redirect: string;
}
/**
 * 内部コンテキスト
 */
interface CheckAuthProps {
  /** 認証状態 */ 
  isAuthed: boolean;
  /** 認証状態取得中かどうか(true：取得中、false：取得完了) */
  isLoading: boolean;
}
/**
 * ログイン認証済みかどうかチェックする
 * 未認証の場合はパラメータで渡されるURLへのリダイレクト用DOMを返す
 */
export class CheckAuth extends React.Component<Props, CheckAuthProps> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isAuthed: false,
      isLoading: true
    };
  }

  async componentDidMount() {
    // AwsAmplifyのコンフィグレーション設定
    AwsAmplifyUtils.configure();
    try {
      // 認証状態を取得
      const user = await AwsAmplifyUtils.checkAuthenticated();
      console.log("user authenticated.");
      this.setState({
        isAuthed: true
      });
    } catch (err) {
      console.log(err);
    } finally {
      this.setState({isLoading: false});
    }
  }
  
  render() {
    const { isLoading } = this.state;
    if (isLoading) {
      return <></>;
    } else {
      if (this.state.isAuthed === this.props.isLogged) {
        // 認証状態であれば渡されたコンポーネントをそのまま返す
        return <>{this.props.component}</>;
      } else {
        // 未認証状態であれば指定コンポーネントへリダイレクトするDOMを返す
        return <Navigate to={this.props.redirect} state={{from: useLocation}} replace={false} />
      }
    }
  }
}