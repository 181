import React from "react";
import styles from "./PasswordComplete.module.scss";
import { useLocation, useNavigate } from "react-router-dom";

import {
  BreadcrumbHeader,
  Breadcrumb,
  BreadcrumbList,
} from "../common/components/BreadcrumbHeader";
import {
  AwsAmplifyUtils,
  AWS_TOKEN_SAVE,
} from "../common/components/AwsAmplifyUtils";
import { CognitoUser } from "amazon-cognito-identity-js";

interface IUploadViewProps {
  breadcrumb: {};
  formNewPasswordError: Boolean;
  formConfirmPasswordError: Boolean;
  formCompleteInvalidError: Boolean;
  formCompleteLimitExceededError: Boolean;
  formCompleteUnknownError: Boolean;
  formCompleteQuestionError: Boolean;
  formCompleteAnswerError: Boolean;
  userId: string;
  newPassword: string;
  confirmPassword: string;
  questionId: number;
  answer: string;
  questionList: Array<QuestionContents>;
}

type QuestionContents = {
  questionId: number;
  question: string;
};

// hookを使用するためクラスを関数でラップ
export default function PasswordCompleteLocation(props: any) {
  // useLocationを取得し、クラスにlocationを渡す
  const location = useLocation();

  return <PasswordComplete {...props} location={location} />;
}

export class PasswordComplete extends React.Component<any, IUploadViewProps> {
  constructor(props: any) {
    super(props);

    // ユーザ情報取得
    var userInfo = this.props.getUserInfo();

    // ログインページから遷移していない場合トップページにリダイレクト
    if (!userInfo) {
      window.location.href = "/";
    }

    this.state = {
      breadcrumb: [
        { name: "ANALYSIS PORTAL <X-chAIn>紹介ページ", href: "../" },
        { name: "初回パスワード設定ページ", href: "/" },
      ],
      formNewPasswordError: false,
      formConfirmPasswordError: false,
      formCompleteInvalidError: false,
      formCompleteLimitExceededError: false,
      formCompleteUnknownError: false,
      formCompleteQuestionError: false,
      formCompleteAnswerError: false,
      userId: props.location.state.userId,
      newPassword: "",
      confirmPassword: "",
      questionId: -1,
      answer: "",
      // 秘密の質問リスト
      questionList: [],
    };

    this.handleNewPasswordChanged = this.handleNewPasswordChanged.bind(this);
    this.handleConfirmPasswordChanged =
      this.handleConfirmPasswordChanged.bind(this);
    this.completePassword = this.completePassword.bind(this);

    AwsAmplifyUtils.configure();

    // ユーザ名取得
    AwsAmplifyUtils.checkAuthenticated().then((value) => {
      this.setState({ userId: value.username });
    });
  }

  /**
   * 初期データロード
   */
  componentDidMount() {
    // 「秘密の質問」取得
    this.getQuestionInfo();
  }

  /**
   * 「秘密の質問」を取得する
   */
  getQuestionInfo = () => {
    const headers = { "Content-Type": "application/json" };
    let data = {};
    AwsAmplifyUtils.callAPI(true)
      .then((api) => {
        return api.API.post(
          "api",
          api.apiPrefix + "/passwordresetmanager/question/get",
          {
            headers: headers,
            body: data,
          }
        );
      })
      .then((res) => {
        if (res.errorCode === "OK") {
          console.log(res.questionList);
          const n = res;
          const ndata = new Array<QuestionContents>();
          n.questionList.forEach((m: QuestionContents) => {
            ndata.push(m);
          });

          console.log(ndata);

          // 秘密の質問のsetState
          this.setState({
            questionList: ndata,
          });
          console.log(this.state);
        } else {
          this.setState({ formCompleteUnknownError: true });
        }
      })
      .catch((e) => {
        console.log(e);
        this.setState({ formCompleteUnknownError: true });
      })
      .finally(() => {
        //何もしない
      });
  };

  /**
   * キー押下処理
   *
   * @param e キーイベント
   */
  keyPress(e: React.KeyboardEvent<any>) {
    if (e.key === "Enter") {
      // ログイン処理
      this.completePassword();
    }
  }

  /**
   * 初回パスワード設定ボタン押下時処理
   */
  completePassword() {
    this.setState({ formNewPasswordError: false });
    this.setState({ formConfirmPasswordError: false });
    this.setState({ formCompleteInvalidError: false });
    this.setState({ formCompleteLimitExceededError: false });
    this.setState({ formCompleteUnknownError: false });

    var userInfo = this.props.getUserInfo();

    // 初回パスワードのフォーマットチェック
    if (this.state.newPassword == "") {
      this.setState({ formNewPasswordError: true });
      return;
    }

    // 初回パスワードと確認パスワードの一致チェック
    if (this.state.newPassword != this.state.confirmPassword) {
      this.setState({ formConfirmPasswordError: true });
      return;
    }

    // 秘密の質問のフォーマットチェック
    if (this.state.questionId == -1) {
      this.setState({ formCompleteQuestionError: true });
      return;
    }

    // 秘密の質問(回答)のフォーマットチェック
    if (this.state.answer == "") {
      this.setState({ formCompleteAnswerError: true });
      return;
    }

    // 認証済みユーザ情報があれば初回パスワード設定
    if (userInfo) {
      AwsAmplifyUtils.completePassword(userInfo, this.state.newPassword)
        .then((user) => {
          // 完了時メッセージを表示
          console.log("completePassword completed");

          // 秘密の質問登録API実行
          this.saveQuestion(user);
        })
        .catch((error) => {
          console.log(error);
          // 不正なパスワード
          if (
            error.name == "InvalidPasswordException" ||
            error.name == "InvalidParameterException"
          ) {
            this.setState({ formCompleteInvalidError: true });
          } else if (error.Name == "LimitExceededException") {
            this.setState({ formCompleteLimitExceededError: true });
          } else {
            this.setState({ formCompleteUnknownError: true });
          }
        });
    } else {
      this.setState({ formCompleteUnknownError: true });
    }
  }

  /**
   * 秘密の質問登録API実行
   * @param user CognitoUser オブジェクト
   */
  saveQuestion(user: any) {
    const headers = { "Content-Type": "application/json" };
    let data = {
      userId: this.state.userId,
      questionId: this.state.questionId,
      answer: this.state.answer,
      accessToken: user.signInUserSession.accessToken.jwtToken,
    };
    AwsAmplifyUtils.callAPI(true)
      .then((api) => {
        return api.API.post(
          "api",
          api.apiPrefix + "/passwordresetmanager/answer/save",
          {
            headers: headers,
            body: data,
          }
        );
      })
      .then(async (res) => {
        if (res.errorCode === "OK") {
          const msgtitle = "秘密の質問登録API成功";
          console.log(msgtitle);

          // パスワード同期API実行
          this.syncPassword(user);
        } else {
          const msgtitle = "秘密の質問登録API実行エラー";
          console.log(msgtitle);
          this.setState({ formCompleteUnknownError: true });
        }
      })
      .catch((e) => {
        console.log(e);
        this.setState({ formCompleteUnknownError: true });
      });
  }

  /**
   * パスワード同期API実行
   * @param user CognitoUser オブジェクト
   */
  syncPassword(user: any) {
    const headers = { "Content-Type": "application/json" };
    let data = {
      userId: this.state.userId,
      accessToken: user.signInUserSession.accessToken.jwtToken,
      newPassword: this.state.newPassword,
    };
    AwsAmplifyUtils.callAPI(true)
      .then((api) => {
        return api.API.post(
          "api",
          api.apiPrefix + "/passwordresetmanager/password/sync",
          {
            headers: headers,
            body: data,
          }
        );
      })
      .then(async (res) => {
        if (res.errorCode === "OK") {
          const msgtitle = "パスワード同期API成功";
          console.log(msgtitle);

          // トップ画面に遷移
          window.location.href = "/";
        } else {
          const msgtitle = "パスワード同期API実行エラー";
          console.log(msgtitle);
          this.setState({ formCompleteUnknownError: true });
        }
      })
      .catch((e) => {
        console.log(e);
        this.setState({ formCompleteUnknownError: true });
      });
  }

  /**
   * 新しいパスワード欄入力内容変更時処理
   * @param e
   */
  handleNewPasswordChanged(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({
      newPassword: e.target.value,
    });
  }

  /**
   * 新しいパスワード（確認）欄入力内容変更時処理
   * @param e
   */
  handleConfirmPasswordChanged(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({
      confirmPassword: e.target.value,
    });
  }

  /**
   * 秘密の質問欄入力内容変更時処理
   * @param e
   */
  handleQuestionChanged(e: React.ChangeEvent<HTMLSelectElement>) {
    this.setState({
      questionId: parseInt(e.target.value),
    });
  }

  /**
   *  秘密の質問欄(回答)入力内容変更時処理
   * @param e
   */
  handleAnswerChanged(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({
      answer: e.target.value,
    });
  }

  /**
   * 予約実装部品向けの処理
   * @returns 常にfalse
   */
  handleReservedFunctionClicked() {
    alert("coming soon.");
    return false;
  }

  render() {
    // 業種リスト生成
    var questionRenderList = [];

    questionRenderList.push(<option value={-1}>{}</option>);

    this.state.questionList.forEach((m: QuestionContents) => {
      questionRenderList.push(
        <option value={m.questionId}>{m.question}</option>
      );
    });

    return (
      <div
        style={{
          fontSize: "16px !important",
          height: "100%",
          maxWidth: "1440px !important",
          margin: "0 auto",
          textAlign: "center",
        }}
      >
        {/*パンクズリスト className={}*/}
        <div>
          <BreadcrumbHeader
            breadcrumb={this.state.breadcrumb}
          ></BreadcrumbHeader>
        </div>
        <div className={styles.complete_div_outside}>
          <div className={styles.complete_title}>初回パスワード設定</div>
          <div className={styles.complete_menu_div}>
            <div className={styles.complete_menu_title}>
              <p className={styles.title}>
                三菱電機 ANALYSIS PORTAL&nbsp;&lt;X-chAIn&gt;
                サイトに初めてログインされるお客様は、こちらから新しいパスワードと秘密の質問を
                <br />
                設定してください。
              </p>
              <div className={styles.policy}>
                <p>
                  パスワードは半角8文字以上で、英大文字・英小文字・数字・記号を最低1文字ずつ使用してください。
                </p>
                <p>記号には以下の文字が使用できます。</p>
                <div className={styles.policy_mark}>
                  <p>
                    ^ $ * . [ ] {} ( ) ? " ! @ # % &amp; / \ , &gt; &lt; ' : ; |
                    _ ~ ` = + -
                  </p>
                </div>
              </div>
            </div>
            <table className={styles.complete_menu_table}>
              <tbody>
                <tr>
                  <td className={styles.complete_menu_td_left}>会員ID</td>
                  <td className={styles.complete_menu_td_right}>
                    <label>{this.state.userId}</label>
                  </td>
                </tr>
                <tr>
                  <td className={styles.complete_menu_td_left}>
                    新しいパスワード
                  </td>
                  <td className={styles.complete_menu_td_right}>
                    <div
                      className={
                        this.state.formNewPasswordError
                          ? styles.error
                          : styles.errorNone
                      }
                    >
                      新しいパスワードを入力してください。
                    </div>
                    <div
                      className={
                        this.state.formCompleteInvalidError
                          ? styles.error
                          : styles.errorNone
                      }
                    >
                      パスワードは半角8文字以上で、英大文字・英小文字・数字・記号を最低1文字ずつ使用してください。
                    </div>
                    <div
                      className={
                        this.state.formCompleteLimitExceededError
                          ? styles.error
                          : styles.errorNone
                      }
                    >
                      パスワード設定回数が上限を超えました。しばらく待ってから再度お試しください。
                    </div>
                    <div
                      className={
                        this.state.formCompleteUnknownError
                          ? styles.error
                          : styles.errorNone
                      }
                    >
                      予期しないエラーが発生しました。管理者にお問い合わせください。
                    </div>
                    <input
                      type="password"
                      onChange={this.handleNewPasswordChanged}
                      onKeyPress={(e) => this.keyPress(e)}
                    ></input>
                  </td>
                </tr>
                <tr>
                  <td className={styles.complete_menu_td_left}>
                    新しいパスワードを再入力
                  </td>
                  <td className={styles.complete_menu_td_right}>
                    <div
                      className={
                        this.state.formConfirmPasswordError
                          ? styles.error
                          : styles.errorNone
                      }
                    >
                      新しいパスワードと確認用パスワードが一致していません。
                    </div>
                    <input
                      type="password"
                      onChange={this.handleConfirmPasswordChanged}
                      onKeyPress={(e) => this.keyPress(e)}
                    ></input>
                  </td>
                </tr>
                <tr>
                  <td className={styles.complete_menu_td_left}>秘密の質問</td>
                  <td className={styles.complete_menu_td_right}>
                    <div
                      className={
                        this.state.formCompleteQuestionError
                          ? styles.error
                          : styles.errorNone
                      }
                    >
                      秘密の質問が選択されていません。
                    </div>
                    <select
                      className={styles.complete_menu_select}
                      onChange={(e) => this.handleQuestionChanged(e)}
                      defaultValue={this.state.questionId}
                      onKeyPress={(e) => this.keyPress(e)}
                    >
                      {questionRenderList}
                    </select>
                  </td>
                </tr>
                <tr>
                  <td className={styles.complete_menu_td_left}>
                    秘密の質問(回答)
                  </td>
                  <td className={styles.complete_menu_td_right}>
                    <div
                      className={
                        this.state.formCompleteAnswerError
                          ? styles.error
                          : styles.errorNone
                      }
                    >
                      秘密の質問(回答)が入力されていません。
                    </div>
                    <input
                      type="text"
                      onChange={(e) => this.handleAnswerChanged(e)}
                      onKeyPress={(e) => this.keyPress(e)}
                    ></input>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className={styles.complete_menu_button}>
            <input
              type="button"
              value="初回パスワード設定"
              onClick={this.completePassword}
            ></input>
          </div>
        </div>
      </div>
    );
  }
}
