export class FileReaderEx extends FileReader {
    readAs(blob: Blob, ctx: "readAsText" | "readAsDataURL" | "readAsArrayBuffer", opt?: any) {
        return new Promise((res, rej) => {
            super.addEventListener("load", ({ target }) => res(target?.result));
            super.addEventListener("error", ({ target }) => rej(target?.error));
            super[ctx](blob, opt);
        });
    }

    readAsArrayBuffer(blob: Blob): Promise<ArrayBuffer> {
        return this.readAs(blob, "readAsArrayBuffer") as Promise<ArrayBuffer>;
    }

    readAsDataURL(blob: Blob) {
        return this.readAs(blob, "readAsDataURL");
    }

    readAsText(blob: Blob, encoding?: string): Promise<string> {
        return this.readAs(blob, "readAsText", encoding) as Promise<string>;
    }
}