import React from "react";
import Button from "@mui/material/Button";
// ダイアログ
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

export interface IMessageStates {
  message: any;
  isOpen: any;
}
interface ICommonDialogProps {
  open: boolean;
  title: string;
  message: string;
  doOk: () => void;
}
interface ICommonDialogState {
}
export class CommonDialog extends React.Component<ICommonDialogProps, ICommonDialogState> {

  private func(f?: () => void): void {
    if(f) f();
  }
  private display(f?: () => void): any {
    return { display: f ? "" : "none" };
  }

  render() {
    return (
      <div>
        <Dialog
          open={this.props.open}
          keepMounted
          onClose={() => { }}
          aria-labelledby="common-dialog-title"
          aria-describedby="common-dialog-description"
        >
          <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
            {this.props.title}
          </DialogTitle>
          <DialogContent>
            {this.props.message}
          </DialogContent>
          <DialogActions>
            <Button style={this.display(this.props.doOk)} onClick={() => this.func(this.props.doOk)} color="primary">ＯＫ</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default CommonDialog;