import { render } from "@testing-library/react";
import React, { useCallback } from "react";
import Dropzone from "react-dropzone";
import {
    BreadcrumbHeader,
    Breadcrumb,
    BreadcrumbList,
} from "../common/components/BreadcrumbHeader";

import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
    AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import {
    ClickAwayListener,
    Paper,
    Popper, Container
} from "@mui/material";
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Link from '@mui/material/Link';
import "../common/scss/common.module.scss";
import styles from "./FAQ.module.scss";

// json,csv,png
import LOGO1 from './png/iconCat1.png';
import LOGO2 from './png/iconCat2.png';
import LOGO3 from './png/iconCat3.png';
import faqInf from "./json/faqinf.json";
import defInf from "./json/define.json";
import data1 from "./csv/data1.json";
import data2 from "./csv/data2.json";

import { useNavigate, useLocation } from "react-router-dom";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

interface AnalysisPageState {
    breadcrumb: {};
}

const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

// hookを使用するためクラスを関数でラップ
export default function FAQ(props: any) {
    // useNavigationを取得し、クラスにnavigationを渡す
    const navigate = useNavigate();
    // useLocationを取得し、クラスにlocationを渡す
    const location = useLocation();

    return (
        <FAQClass {...props} navigate={navigate} location={location} />
    );
}

class FAQClass extends React.Component<any, AnalysisPageState>
{
    constructor(props: any) {
        super(props);

        if (props.location.state) {
            this.state = props.location.state;
        } else {
            this.state = {
                breadcrumb: [
                    { name: "ANALYSIS PORTAL <X-chAIn>紹介ページ", href: "/" },
                    { name: "よくある質問", href: "" },
                ],
            };
            console.log(this.state);
        }
    }


    // ページの先頭に戻る
    returnTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    /**
     * APR概要画面の呼び出し
     */
    Contactsite = async () => {
        // お問い合わせフォームへ遷移
        window.scrollTo(0, 0);
        window.location.href = "../Contactsite";
    }

    render() {
        const linkList = [];
        if (this.props.logged) {
            linkList.push(<div id="oldContact"><div id="linkButton" style={{ textAlign: "center" }}><button className={styles.AskBtn} onClick={() => {
                this.Contactsite();
            }}><b>お問い合わせフォームへ</b></button></div><br></br><p className={styles.pastchat}>過去のお問い合わせは<a href={defInf.define1.url} target="_blank" rel="noopener noreferrer">こちら</a></p></div >);
        } else {
            linkList.push(<div id="linkButton" style={{ marginBottom: "100px", textAlign: "center" }}><button className={styles.AskBtn} onClick={() => {
                this.Contactsite();
            }}><b>お問い合わせフォームへ</b></button></div>);
        }

        return (
            <div>
                <div className={styles.l_breadcrumb}>
                    <BreadcrumbHeader
                        breadcrumb={this.state.breadcrumb}
                    ></BreadcrumbHeader>
                </div>
                <p id="anchor-main">ここから本文</p>
                <Container maxWidth="xl">
                    <br></br>
                    <h1 style={{ lineHeight: 2, color: "#d50000" }}>よくあるご質問（ＦＡＱ）</h1>
                    <br></br>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            {
                                (function () {
                                    const list = [];
                                    for (let i = 1; i <= faqInf.faqcnt; i++) {
                                        let category = " " + faqInf["1"].category;
                                        let csvfile = faqInf["1"].csvfile;
                                        let icon = faqInf["1"].icon;
                                        if (i == 1) {
                                            category = " " + faqInf["1"].category;
                                            csvfile = faqInf["1"].csvfile;
                                            icon = faqInf["1"].icon;
                                        }
                                        else if (i == 2) {
                                            category = " " + faqInf["2"].category;
                                            csvfile = faqInf["2"].csvfile;
                                            icon = faqInf["2"].icon;
                                        }
                                        let LOGO;
                                        if (icon == "iconCat1.png") { LOGO = LOGO1 }
                                        if (icon == "iconCat2.png") { LOGO = LOGO2 }
                                        if (icon == "iconCat3.png") { LOGO = LOGO3 }
                                        let youso = "FAQ/#youso" + i.toString();
                                        list.push(
                                            <Link href={youso}>
                                                <ListItem>
                                                    <ListItemButton>
                                                        <ListItemAvatar>
                                                            <Avatar src={LOGO} />
                                                        </ListItemAvatar>
                                                        <ListItemText primary={category} />
                                                    </ListItemButton>
                                                </ListItem>
                                            </Link>
                                        );
                                    }
                                    return <nav className={styles.FaqNav}><ul><List>{list}</List></ul> </nav>;
                                }())
                            }
                        </Grid>
                        <Grid item xs={8}>

                            <Container maxWidth="md">
                                <div>
                                    {
                                        (function () {
                                            const list = [];
                                            for (let i = 1; i <= faqInf.faqcnt; i++) {
                                                let csvData;
                                                let category = faqInf["1"].category;
                                                let csvfile = faqInf["1"].csvfile;
                                                let icon = faqInf["1"].icon;
                                                if (i == 1) {
                                                    category = faqInf["1"].category;
                                                    csvfile = faqInf["1"].csvfile;
                                                    icon = faqInf["1"].icon;
                                                    csvData = data1;
                                                }
                                                else if (i == 2) {
                                                    category = faqInf["2"].category;
                                                    csvfile = faqInf["2"].csvfile;
                                                    icon = faqInf["2"].icon;
                                                    csvData = data2;
                                                }
                                                let youso = "youso" + i.toString();
                                                list.push(<br></br>);
                                                list.push(<h2 style={{ lineHeight: 3 }} id={youso}>{category}</h2>);
                                                {
                                                    (function () {
                                                        const listTemp = [];
                                                        if (csvData === undefined) {
                                                            return 0;
                                                        }
                                                        for (let j = 0; j < csvData.dataCnt; j++) {
                                                            let s = "Ｑ" + (j + 1).toString();
                                                            listTemp.push(
                                                                <Accordion >
                                                                    <div className={styles.uu}>
                                                                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" >
                                                                            <Typography>
                                                                                <a style={{ color: 'brown' }}>{s}</a>
                                                                                <a>{"　" + csvData.object_list[j].Q}</a>
                                                                            </Typography>
                                                                        </AccordionSummary>
                                                                    </div>
                                                                    <AccordionDetails>
                                                                        <Typography>
                                                                            {csvData.object_list[j].A.split('\n').map(t => (<div className={styles.subfaq}>{t}</div>))}
                                                                        </Typography>
                                                                    </AccordionDetails>
                                                                </Accordion>
                                                            );
                                                        }
                                                        list.push(<div className={styles.faq}>{listTemp}</div>);
                                                        return { list };
                                                    }())
                                                }
                                            }
                                            return <ul>{list}</ul>;
                                        }())
                                    }
                                </div>
                            </Container>
                        </Grid>
                    </Grid>
                </Container >
                <div style={{ display: 'block', padding: 13, margin: 0 }}>
                </div>
                <p className={styles.notfaq}>よくあるご質問（ＦＡＱ）では解決できない場合</p>
                {linkList}
                <div className={styles.entry_menu_pageTop}>
                    <div>
                        <input
                            type="button"
                            className={styles.pageTopButton}
                            value="▲ページトップへ戻る"
                            onClick={this.returnTop}
                        />
                    </div>
                </div>
            </div >
        );
    }
}
//export default FAQ;
