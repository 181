import PersonIcon from "@mui/icons-material/Person";
import React from "react";
import "react-pro-sidebar/dist/css/styles.css";
import { AwsAmplifyUtils } from "../../common/components/AwsAmplifyUtils";
import styles from "./UserInfo.module.scss";

const SERVICE_APR = 1;
const SERVICE_EMS = 2;
const SERVICE_DIGITAL = 3;

/**
 * State
 */
interface Profile {
  icon: string;
  nickname: string;
  company: string;
  name: string;
  address: string;
  tel: string;
  date: string;
}

interface Plan {
  date: string;
  plan: string;
  count: number;
  availableDays: number;
  dateStr :string;
}
interface SlidebarProps {
  close: boolean;
}

interface SlidebarState {
  userId: string;
  profile: Profile | null;
  plan: Plan | null;
}

/**
 * ユーザー情報
 */
export class UserInfo extends React.Component<SlidebarProps, SlidebarState> {
  /**
   * コンストラクタ
   * @param props Props
   */
  constructor(props: SlidebarProps) {
    super(props);

    this.state = {
      userId: "",
      profile: null,
      plan: null,
    };
  }

  /**
   * ユーザ情報を取得する
   * @param userId ユーザID
   */
  getUserInfo  (userId: any) {
    const headers = { "Content-Type": "application/json" };
    let data = {
      userId: userId,
    };

    AwsAmplifyUtils.callAPI()
      .then((api) => {
        return api.API.post(
          "api",
          api.apiPrefix + "/userinfomanager/getuserinfo",
          {
            headers: headers,
            body: data,
          }
        );
      })
      .then((res) => {
        console.log(res);
        if (res.errorCode == 0) {
          var data = res.data;
          console.log(data);

          this.setState({
            userId: userId,
            profile: {
              icon: data.photoData,
              nickname: data.nickName,
              company: data.companyName,
              name: data.name,
              address: data.companyAddress,
              tel: data.companyTel,
              date: data.registDate,
            },
          });
          console.log(this.state);
        } else {
          if (res.errorCode == 1) {
            alert(res.errorMessage);
          } else {
            alert(res.errorMessage);
          }
        }
      })
      .catch((e) => {
        console.log(e);
        alert(e);
      })
      .finally(() => {
        //何もしない
      });
  };

  /**
   * 初期データロード
   */
  componentDidMount() {
    // configure
    AwsAmplifyUtils.configure();
    // ユーザ名取得
    AwsAmplifyUtils.checkAuthenticated().then((value) => {
      this.setState({ userId: value.username });
      console.log(this.state.userId);
      // ユーザ情報取得
      this.getUserInfo(this.state.userId);
      // 契約ID（=cognitoグループ名称)
      AwsAmplifyUtils.getCognitoGroup().then((value) => {
        console.log(value);
        // 契約情報を取得
        this.getContractInfo(value);
      });
    });
  }

  /**
   * 契約情報を取得
   * @param contractId 契約ID（=cognitoグループ名称)
   */
  getContractInfo(contractId: any) {
    const headers = { "Content-Type": "application/json" };
    let data = {
      contractId: contractId,
    };

    AwsAmplifyUtils.callAPI()
      .then((api) => {
        return api.API.post(
          "api",
          api.apiPrefix + "/contractmanager/getcontractinfo",
          {
            headers: headers,
            body: data,
          }
        );
      })
      .then(async (res) => {
        console.log(res);
        if (res.errorCode == 0) {
          var data = null;
          for (var i in res.data) {
            if (res.data[i].serviceId == SERVICE_APR) {
              data = res.data[i];
            } else if (res.data[i].serviceId == SERVICE_EMS) {
            } else if (res.data[i].serviceId == SERVICE_DIGITAL) {
            }
          }

          let date = new Date(
            Date.parse(this.state.profile?.date || "") +
              (data.availableDays || 0) * 60 * 60 * 24 * 1000
          );

          date = new Date(
            Date.parse(this.state.profile?.date || "") 
          );

          let dateStr =
            date.getFullYear() + "/" + date.getMonth() + "/" + date.getDay();

            dateStr = this.state.profile?.date || "";

          // state更新
          this.setState({
            plan: {
              date: data.contractDate,
              plan: data.planName,
              count: data.useCount - data.usedCount,
              availableDays: data.availableDays,
              dateStr: dateStr,
            },
          });
        } else {
          if (res.errorCode == 1) {
            alert(res.errorMessage);
          } else {
            alert(res.errorMessage);
          }
        }
      })
      .catch((e) => {
        console.log(e);
        alert(e);
      })
      .finally(() => {
        //何もしない
      });
  }

  /**
   * ユーザの契約プランを表示する
   * @returns
   */
  createUserText() {
    /***ここでユーザ情報を取得してテキストを作成する */
    let now = new Date();
    // 契約期間を作成する
    let date =
      now.getFullYear() +
      "/" +
      (Number(now.getMonth()) + 2) +
      "/" +
      now.getDate();
    let resData = (
      <div className={styles.UserText_Area_div}>
        <ul className={styles.UserText_Area_ul}>
          <li>契約プラン:{this.state.plan?.plan}</li>
          {/*<li>ランク:プロフェッショナル</li>*/}
          <li>分析回数(残り):{this.state.plan?.count} 回</li>
          <li>
            契約期間:
            {this.state.plan?.dateStr} まで
          </li>
        </ul>
      </div>
    );
    return resData;
  }
  /**
   * ユーザアイコンを表示する
   * @returns
   */
  createUserIcon(flag: boolean) {
    let size = 68;
    let closeIcon;
    let UserIconDivClose;
    // 縮小表示のとき
    if (flag) {
      size = 25;
      closeIcon = styles.UserIconClose;
      UserIconDivClose = styles.UserIconDivClose;
    }
    // アイコン設定有無をチェック
    let existIcon = false;
    if (this.state.profile?.icon != null && this.state.profile?.icon !== "") {
      // 何らかの画像設定有りとみなす
      existIcon = true;
    }
    /**ユーザ情報とアイコン情報を取得する */
    let resData = (
      <div className={styles.UserIconDiv + " " + UserIconDivClose}>
        <div>
          {/*<CreateIcon className={styles.createIcon} sx={{ color: "#808080" }} />*/}
        </div>
        <div className={styles.UserIcon + " " + closeIcon}>
          <img
            className={
              existIcon === true ? styles.tests : styles.test
            }
            src={
              this.state.profile?.icon
              }
            alt="ユーザアイコン"
          />
          <div
            className={
              existIcon === true ? styles.test : styles.tests
            }
          >
            {/*<PersonIcon sx={{ fontSize: 68 }}></PersonIcon>*/}
            <PersonIcon sx={{ fontSize: size }}></PersonIcon>
          </div>
        </div>
        <div className={styles.UserName}>{this.state.profile?.nickname} さん</div>
      </div>
    );
    return resData;
  }
  render(): React.ReactNode {
    // サイドメニューを閉じるとき
    let none;
    let closeArea;
    if (this.props.close) {
      none = styles.none;
      closeArea = styles.closeArea;
    }
    let userText = this.createUserText();
    let userIcon = this.createUserIcon(this.props.close);
    return (
      <div className={styles.UserInfo + " " + closeArea}>
        <div className={styles.UserInfo_Area}>
          <div className={styles.UserIcon_Area + " " + none}>{userIcon}</div>
          <div className={styles.UserText_Area + " " + none}>{userText}</div>
        </div>
      </div>
    );
  }
}

export default UserInfo;
