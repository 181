import React from "react";
import mainImag2 from "./img/main/パイプ.jpg";
import mainImag3 from "./img/main/協創イメージ.jpg";
import mainImag1 from "./img/main/電球と手.jpg";
import styles from "./MainImg.module.scss";
import InputEvent from "../common/utility/InputEvent";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
export interface Breadcrumb {
  name: string;
  href?: string;
}
export interface BreadcrumbList {
  breadcrumb: Breadcrumb[];
}
const SCROll = 1100;

/**
 * ポータルサイト用ヘッダ(≠三菱電気ヘッダ)
 */
export class MainImg extends React.Component<any> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  // トップ画面スクロールボタンクリック
  moveDown = (e: any) => {
    if (InputEvent.checkClickEnter(e)) {
      window.scrollTo({
        top: SCROll,
        behavior: "smooth",
      });
    }
  };

  render() {
    return (
      <div className={styles.hero_img}>
        <img
          className={styles.main_img + " " + styles.main_img_1}
          src={mainImag1}
          alt=""
        ></img>
        <img
          className={styles.main_img + " " + styles.main_img_2}
          src={mainImag2}
          alt=""
        ></img>
        <img
          className={styles.main_img + " " + styles.main_img_3}
          src={mainImag3}
          alt=""
        ></img>
        <img
          className={styles.main_img + " " + styles.main_img_1}
          src={mainImag1}
          alt=""
        ></img>
        <img
          className={styles.main_img + " " + styles.main_img_2}
          src={mainImag2}
          alt=""
        ></img>
        <img
          className={styles.main_img + " " + styles.main_img_3}
          src={mainImag3}
          alt=""
        ></img>
        <div className={styles.overflowText}>
          <p className={styles.subTitle}>
            AIと歩む　新たな分析ソリューションをあなたへ
          </p>
          <b lang="en">ANALYSIS PORTAL&nbsp;&lt;X-chAIn&gt;.</b> by MITSUBISHI Electric
        </div>
        <div className={styles.arrowDoenArea} ><KeyboardArrowDownIcon
          onClick={(e) => this.moveDown(e)}
          onKeyDown={(e) => this.moveDown(e)}
          className={styles.icon} sx={{ fontSize: "2.5vw" }} /></div>
      </div>
    );
  }
}
