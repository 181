/// テーブル用の
/*import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";*/
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { ClickAwayListener, Paper, Popper } from "@mui/material";
import Button from "@mui/material/Button";
// ダイアログ
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import "gridjs/dist/theme/mermaid.css";
import React from "react";
import Modal from "react-modal";
import { AwsAmplifyUtils } from "../../../../common/components/AwsAmplifyUtils";
import InputEvent from "../../../../common/utility/InputEvent";
//import DataA from "../../../data/aprReportAData.json";
//import DataB from "../../../data/aprReportBData.json";
import ContentHeder from "../../ContentHeder";
import CommonDialog from "../../Dialog";
//import "./ModelPage.css";
import styles from "./ReportPage.module.scss";

type Props = {
  transReason: string;
};
interface SlidebarState {
  close: boolean;
}

/**
 * State
 */
interface ReportPageState {
  loading: boolean;
  DataA: Array<ReportList>;
  DataB: Array<ReportList>;
  DataAComplete: boolean;
  DataBComplete: boolean;
  cntA: number;
  cntB: number;
  reportA: boolean;
  sortA: boolean;
  reportB: boolean;
  sortB: boolean;
  search: Array<string>;
  inputTitle?: string;
  openDelDialog: boolean;
  delMessage?: string;
  showMessage: boolean;
  title?: string;
  message?: string;
  delData: ReportList;
}

const TYPE = ["DataA", "DataB"];
class ReportList {
  constructor(
    public reportId: number,
    public createdDate: number,
    public fileName: string,
    public img: string,
    public openMenu: boolean,
    public openInput: boolean
  ) {}
}

type ReportBase = {
  reportId: number;
  createdDate: number;
  fileName: string;
};

/**
 * レポートページ
 */
export class ReportPage extends React.Component<any, ReportPageState> {
  /**
   * コンストラクタ
   * @param props Props
   */
  constructor(props: any) {
    super(props);

    // stateを初期化
    this.state = {
      loading: false,
      DataA: undefined as any,
      DataB: undefined as any,
      DataAComplete: false,
      DataBComplete: false,
      cntA: 0,
      cntB: 0,
      reportA: true,
      sortA: true,
      reportB: true,
      sortB: true,
      search: [],
      openDelDialog: false,
      showMessage: false,
      delData: undefined as any,
    };

    this.getReport("apr-simple");
    this.getReport("apr-standard");
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    // 波形比較、異常分析レポートが共に完了の場合、Modalをclose
    if (
      this.state.DataAComplete != prevState.DataAComplete ||
      this.state.DataBComplete != prevState.DataBComplete
    ) {
      if (this.state.DataAComplete && this.state.DataBComplete) {
        this.props.setLoading(false);

        if (this.props.transReason === "startingAnalysis") {
          // 遷移要因を初期化しておく
          this.props.setTransReason("");
          // 遷移要因が分析開始なら分析開始を表示
          this.showMessage(
            "分析開始",
            "分析を開始しました。"
          );
        }
      }
    }
  }

  /***
   * 検索
   * @param search 検索テキスト
   */
  searchChanged = (search: Array<string>) => {
    this.setState({ search: search });
  };

  /***
   * レポート一覧取得
   * @param type タイプ(波形比較/異常分析)
   */
  async getReport(type: string) {
    switch (type) {
      case "apr-simple": {
        this.setState({ DataAComplete: false });
        break;
      }
      case "apr-standard": {
        this.setState({ DataBComplete: false });
        break;
      }
    }

    this.props.setLoading(true);

    const headers = { "Content-Type": "application/json" };

    try {
      // レポート一覧の取得
      AwsAmplifyUtils.callAPI()
        .then((api) => {
          return api.API.post(
            "api",
            api.apiPrefix + "/reportmanager/report/list",
            {
              body: { type: type },
              headers: headers,
            }
          );
        })
        .then(async (res) => {
          if (res.errorCode === "OK") {
            const d = res;
            const dmap = new Array<ReportList>();
            const upstate = () => {
              switch (type) {
                case "apr-simple": {
                  this.setState({
                    DataA: dmap as any,
                    cntA: d.reports.length,
                  });
                  break;
                }
                case "apr-standard": {
                  this.setState({
                    DataB: dmap as any,
                    cntB: d.reports.length,
                  });
                  break;
                }
              }
            };

            // レポートが0件のときの、 Modal 解除処理
            if (d.reports.length == 0) {
              switch (type) {
                case "apr-simple": {
                  this.setState({ DataAComplete: true });
                  break;
                }
                case "apr-standard": {
                  this.setState({ DataBComplete: true });
                  break;
                }
              }
            }
            // サムネイルを取得
            (d.reports as Array<ReportBase>).forEach(async (r) => {
              AwsAmplifyUtils.callAPI()
                .then((api) => {
                  return api.API.post(
                    "api",
                    api.apiPrefix + "/reportmanager/report/thumbnail/get",
                    {
                      responseType: "blob",
                      body: { reportId: r.reportId },
                      headers: headers,
                    }
                  );
                })
                .then(async (img) => {
                  if (true) {
                    const url = URL.createObjectURL(img);
                    const cr = new ReportList(
                      r.reportId,
                      r.createdDate,
                      r.fileName,
                      url,
                      false,
                      false
                    );
                    dmap.push(cr);
                  } else {
                    const n = await res.json();
                    console.log(n);
                    const cr = new ReportList(
                      r.reportId,
                      r.createdDate,
                      r.fileName,
                      "",
                      false,
                      false
                    );
                    dmap.push(cr);
                  }
                  upstate();
                })
                .catch((e) => {
                  console.log(e);
                  this.props.setLoading(false);
                })
                .finally(() => {
                  // Modal 解除処理
                  switch (type) {
                    case "apr-simple": {
                      if (this.state.DataA.length == this.state.cntA) {
                        this.setState({ DataAComplete: true });
                      }
                      break;
                    }
                    case "apr-standard": {
                      if (this.state.DataB.length == this.state.cntB) {
                        this.setState({ DataBComplete: true });
                      }
                      break;
                    }
                  }
                });
            });
          } else {
            const n = await res.json();
            console.log(n);
          }
        })
        .catch((e) => {
          console.log(e);
          alert(e);
          // Modal 解除処理
          switch (type) {
            case "apr-simple": {
              this.setState({ DataAComplete: true });
              break;
            }
            case "apr-standard": {
              this.setState({ DataBComplete: true });
              break;
            }
          }          
        });
    } catch (e) {
      console.log(e);
      this.props.setLoading(false);
    } finally {
      //
    }
  }
  /***
   * レポートのダウンロード
   * サムネイル画像をクリック時
   * @param id レポートID
   */
  async openReport(id: number) {
    const title = "レポートダウンロードエラー";
    const headers = { "Content-Type": "application/json" };

    AwsAmplifyUtils.callAPI()
      .then((api) => {
        return api.API.post(
          "api",
          api.apiPrefix + "/reportmanager/report/download",
          {
            responseType: "blob",
            body: { reportId: id },
            headers: headers,
          }
        );
      })
      .then(async (report) => {
        if (true) {
          const link = document.createElement("a");
          document.body.appendChild(link);
          link.download = "reportData.xlsx";
          link.href = URL.createObjectURL(report);
          link.click();
          link.remove();
          URL.revokeObjectURL(link.href);
        } else {
          const n = await report.json();
          console.log(n);
          this.showMessage(
            title,
            "ArgumentError: 指定したレポートが存在しません。"
          );
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }
  /***
   * 入力文字数チェック
   * タイトル
   * @param title タイトル
   * @returns true:OK / false: NG
   */
  checkInputText(title: string) {
    let len = 0;
    for (let i = 0; i < title.length; i++) {
      if (title[i].match(/[ -~]/)) {
        len += 0.5;
      } else {
        len += 1;
      }
    }
    if (len > 16) {
      return false;
    }
    return true;
  }
  /***
   * レポートタイトルの変更
   * レポートタイトルをエンター時
   * @param inputTitle 入力されたタイトル
   * @param type タイプ(波形比較/異常分析)
   * @param reportData レポートデータ
   */
  changeText = (
    event: React.KeyboardEvent<HTMLInputElement>,
    inputTitle: string,
    type: string,
    reportData: any
  ) => {
    if (event.code === "Enter") {
      if (inputTitle == null || inputTitle == "") {
        // 未入力
        alert("タイトルが未入力です。");
      } else {
        const result = this.checkInputText(inputTitle);
        if (!result) {
          //文字数オーバー
          alert("タイトルの文字数オーバーです。（全角16文字以内）");
        } else {
          this.setState({ loading: true });
          const title = "レポートファイル名変更エラー";
          const headers = { "Content-Type": "application/json" };
          let data = { reportId: reportData.reportId, fileName: inputTitle };
          AwsAmplifyUtils.callAPI()
            .then((api) => {
              return api.API.post(
                "api",
                api.apiPrefix + "/reportmanager/report/rename",
                {
                  headers: headers,
                  body: data,
                }
              );
            })
            .then(async (res) => {
              if (res.errorCode === "OK") {
                console.log("タイトルを変更しました");
              } else {
                const n = res;
                console.log(n.erroCode + ":" + n.errorMessage);
                if (n.erroCode == "ArgumentError") {
                  this.showMessage(title, "タイトルの変更に失敗しました。");
                  //this.showMessage(title, "指定したレポートが存在しません。");
                } else {
                  this.showMessage(title, "タイトルの変更に失敗しました。");
                }
              }
            })
            .catch((e) => {
              console.log(e);
              this.showMessage(title, "タイトルの変更に失敗しました。");
              //this.showMessage(title, "予期しないエラーのため取得できませんでした。");
            })
            .finally(() => {
              this.getReport("apr-simple");
              this.getReport("apr-standard");
              this.setState({ loading: false });
            });
        }
      }
    }
  };
  /***
   * レポート削除
   * 確認ダイアログ表示
   * メニューボタンの削除をクリック時
   * @param type タイプ(波形比較/異常分析)
   * @param reportData レポートデータ
   */
  deleteConfirmation(type: string, reportData: any) {
    const delmessage = "レポートを削除しますか？";
    this.setState({
      openDelDialog: true,
      delMessage: delmessage,
      delData: reportData,
    });
  }
  /***
   * レポートの削除
   * メニューボタンの削除をクリック時
   */
  deleteReport() {
    this.setState({ loading: true });
    const title = "レポート削除エラー";
    const headers = { "Content-Type": "application/json" };
    const data = { reportId: this.state.delData.reportId };
    try {
      AwsAmplifyUtils.callAPI()
        .then((api) => {
          return api.API.post(
            "api",
            api.apiPrefix + "/reportmanager/report/delete",
            {
              headers: headers,
              body: data,
            }
          );
        })
        .then(async (res) => {
          if (res.errorCode === "OK") {
            console.log("レポートを削除しました");
            this.getReport("apr-simple");
            this.getReport("apr-standard");
          } else {
            const n = res;
            console.log(n.erroCode + ":" + n.errorMessage);
            if (res.erroCode == "ArgumentError") {
              this.showMessage(title, "レポートの削除に失敗しました。");
              //this.showMessage(title, "指定したレポートが存在しません。");
            } else {
              this.showMessage(title, "レポートの削除に失敗しました。");
            }
          }
        });
    } catch (e) {
      console.log(e);
      this.showMessage(title, "レポートの削除に失敗しました。");
      //this.showMessage(title, "予期しないエラーのため取得できませんでした。");
    } finally {
      this.setState({ loading: false });
    }
  }

  /***
   * レポートの検索
   */
  searchExecution(data: Array<ReportList>) {
    let res = Array<ReportList>();
    let text = this.state.search;
    for (let i = 0; i < data.length; i++) {
      for (let j = 0; j < text.length; j++) {
        if (data[i].fileName.includes(text[j])) {
          if (j == text.length - 1) {
            res.push(data[i]);
          }
        } else {
          break;
        }
      }
    }
    return res;
  }

  /***
   * レポートを表示形式に編集
   * @param type タイプ(波形比較/異常分析)
   * @returns resData レポート一覧
   */
  createReportDiv(type: string) {
    let data = new Array<ReportList>();
    let searchdata = new Array<ReportList>();
    let sort: boolean;
    switch (type) {
      case "DataA": {
        data = this.state.DataA;
        sort = this.state.sortA;
        break;
      }
      case "DataB": {
        data = this.state.DataB;
        sort = this.state.sortB;
        break;
      }
    }
    if (this.state.search.length == 0 || data == null) {
      searchdata = data;
    } else {
      searchdata = this.searchExecution(data);
    }
    let styleInputArea = { display: "", disabled: false };
    let styleTitleArea = { display: "" };
    const sorter = (a: number, b: number) => (sort ? b - a : a - b);
    let resData = new Array();
    searchdata
      ?.sort((a, b) => sorter(a.createdDate, b.createdDate))
      .forEach((d) => {
        resData.push(
          <div className={styles.gridObj} key={`${type}-${d.reportId}`}>
            <div className={styles.grid_title}>
              <input
                className={styles.grid_title_text}
                id={`inputTitle-${type}-${d.reportId}`}
                onKeyUp={(e) => {
                  let inputField: HTMLInputElement = document.getElementById(
                    `inputTitle-${type}-${d.reportId}`
                  ) as HTMLInputElement;
                  if (inputField != undefined && inputField != null) {
                    this.changeText(e, inputField.value, type, d);
                  }
                }}
                onBlur={() => this.closeInputArea(type, d)}
                style={d.openInput ? styleInputArea : { display: "none" }}
                type={"text"}
              ></input>
              <div
                className={styles.grid_title_div}
                id={`title-${type}-${d.reportId}`}
                onClick={() => {
                  // サブメニューからリネームするよう変更
                  // this.openInputArea(type, d);
                }}
                style={!d.openInput ? styleTitleArea : { display: "none" }}
              >
                {d.fileName}
              </div>
              <div className={styles.caption}>
                <div className={styles.settingRange}>
                  <button
                    id={`openMenu-${type}-${d.reportId}`}
                    className={styles.setting}
                    aria-controls={d.openMenu ? "menu-list-grow" : undefined}
                    aria-label="reportMenu"
                    aria-haspopup="true"
                    onClick={() => this.openMenu(type, d)}
                    onKeyDown={(e) => {
                      this.keyDown(e, () => this.openMenu(type, d));
                    }}
                  >
                    <MoreVertIcon />
                  </button>
                  <Popper
                    open={d.openMenu}
                    anchorEl={document.getElementById(
                      `openMenu-${type}-${d.reportId}`
                    )}
                    placement={"bottom-end"}
                  >
                    <Paper variant="outlined">
                      <ClickAwayListener
                        onClickAway={() => this.closeMenu(type, d)}
                      >
                        <MenuList id="menu-list-grow" autoFocusItem={true}>
                          <MenuItem
                            tabIndex={0}
                            onClick={() => {
                              this.closeMenu(type, d);
                              this.openInputArea(type, d);
                            }}
                            onKeyDown={(e) => {
                              if (e.key === "Tab" || e.key === "Escape") {
                                // TAB押下
                                e.preventDefault();
                                this.closeMenu(type, d);
                                document
                                  .getElementById(
                                    `openMenu-${type}-${d.reportId}`
                                  )
                                  ?.focus();
                              } else {
                                this.keyDown(e, () => {
                                  this.closeMenu(type, d);
                                  this.openInputArea(type, d);
                                });
                              }
                            }}
                          >
                            レポート名称変更
                          </MenuItem>
                          <MenuItem
                            tabIndex={0}
                            onClick={() => {
                              this.closeMenu(type, d);
                              this.deleteConfirmation(type, d);
                            }}
                            onKeyDown={(e) => {
                              if (e.key === "Tab" || e.key === "Escape") {
                                // TAB押下
                                e.preventDefault();
                                this.closeMenu(type, d);
                                document
                                  .getElementById(
                                    `openMenu-${type}-${d.reportId}`
                                  )
                                  ?.focus();
                              } else {
                                this.keyDown(e, () => {
                                  this.closeMenu(type, d);
                                  this.deleteConfirmation(type, d);
                                });
                              }
                            }}
                          >
                            レポート削除
                          </MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Popper>
                </div>
              </div>
            </div>
            <div className={styles.grid_coment}>
              <div className={styles.grid_coment_div}>
                作成日時:{this.convDate(d.createdDate)}
              </div>
            </div>
            <div className={styles.grid_img}>
              <img
                alt={d.fileName}
                className={styles.reportImg}
                src={d.img}
                onClick={() => this.openReport(d.reportId)}
                onKeyDown={(e) => {
                  this.keyDown(e, () => {
                    this.openReport(d.reportId);
                  });
                }}
                tabIndex={0}
              ></img>
            </div>
          </div>
        );
      });
    return resData;
  }
  /***
   * 日付をlong->YYYY/MM/DD hh:mm
   * ゼロ詰めなし
   * @param datetime 日時(long)
   * @returns format(yyyy/M/d h:mm)
   */
  convDate(datetime: number) {
    let date = new Date(datetime);
    let format = "Y/M/D h:m";
    format = format.replace(/Y/g, date.getFullYear() as any);
    format = format.replace(/M/g, (date.getMonth() + 1) as any);
    format = format.replace(/D/g, date.getDate() as any);
    format = format.replace(/h/g, date.getHours() as any);
    format = format.replace(/m/g, (("0" + date.getMinutes()) as any).slice(-2));
    return format;
  }

  /**
   * 波形比較レポート表示/非表示
   */
  showReportA = () => {
    this.setState({ reportA: !this.state.reportA });
  };
  /**
   * 波形比較レポートソート
   */
  sortReportA = () => {
    this.setState({ sortA: !this.state.sortA });
  };
  /**
   * 異常分析レポート表示/非表示
   */
  showReportB = () => {
    this.setState({ reportB: !this.state.reportB });
  };
  /**
   * 異常分析レポートソート
   */
  sortReportB = () => {
    this.setState({ sortB: !this.state.sortB });
  };
  /***
   * レポートタイトル入力エリア表示
   * レポートタイトルをクリック時
   * @param type タイプ(波形比較/異常分析)
   * @param createData レポートデータ
   */
  openInputArea = (type: string, createData: any) => {
    setTimeout(() => {
      let data = this.state.DataA;
      if (type == "DataB") {
        data = this.state.DataB;
      }
      data.forEach((d) => {
        if (d.reportId == createData.reportId) {
          d.openInput = true;
        }
      });
      if (type == "DataA") {
        this.setState({ DataA: data });
      } else {
        this.setState({ DataB: data });
      }
      let inputField: HTMLInputElement = document.getElementById(
        `inputTitle-${type}-${createData.reportId}`
      ) as HTMLInputElement;
      inputField.value = createData.fileName;
      inputField.focus();
    }, 0);
  };
  /***
   * レポートタイトル入力エリア非表示
   * 入力エリア以外をクリック時
   * @param type タイプ(波形比較/異常分析)
   * @param createData レポートデータ
   */
  closeInputArea = (type: string, createData: any) => {
    let data = this.state.DataA;
    if (type == "DataB") {
      data = this.state.DataB;
    }
    data.forEach((d) => {
      if (d.reportId == createData.reportId) {
        d.openInput = false;
      }
    });
    if (type == "DataA") {
      this.setState({ DataA: data });
    } else {
      this.setState({ DataB: data });
    }
  };
  /***
   * メニュー表示
   * アイコンクリック時
   * @param type タイプ(波形比較/異常分析)
   * @param createData レポートデータ
   */
  openMenu = (type: string, createData: any) => {
    let data = this.state.DataA;
    if (type == "DataB") {
      data = this.state.DataB;
    }
    data.forEach((d) => {
      if (d.reportId == createData.reportId) {
        d.openMenu = true;
      }
    });
    if (type == "DataA") {
      this.setState({ DataA: data });
    } else {
      this.setState({ DataB: data });
    }
  };
  /***
   * メニュー非表示
   * @param type タイプ(波形比較/異常分析)
   * @param createData レポートデータ
   */
  closeMenu = (type: string, createData: any) => {
    let data = this.state.DataA;
    if (type == "DataB") {
      data = this.state.DataB;
    }
    data.forEach((d) => {
      if (d.reportId == createData.reportId) {
        d.openMenu = false;
      }
    });
    if (type == "DataA") {
      this.setState({ DataA: data });
    } else {
      this.setState({ DataB: data });
    }
  };
  /**
   * レポート件数を編集
   * @param cnt レポート件数
   * @param type タイプ(波形比較/異常分析)
   * @returns cntText 件数テキスト
   */
  reportCnt(cnt: number, type: string) {
    let cntText = null;
    switch (type) {
      case "DataA": {
        cntText = "波形比較レポート(" + cnt + "件)";
        break;
      }
      case "DataB": {
        cntText = "異常兆候分析レポート(" + cnt + "件)";
        break;
      }
    }
    return cntText;
  }
  /***
   * メッセージダイアログの設定
   * @param title ダイアログタイトル
   * @param message メッセージ
   */
  showMessage(title: string, message: string) {
    this.setState({ showMessage: true, title: title, message: message });
  }

  /**
   * キー押下イベント
   *
   * @param e キーイベント
   * @param f 実行ファンクション
   */
  keyDown(e: any, f: any) {
    if (InputEvent.checkClickEnter(e)) {
      // Enter押下時処理
      f();
    }
  }

  render(): React.ReactNode {
    let styleA = { display: "" };
    let styleAIcon = {};
    let styleASortIcon = {};
    // レポートが非表示のとき
    if (!this.state.reportA) {
      styleA = { display: "none" };
      styleAIcon = {};
      styleAIcon = { transform: "rotate(270deg)" };
    }
    // レポートソートが昇順のとき
    if (!this.state.sortA) {
      styleASortIcon = {};
      styleASortIcon = { transform: "rotate(180deg)" };
    }
    let styleB = { display: "" };
    let styleBIcon = {};
    let styleBSortIcon = {};
    // レポートが非表示のとき
    if (!this.state.reportB) {
      styleB = { display: "none" };
      styleBIcon = {};
      styleBIcon = { transform: "rotate(270deg)" };
    }
    // レポートソートが昇順のとき
    if (!this.state.sortB) {
      styleBSortIcon = {};
      styleBSortIcon = { transform: "rotate(180deg)" };
    }
    let reportA = this.createReportDiv("DataA");
    let reportB = this.createReportDiv("DataB");
    return (
      <div>
        <div className={styles.ReportDiv}>
          <div className={styles.ReportPage}>
            <ContentHeder
              close={this.props.close}
              searchChanged={this.searchChanged}
            ></ContentHeder>

            <div
              className={
                this.state.cntA
                  ? styles.reportListArea
                  : styles.reportListArea + " " + styles.display_none
              }
            >
              {/**分析レポート */}
              <div className={styles.reportA_Area}>
                <KeyboardArrowDownIcon
                  className={styles.icon}
                  onClick={this.showReportA}
                  onKeyDown={(e) => {
                    this.keyDown(e, () => {
                      this.showReportA();
                    });
                  }}
                  style={styleAIcon}
                  tabIndex={0}
                ></KeyboardArrowDownIcon>
                <div className={styles.reportListTitle}>
                  {this.reportCnt(reportA.length, TYPE[0])}
                </div>
                <div className={styles.reportSort}>作成日時</div>
                <ArrowDownwardIcon
                  className={styles.icon}
                  onClick={this.sortReportA}
                  onKeyDown={(e) => {
                    this.keyDown(e, () => {
                      this.sortReportA();
                    });
                  }}
                  style={styleASortIcon}
                  tabIndex={0}
                ></ArrowDownwardIcon>
              </div>
            </div>
            <div
              className={
                this.state.cntA
                  ? styles.reportAList
                  : styles.reportAList + " " + styles.display_none
              }
              style={styleA}
            >
              <div className={styles.reportGrid}>{reportA}</div>
            </div>
            <div
              className={
                this.state.cntB
                  ? styles.reportListArea + " " + styles.reportListArea2
                  : styles.reportListArea +
                    " " +
                    styles.reportListArea2 +
                    " " +
                    styles.display_none
              }
            >
              {/**異常兆候レポート */}
              <div className={styles.reportA_Area}>
                <KeyboardArrowDownIcon
                  className={styles.icon}
                  onClick={this.showReportB}
                  onKeyDown={(e) => {
                    this.keyDown(e, () => {
                      this.showReportB();
                    });
                  }}
                  style={styleBIcon}
                  tabIndex={0}
                ></KeyboardArrowDownIcon>
                <div className={styles.reportListTitle}>
                  {this.reportCnt(reportB.length, TYPE[1])}
                </div>
                <div className={styles.reportSort}>作成日時</div>
                <ArrowDownwardIcon
                  className={styles.icon}
                  onClick={this.sortReportB}
                  onKeyDown={(e) => {
                    this.keyDown(e, () => {
                      this.sortReportB();
                    });
                  }}
                  style={styleBSortIcon}
                  tabIndex={0}
                ></ArrowDownwardIcon>
              </div>
            </div>
            <div
              className={
                this.state.cntB
                  ? styles.reportAList
                  : styles.reportAList + " " + styles.display_none
              }
              style={styleB}
            >
              <div className={styles.reportGrid}>{reportB}</div>
            </div>
          </div>
        </div>
        <Dialog
          open={this.state.openDelDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {this.state.delMessage}
          </DialogTitle>
          <DialogActions>
            <Button
              onClick={() => {
                this.deleteReport();
                this.setState({
                  openDelDialog: false,
                  delData: new ReportList(-1, -1, "", "", false, false),
                });
              }}
            >
              はい
            </Button>
            <Button
              autoFocus
              onClick={() => {
                this.setState({
                  openDelDialog: false,
                  delData: new ReportList(-1, -1, "", "", false, false),
                });
              }}
            >
              いいえ
            </Button>
          </DialogActions>
        </Dialog>
        <Modal
          isOpen={this.state.loading}
          className="loader-dialog"
          ariaHideApp={false}
        >
          <div className="loader-modal">
            <div className="loader"></div>
          </div>
        </Modal>
        <CommonDialog
          open={this.state.showMessage}
          title={this.state.title as any}
          message={this.state.message as any}
          doOk={() => this.setState({ showMessage: false })}
        ></CommonDialog>
      </div>
    );
  }
}

export default ReportPage;

//https://gridjs.io/docs/examples/row-buttons grid.js
