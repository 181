import MoreVertIcon from "@mui/icons-material/MoreVert";
import Button from "@mui/material/Button";
// ダイアログ
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
import { html } from "gridjs";
/// テーブル用の
/*import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";*/
import { Grid, _ } from "gridjs-react";
import "gridjs/dist/theme/mermaid.css";
import React from "react";
import "react-pro-sidebar/dist/css/styles.css";
import styles from "./LearningDiv.module.scss";
import PNG from "./rigthPage.png";
import { allowedNodeEnvironmentFlags } from "process";
import UploadDiv from "./UploadDiv";
import DownloadDiv from "./DownloadDiv";
import Checkbox from "@mui/material/Checkbox";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
type Props = {};
interface SlidebarState {
  close: boolean;
}
interface LearningDivState {
  now: boolean;
}
//<Props,State>
export class LearningDiv extends React.Component<any, LearningDivState> {
  constructor(props: LearningDivState) {
    super(props); // stateを初期化
    this.state = { now: true };
  }
  changeRadio(f: boolean) {
    this.setState({
      now: f,
    });
  }
  render(): React.ReactNode {
    return (
      <div className={styles.LearningDiv}>
        {/*** タイトルエリア */}
        <div className={styles.title}>学習実行</div>
        {/**対象期間エリア */}
        <div className={styles.tableAddArea}>
          <div className={styles.periodButtonArea}>対象期間:</div>
          <Button className={styles.Button} variant="contained">
            追加
          </Button>
          <Button className={styles.Button} variant="contained" disabled>
            削除
          </Button>
        </div>
        {/**テーブル期間 */}
        <div className={styles.tableDiv}>
          <table>
            <tbody className={styles.tbody}>
              <tr>
                <td>
                  <Checkbox />{" "}
                </td>
                <td>期間1　</td>
                <td>
                  <TextField
                    className={styles.date}
                    size="small"
                    id="date"
                    type="date"
                    sx={{ width: 150 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label={""}
                  />
                </td>
                <td>
                  <TextField
                    size="small"
                    id="date"
                    type="time"
                    className={styles.time}
                    defaultValue=""
                    sx={{ width: 100 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label={""}
                  />
                </td>
                <td>から</td>
                <td>
                  <TextField
                    className={styles.date}
                    size="small"
                    id="date"
                    type="date"
                    sx={{ width: 150 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label={""}
                  />
                </td>
                <td>
                  <TextField
                    size="small"
                    id="date"
                    type="time"
                    className={styles.time}
                    defaultValue=""
                    sx={{ width: 100 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label={""}
                  />
                </td>
                <td>まで</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className={styles.interval}></div>
        {/**対象期間エリア */}
        <div className={styles.tableAddArea}>
          <div className={styles.periodButtonArea}>除外期間:</div>
          <Button className={styles.Button} variant="contained">
            追加
          </Button>
          <Button className={styles.Button} variant="contained" disabled>
            削除
          </Button>
        </div>
        {/**除外期間 */}
        <div className={styles.tableDiv}>
          <table>
            <tbody className={styles.tbody}>
              <tr>
                <td>
                  <Checkbox />{" "}
                </td>
                <td>期間1　</td>
                <td>
                  <TextField
                    className={styles.date}
                    size="small"
                    id="date"
                    type="date"
                    sx={{ width: 150 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label={""}
                  />
                </td>
                <td>
                  <TextField
                    size="small"
                    id="date"
                    type="time"
                    className={styles.time}
                    defaultValue=""
                    sx={{ width: 100 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label={""}
                  />{" "}
                </td>
                <td>から</td>
                <td>
                  <TextField
                    className={styles.date}
                    size="small"
                    id="date"
                    type="date"
                    sx={{ width: 150 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label={""}
                  />
                </td>
                <td>
                  <TextField
                    size="small"
                    id="date"
                    type="time"
                    className={styles.time}
                    defaultValue=""
                    sx={{ width: 100 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label={""}
                  />{" "}
                </td>
                <td>まで</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className={styles.interval}></div>
        {/**　開始日時 */}
        <div className={styles.startDateArea}>
          <div className={styles.startDateTitle}> 開始日時</div>
          <div className={styles.radioArea}>
            <div>
              {" "}
              <RadioGroup>
                <FormControlLabel
                  value="今すぐ開始"
                  control={
                    <Radio
                      checked={this.state.now}
                      onChange={() => {
                        this.changeRadio(true);
                      }}
                    />
                  }
                  label="今すぐ開始"
                ></FormControlLabel>
                <FormControlLabel
                  value="指定した日時に開始"
                  control={
                    <Radio
                      checked={!this.state.now}
                      onChange={() => {
                        this.changeRadio(false);
                      }}
                    />
                  }
                  label="指定した日時に開始"
                ></FormControlLabel>
              </RadioGroup>
            </div>
          </div>
          {/**開始日時の入力欄 */}
          <div className={styles.startDateArea}>
            <TextField
              className={styles.date}
              size="small"
              id="date"
              type="date"
              sx={{ width: 150 }}
              InputLabelProps={{
                shrink: true,
              }}
              disabled={this.state.now}
              label={""}
            />
            <TextField
              size="small"
              id="date"
              type="time"
              className={styles.time}
              defaultValue=""
              sx={{ width: 100 }}
              InputLabelProps={{
                shrink: true,
              }}
              disabled={this.state.now}
              label={""}
            />
            <div className={styles.startText}>から実行</div>
          </div>
          <div className={styles.interval}></div>
        </div>{" "}
        {/**ボタンエリア */}
        <div className={styles.buttonArea}>
          <div className={styles.flex + " " + styles.StartButton}>
            <Button className={styles.Button} variant="contained">
              初期学習
            </Button>
            <Button className={styles.Button} variant="contained">
              追加学習
            </Button>
          </div>
          <div className={styles.intervalButton}></div>
          <div className={styles.flex + " " + styles.StartButton}>
            <Button className={styles.Button} variant="contained">
              訂正学習
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default LearningDiv;

//https://gridjs.io/docs/examples/row-buttons grid.js
