/**
 * トップ画面：ヘッダ部分
 */
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import LogoutIcon from "@mui/icons-material/Logout";
import React from "react";
import InputEvent from "../../common/utility/InputEvent";
import Config from "../config/config.json";
import MainServices from "../config/MainServices.json";
import UtilityServices from "../config/UtilityServices.json";
import styles from "./ServiceList.module.scss";
import "./ServiceListAnime.css";
import "./ServiceListAnime.js";

const SlideToggle = require("react-slide-toggle");

/**
 * 親コンポーネントから伝搬される情報
 */
interface eneportalProps {
  /** ログイン済みか */
  logged: boolean;
}

/**
 * ポータルサイト用ヘッダ(≠三菱電機ヘッダ)コンポーネント
 */
export class ServiceList extends React.Component<eneportalProps, any> {
  constructor(props: eneportalProps) {
    super(props);
    this.state = {
      toggleEvent: 0,
      show: false,
    };
  }

  /**
   * トグルイベント通知
   */
  onToggle = (e: any) => {
    // クリックとエンターキー押下時
    if (e.type == "click" || (e.type == "keydown" && e.keyCode == 13)) {
      this.setState({ show: !this.state.show });
    }
  };

  /**
   * サービスリスト生成
   * @returns サービスリストJSX
   */
  createServiceList() {
    let resList = new Array();

    for (let i in MainServices) {
      resList.push(
        <li key={i}>
          <ArrowRightIcon />
          {MainServices[i].underConstruction ? (
            <a
              href="javascript:void(0)"
              onClick={this.showUnderConstructionMessage}
            >
              {MainServices[i].title}
            </a>
          ) : (
            <a href={MainServices[i].href}>{MainServices[i].title}</a>
          )}
        </li>
      );
    }

    return resList;
  }

  /**
   * その他リスト生成
   * @returns その他リストJSX
   */
  createOthersList() {
    let resList = new Array();

    for (let i in UtilityServices) {
      resList.push(
        <li key={i}>
          <ArrowRightIcon />
          {UtilityServices[i].underConstruction ? (
            <a
              href="javascript:void(0)"
              onClick={this.showUnderConstructionMessage}
            >
              {UtilityServices[i].title}
            </a>
          ) : (
            <a href={UtilityServices[i].href}>{UtilityServices[i].title}</a>
          )}
        </li>
      );
    }

    return resList;
  }

  /**
   * メニュー開閉時の高さスタイル生成
   * @returns メニュー開閉時の高さスタイル
   */
  menuOpenCloseStyle() {
    let res = {};
    if (this.state.show) {
      res = { height: "250px" }; //, display: "" };
    } else {
      res = { height: "0px" }; //, display: "none" };
    }
    return res;
  }

  /**
   * メニュー開閉時のボタンアイコン生成
   * @returns メニュー開閉時のボタンアイコンJSX
   */
  menuOpenCloseButtonIcon() {
    if (this.state.show) {
      return <ArrowDropUpIcon />; //, display: "" };
    } else {
      return <ArrowDropDownIcon />; //, display: "none" };
    }
  }

  /**
   * メニュー開閉時のメニューのクラス名
   * @returns メニュー開閉時のメニューのクラス名
   */
  menuOpenCloseClass() {
    let res;
    if (this.state.show) {
      res = styles.opened;
    } else {
      res = "";
    }
    return res;
  }

  /**
   * 工事中メッセージを表示
   */
  showUnderConstructionMessage(e: any) {
    // クリックとエンターキー押下時
    if (InputEvent.checkClickEnter(e)) {
      alert("coming soon");
    }
  }

  /**
   * マイページへ遷移
   */
  mypage(e: any) {
    // クリックとエンターキー押下時
    if (InputEvent.checkClickEnter(e)) {
      window.open("./mypage", "mypage");
    }
  }

  /**
   * ログインページへ遷移
   */
  login(e: any) {
    // クリックとエンターキー押下時
    if (InputEvent.checkClickEnter(e)) {
      window.location.href = "./login";
    }
  }

  /**
   * ログアウトページへ遷移
   */
  logout(e: any) {
    // クリックとエンターキー押下時
    if (InputEvent.checkClickEnter(e)) {
      window.location.href = "./logout";
    }
  }

  /**
   * よくある質問ページへ遷移
   */
  faq(e: any) {
    if (InputEvent.checkClickEnter(e)) {
      //window.open(Config.faq.url, "_blank");
      window.location.href = Config.faq.url;
    }
  }
  createLoginButton(loggedIn: boolean) {
    let res;
    // ログイン済み
    if (loggedIn) {
      res = (
        <div
          className={styles.login_div}
          tabIndex={0}
          role="button"
          onClick={(e) => this.mypage(e)}
          onKeyDown={(e) => this.mypage(e)}
        >
          マイページ
        </div>
      );
    } else {
      // 未ログイン
      res = (
        //TODO 仮
        <div
          className={styles.login_div}
          tabIndex={0}
          role="button"
          onClick={(e) => this.login(e)}
          onKeyDown={(e) => this.login(e)}
        >
          ログイン
        </div>
      );
    }
    return res;
  }

  /**
   * 描画処理
   * @returns 描画するJSX
   */
  render() {
    let serviceList = this.createServiceList();

    let othersList = this.createOthersList();

    let dip = this.menuOpenCloseStyle();
    let showIcon = this.menuOpenCloseButtonIcon();
    let cs = this.menuOpenCloseClass();

    // ログアウトアイコンの表示非表示
    let hide = styles.hide;

    // ログイン済みのとき
    if (this.props.logged) {
      hide = "";
    }

    // ログインボタン
    let loginButton = this.createLoginButton(this.props.logged);

    return (
      <div className={styles.serviceList}>
        <div className={styles.l_site_header}>
          <div className={styles.site_hed_container}>
            <div className={styles.site_hed_nav_wrap}>
              <div className={styles.site_hed_nav}>
                <div className={styles.sote_hed_title_div}>
                  <div lang="en"> ANALYSIS PORTAL&nbsp;&lt;X-chAIn&gt;.</div>
                </div>
                <div className={styles.site_hed_nav__container}>
                  <div className={cs}>
                    <div
                      className={styles.site_hed_li_name + " " + cs}
                      onClick={(e) => this.onToggle(e)}
                      onKeyDown={(e) => this.onToggle(e)}
                      tabIndex={0}
                      role="button"
                    >
                      <label>サービス一覧</label>
                      {showIcon}
                    </div>
                    <div className={styles.site_hed_menu} style={dip}>
                      <div className={styles.site_hed_menu__container}>
                        <div className={styles.site_hed_menu__body}>
                          <div>分析サービス</div>
                          <ul className={styles.site_hed_menu__links}>
                            {serviceList}
                          </ul>
                        </div>
                        <div className={styles.site_hed_menu_centerLine}>
                          <div className={styles.site_hed_menu_Line}></div>
                        </div>
                        {/*                        <div className={styles.site_hed_menu__body}>
                          <div>関連リンク</div>
                          <ul
                            className={
                              styles.site_hed_menu__links +
                              " " +
                              styles.site_hed_menu__links_others
                            }
                          >
                            {othersList}
                          </ul>
                        </div> */}

                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      (styles.site_hed_nav_item,
                        styles.site_hed_nav_item_suport)
                    }
                  >
                    <div className={styles.site_hed_li_name}>
                      <label
                        className={styles.no_img_label}
                        tabIndex={0}
                        role="button"
                        onClick={(e) => this.faq(e)}
                        onKeyDown={(e) => this.faq(e)}
                      >
                        {/*ご利用サポート*/}
                        よくある質問
                      </label>
                    </div>
                  </div>
                  <div
                    className={
                      (styles.site_hed_nav_item, styles.site_hed_nav_item_login)
                    }
                  >
                    <div className={styles.login_div_area}>{loginButton}</div>
                  </div>
                  <div className={styles.logInOutIconArea + " " + hide}>
                    <LogoutIcon
                      sx={{ color: "#32639b" }}
                      className={styles.icon}
                      tabIndex={0}
                      role="button"
                      onClick={(e) => this.logout(e)}
                      onKeyDown={(e) => this.logout(e)}
                    ></LogoutIcon>
                  </div>
                </div>
              </div>
            </div>
            {/*<!-- /.site-hed-nav-wrap -->*/}
          </div>
          {/*<!-- /.site-hed-container -->*/}
        </div>
      </div>
    );
  }
}
