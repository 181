import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import React from "react";
import { BreadcrumbHeader } from "../../../common/components/BreadcrumbHeader";
import { ServiceList } from "../../../common/components/ServiceList";
import InputEvent from "../../../common/utility/InputEvent";
import featureImage4 from "../../icon/24時間受付のご相談窓口.svg";
import serviceImage4 from "../../icon/デジタル書庫.svg";
import processImage1 from "../../icon/使い方01@4x.png";
import processImage2 from "../../icon/使い方02@4x.png";
import processImage3 from "../../icon/使い方03@4x.png";
import processImage4 from "../../icon/使い方04@4x.png";
import featureImage3 from "../../icon/手軽な操作.svg";
import serviceImage1 from "../../icon/最適運転分析豚モノクロ_土赤茶色.svg";
import featureImage1 from "../../icon/様々なシーンで活躍.svg";
import serviceImage3 from "../../icon/現場作業・点検情報管理.svg";
import customerImage from "../../icon/現場実務者.svg";
import topImage from "../../icon/異常兆候分析.svg";
import featureImage2 from "../../icon/目的に応じた使い方_2人.svg";
import wakabaIcon from "../../icon/若葉マーク.svg";
import serviceImage2 from "../../icon/部分放電分析.svg";
import styles from "./DescriptionApr.module.scss";

/** 使い方 > クリック のスクロール量*/
const USE_SCROll = 1510;

interface IUploadViewProps {
  status: number;
  breadcrumb: {};
}
export class DescriptionApr extends React.Component<any, IUploadViewProps> {
  constructor(props: any) {
    super(props);
    this.state = {
      status: 12,
      breadcrumb: [
        { name: "ANALYSIS PORTAL <X-chAIn>紹介ページ", href: "../" },
        { name: "異常兆候分析紹介ページ", href: "/" },
      ],
    };
  }
  /**
   * 別ウインドウを開く
   * @param url URL
   */
  openWindow(url: string, e: any, target: string) {
    if (InputEvent.checkClickEnter(e)) {
      window.open(url, target);
    }
  }

  returnTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  login() {
    window.location.href = "/login";
    //window.open("../logged");
  }
  clickDemo = (e: any) => {
    if (InputEvent.checkClickEnter(e)) {
      alert("comming soon");
    }
  };

  // 特徴 > クリック
  moveFeature = (e: any) => {
    if (InputEvent.checkClickEnter(e)) {
      window.scrollTo({
        top: 850,
        behavior: "smooth",
      });
    }
  };

  // 使い方 > クリック
  moveUse = (e: any) => {
    if (InputEvent.checkClickEnter(e)) {
      window.scrollTo({
        top: USE_SCROll,
        behavior: "smooth",
      });
    }
  };

  // ご利用プラン > クリック
  movePlan = (e: any) => {
    if (InputEvent.checkClickEnter(e)) {
      window.scrollTo({
        top: 3510,
        behavior: "smooth",
      });
    }
  };

  // お客さまの声 > クリック
  moveCustomer = (e: any) => {
    if (InputEvent.checkClickEnter(e)) {
      window.scrollTo({
        top: 4480,
        behavior: "smooth",
      });
    }
  };

  render() {
    var url = "./";
    var target = "";
    // ログイン済みのとき
    if (this.props.logged) {
      url = "./mypage";
    } else {
      url = "./login?page=/mypage";
    }
    return (
      <div
        style={{
          fontSize: "16px !important",
          height: "100%",
          maxWidth: "1440px !important",
          margin: "0 auto",
          textAlign: "center",
        }}
      >
        {/*パンクズリスト className={}*/}
        <div>
          <BreadcrumbHeader
            breadcrumb={this.state.breadcrumb}
          ></BreadcrumbHeader>
        </div>
        {/*サービス一覧ヘッダ */}
        <div className="headerFix">
          <ServiceList logged={this.props.logged}></ServiceList>
        </div>
        <div className={styles.desc_outside}>
          <div className={styles.desc_topImage_outside}>
            <div className={styles.desc_topImage_title}>
              異常兆候分析
            </div>
            <div className={styles.desc_topImage_contents}>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <img src={topImage} alt="異常兆候分析"></img>
                    </td>
                    <td>
                      <div className={styles.desc_topImage_contents_script}>
                        <p className={styles.desc_topImage_contents_title}>
                          AIにより、いつもと違う異変を診断
                        </p>
                        <div className={styles.desc_topImage_contents_p}>
                          <p>
                            機械学習を用いたプロセスデータ解析サービスです。
                          </p>
                          <p>
                            入力データに基づき、異変の有無をランキング形式で
                          </p>
                          <p>見える化・レポートします。</p>
                        </div>
                        <div
                          className={styles.desc_demo_button}
                          tabIndex={0}
                          role="button"
                          onClick={(e) => this.openWindow(url, e, "mypage")}
                          onKeyDown={(e) => this.openWindow(url, e, "mypage")}
                        >
                          サービスを利用する
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className={styles.desc_menu}>
              <table>
                <tbody>
                  <tr>
                    <td
                      onClick={(e) => this.moveFeature(e)}
                      onKeyDown={(e) => this.moveFeature(e)}
                      tabIndex={0}
                      role="button"
                    >
                      特徴　&gt;
                    </td>
                    <td
                      className={styles.desc_menu_bar}
                      onClick={(e) => this.moveUse(e)}
                      onKeyDown={(e) => this.moveUse(e)}
                      tabIndex={0}
                      role="button"
                    >
                      使い方 　&gt;
                    </td>
                    <td
                      className={styles.desc_menu_bar}
                      onClick={(e) => this.movePlan(e)}
                      onKeyDown={(e) => this.movePlan(e)}
                      tabIndex={0}
                      role="button"
                    >
                      ご利用プラン 　&gt;
                    </td>
                    <td
                      className={styles.desc_menu_bar}
                      onClick={(e) => this.moveCustomer(e)}
                      onKeyDown={(e) => this.moveCustomer(e)}
                      tabIndex={0}
                      role="button"
                    >
                      お客さまの声 　&gt;
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className={styles.desc_feature + " animation_bottom"}>
              <div className={styles.desc_feature_title}>特徴</div>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <div className={styles.desc_feature_contents}>
                        <div className={styles.desc_feature_subTitle}>
                          さまざまなシーンで活躍
                        </div>
                        <div className={styles.desc_feature_subContents}>
                          <p>計測機器やセンサなど、</p>
                          <p>時系列データから異変を検知します。</p>
                        </div>
                      </div>
                      <div className={styles.desc_feature_image}>
                        <img
                          className={styles.desc_feature_image_img1}
                          src={featureImage1}
                          alt="さまざまなシーンで活躍"
                        ></img>
                      </div>
                    </td>
                    <td>
                      <div className={styles.desc_feature_contents}>
                        <div className={styles.desc_feature_subTitle}>
                          目的に応じた使い方
                        </div>
                        <div className={styles.desc_feature_subContents}>
                          <p>「ちょっと使ってみたい」「継続的に</p>
                          <p>活用したい」など様々な用途に対応し</p>
                          <p>ます。</p>
                        </div>
                      </div>
                      <div className={styles.desc_feature_image}>
                        <img
                          className={styles.desc_feature_image_img2}
                          src={featureImage2}
                          alt="目的に応じた使い方"
                        ></img>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className={styles.desc_feature_contents}>
                        <div className={styles.desc_feature_subTitle}>
                          手軽な操作
                        </div>
                        <div className={styles.desc_feature_subContents}>
                          <p>正常時と気になる期間のデータを</p>
                          <p>準備しワンクリックで分析します。</p>
                        </div>
                      </div>
                      <div className={styles.desc_feature_image}>
                        <img
                          className={styles.desc_feature_image_img3}
                          src={featureImage3}
                          alt="手軽な操作"
                        ></img>
                      </div>
                    </td>
                    <td>
                      <div className={styles.desc_feature_contents}>
                        <div className={styles.desc_feature_subTitle}>
                          24時間受付のご相談窓口
                        </div>
                        <div className={styles.desc_feature_subContents}>
                          <p>データフォーマットの変換が面倒など、</p>
                          <p>データ管理を含めた様々なご相談を</p>
                          <p>受け付けています。</p>
                        </div>
                      </div>
                      <div className={styles.desc_feature_image}>
                        <img
                          className={styles.desc_feature_image_img4}
                          src={featureImage4}
                          alt="24時間受付のご相談窓口"
                        ></img>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div id="aprUse" className={styles.desc_use_outside}>
            <div className={styles.desc_use_white}>
              <div className={styles.desc_use_title + " animation_bottom"}>
                使い方
              </div>
              <div className={styles.desc_use_contents + " animation_bottom"}>
                <div className={styles.desc_use_subTitle}>
                  01.分析メニューを選ぶ
                </div>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <div className={styles.desc_use_contents_process}>
                          <p>
                            サイドメニューから、「波形比較」を選択します。</p>
                        </div>
                        <div className={styles.desc_use_contents_process}>
                          <p className={styles.desc_use_content_processs_bold}>
                            波形比較
                          </p>
                          <p>
                            学習ファイルと分析ファイルの2つを用意し、分析ファイル内の異変
                          </p>
                          <p>を簡易評価。</p>
                        </div>
                        {/*
                        <div className={styles.desc_use_contents_process}>
                          <p className={styles.desc_use_content_processs_bold}>
                            異常兆候分析
                          </p>
                          <p>
                            正常データをもとに学習モデルを作成し、設備の異常兆候を
                          </p>
                          <p>高精度に検出。</p>
                        </div>
                         */}
                      </td>
                      <td>
                        <div className={styles.desc_use_contents_image}>
                          <img
                            className={styles.desc_use_contents_image_img1}
                            src={processImage1}
                            alt="分析メニューを選ぶ"
                          ></img>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div
                className={
                  styles.c_bulletDownArrow_style01 + " animation_bottom"
                }
              ></div>
              <div className={styles.desc_use_contents + " animation_bottom"}>
                <div className={styles.desc_use_subTitle}>
                  02.分析ファイル(データ)を選ぶ
                </div>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <div className={styles.desc_use_contents_process}>
                          <p>学習ファイルと分析ファイル(※)を指定のエリアへ</p>
                          <p>ドラッグ＆ドロップし、登録します。</p>
                        </div>
                        <div
                          className={
                            styles.desc_use_contents_process +
                            " " +
                            styles.desc_use_content_processs_gray
                          }
                        >
                          <p>
                            ※「データフォームダウンロード」で、指定のフォーマットファイルを
                          </p>
                          <p>　　ダウンロードできます。</p>
                        </div>
                      </td>
                      <td>
                        <div className={styles.desc_use_contents_image}>
                          <img
                            className={styles.desc_use_contents_image_img2}
                            src={processImage2}
                            alt="分析ファイル(データ)を選ぶ"
                          ></img>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div
                className={
                  styles.c_bulletDownArrow_style01 + " animation_bottom"
                }
              ></div>
              <div className={styles.desc_use_contents + " animation_bottom"}>
                <div className={styles.desc_use_subTitle}>
                  03.分析を開始する
                </div>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <div className={styles.desc_use_contents_process}>
                          <p>
                            「分析を開始」ボタンを押して、分析を開始します。
                          </p>
                        </div>
                      </td>
                      <td>
                        <div className={styles.desc_use_contents_image}>
                          <img
                            className={styles.desc_use_contents_image_img3}
                            src={processImage3}
                            alt="分析を開始する。"
                          ></img>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className={styles.c_bulletDownArrow_style01}></div>
              <div className={styles.desc_use_contents + " animation_bottom"}>
                <div className={styles.desc_use_subTitle}>
                  04.分析結果を確認する
                </div>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <div className={styles.desc_use_contents_process}>
                          <p>
                            分析が完了すると、レポート一覧に分析レポートが追加されます。
                          </p>
                          <p>該当レポートを選び、分析結果を確認します。</p>
                          <p>(分析完了時には、画面右上の「<NotificationsNoneIcon
                            className={styles.desc_use_contents_icon}
                          />」に通知されます。</p>
                        </div>
                      </td>
                      <td>
                        <div className={styles.desc_use_contents_image}>
                          <img
                            className={styles.desc_use_contents_image_img4}
                            src={processImage4}
                            alt="分析結果を確認する。"
                          ></img>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div
              className={
                styles.desc_use_white +
                " " +
                styles.desc_plan +
                " animation_bottom"
              }
            >
              <div className={styles.desc_use_title}>ご利用プラン</div>
              <p>ご利用目的に応じ、以下2つのプランからお選びいただけます。</p>
              <table>
                <tbody >
                  <tr className={styles.desc_plan_tr1}>
                    {/* <td>
                      <label>まずはお試し</label>
                    </td> */}
                    <td>
                      <label>まずはお試し</label>
                    </td>
                    <td style={{ width: "100px" }}>
                      {/*トライアル版復帰時に削除 */}
                    </td>
                    <td>
                      <label>異常分析を開始したい</label>
                    </td>
                  </tr>
                  <tr className={styles.desc_plan_tr2}>
                    {/* <td>
                      <p className={styles.desc_plan_tr2_image}>
                        <img src={wakabaIcon} alt="トライアル"></img>トライアル
                      </p>
                      <label>プラン</label>
                  </td>*/}
                    <td>
                      <p>ライト</p>
                      <label>プラン</label>
                    </td>
                    <td style={{ width: "100px" }}>
                      {/*トライアル版復帰時に削除 */}
                    </td>
                    <td>
                      <p>スタンダード</p>
                      <label>プラン</label>
                    </td>
                  </tr>
                  <tr>
                    {/* 
                    <td className={styles.desc_plan_tr3}>
                      <div className={styles.desc_plan_tr3_background}>
                        <div className={styles.desc_plan_tr3_div}>
                          <p className={styles.desc_plan_tr3_text1}>
                            最大分析点数
                          </p>
                          <label className={styles.desc_plan_tr3_text2}>
                            10
                          </label>
                          点
                        </div>
                        <div className={styles.desc_plan_tr3_div}>
                          <label className={styles.desc_plan_tr3_text1}>
                            分析回数
                          </label>
                          <label className={styles.desc_plan_tr3_text2}>
                            1
                          </label>
                          回
                        </div>
                        <div className={styles.desc_plan_tr3_text3}>無料</div>
                      </div>
                </td>*/}
                    <td className={styles.desc_plan_tr3}>
                      <div className={styles.desc_plan_tr3_background}>
                        <div className={styles.desc_plan_tr3_div}>
                          <p
                            className={
                              styles.desc_plan_tr3_text1 +
                              " " +
                              styles.desc_plan_tr3_text1_300_1000
                            }
                          >
                            最大分析点数
                          </p>
                          <label
                            className={
                              styles.desc_plan_tr3_text2 +
                              " " +
                              styles.desc_plan_tr3_text2_300
                            }
                          >
                            10
                          </label>
                          点
                        </div>
                        <div className={styles.desc_plan_tr3_div + " " + styles.desc_plan_tr3_center}>
                          <label
                            className={
                              styles.desc_plan_tr3_text1 +
                              " " +
                              styles.desc_plan_tr3_text1_200_1000
                            }
                          >
                            ひと月当たりの分析回数
                          </label>
                          <label
                            className={
                              styles.desc_plan_tr3_text2 + " " + styles.desc_plan_tr3_text2_mobius
                            }
                          >
                            100
                          </label>
                          回
                        </div>
                        <div
                          className={
                            styles.desc_plan_tr3_text3 +
                            " " +
                            styles.desc_plan_tr3_text3_script
                          }
                        >
                          ￥
                          <label className={styles.desc_plan_tr3_text3_num}>
                            10
                          </label>
                          万/月
                          <label className={styles.desc_plan_tr3_text3_small}>(30日)</label>
                        </div>
                      </div>
                    </td>
                    <td style={{ width: "100px" }}>
                      {/*トライアル版復帰時に削除 */}
                    </td>
                    <td className={styles.desc_plan_tr3}>
                      <div className={styles.desc_plan_tr3_background}>
                        <div className={styles.desc_plan_tr3_div}>
                          <p
                            className={
                              styles.desc_plan_tr3_text1 +
                              " " +
                              styles.desc_plan_tr3_text1_300
                            }
                          >
                            分析モデル構築用環境を準備
                          </p>
                        </div>
                        <div className={styles.desc_plan_tr3_div}>
                          <label
                            className={
                              styles.desc_plan_tr3_text1 +
                              " " +
                              styles.desc_plan_tr3_text1_300
                            }
                          >
                            信号点数やデータ期間は
                          </label>
                        </div>
                        <div
                          className={
                            styles.desc_plan_tr3_text3 +
                            " " +
                            styles.desc_plan_tr3_text3_script
                          }
                        >
                          <label className={styles.desc_plan_tr3_text3}>
                            応相談
                          </label>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              className={styles.desc_demo_button + " animation_bottom"}
              tabIndex={0}
              role="button"
              onClick={(e) => this.openWindow(url, e, "mypage")}
              onKeyDown={(e) => this.openWindow(url, e, "mypage")}
            >
              サービスを利用する
            </div>
          </div>
          <div className={styles.desc_customer_outside}>
            <div className={styles.desc_use_title + " animation_bottom"}>
              お客さまの声
            </div>
            <div
              className={styles.desc_customer_contents + " animation_bottom"}
            >
              <div className={styles.desc_customer_image}>
                <img src={customerImage} alt="お客さまの声"></img>
              </div>
              <div className={styles.desc_customer_subcontents}>
                <p className={styles.desc_customer_p1}>
                  日々の点検では気付けなかった異常を発見
                </p>
                <p className={styles.desc_customer_p2}>
                  復水器の補給水流量の増加傾向検知により、警報しきい値に到達するより早いタイミングにて
                </p>
                <p className={styles.desc_customer_p2}>
                  チューブ漏洩を発見し、早期のトラブル対応に貢献できた。
                </p>
              </div>
            </div>
            <div
              className={styles.desc_customer_contents + " animation_bottom"}
            >
              <div className={styles.desc_customer_image}>
                <img src={customerImage} alt="お客さまの声"></img>
              </div>
              <div className={styles.desc_customer_subcontents}>
                <p className={styles.desc_customer_p1}>
                  <p>トラブルによる影響が運転員の意識しない範囲にまで</p>
                  <p>及んでいることを理解</p>
                </p>
                <p className={styles.desc_customer_p2}>
                  配管の蒸気止め弁にトラブルがあった際、影響が蒸気圧力や温度、流量に出るが、
                </p>
                <p className={styles.desc_customer_p2}>
                  どのポイントのセンサ値に影響が及ぶかについて、時系列と共に明確に特定、認識できた。
                </p>
              </div>
            </div>
            <div
              className={styles.desc_demo_button + " animation_bottom"}
              tabIndex={0}
              role="button"
              onClick={(e) => this.openWindow(url, e, "mypage")}
              onKeyDown={(e) => this.openWindow(url, e, "mypage")}
            >
              サービスを利用する
            </div>
          </div>
          {/**
          <div className={styles.desc_related_outside + " animation_bottom"}>
            <div
              className={
                styles.desc_use_title + " " + styles.desc_related_title
              }
            >
              関連サービス
            </div>
            <div className={styles.desc_related_image}>
              <table>
                <tbody>
                  <tr className={styles.desc_related_image_white}>
                    <td
                      className={styles.desc_related_image_td}
                      onClick={(e) => this.clickDemo(e)}
                      onKeyDown={(e) => this.clickDemo(e)}
                      tabIndex={0}
                      role="button"
                    >
                      <img
                        className={styles.desc_related_image_img1}
                        src={serviceImage1}
                        alt="最適運転分析"
                      ></img>
                    </td>
                    <td
                      className={styles.desc_related_image_td}
                      onClick={(e) => this.clickDemo(e)}
                      onKeyDown={(e) => this.clickDemo(e)}
                      tabIndex={0}
                      role="button"
                    >
                      <img
                        className={styles.desc_related_image_img2}
                        src={serviceImage2}
                        alt="部分放電"
                      ></img>
                    </td>
                    <td
                      className={styles.desc_related_image_td}
                      onClick={(e) => this.clickDemo(e)}
                      onKeyDown={(e) => this.clickDemo(e)}
                      tabIndex={0}
                      role="button"
                    >
                      <img
                        className={styles.desc_related_image_img3}
                        src={serviceImage3}
                        alt="現場作業管理"
                      ></img>
                    </td>
                    <td
                      className={styles.desc_related_image_td}
                      onClick={(e) => this.clickDemo(e)}
                      onKeyDown={(e) => this.clickDemo(e)}
                      tabIndex={0}
                      role="button"
                    >
                      <img
                        className={styles.desc_related_image_img4}
                        src={serviceImage4}
                        alt="デジタル書庫"
                      ></img>
                    </td>
                  </tr>
                  <tr>
                    <td>最適運転分析</td>
                    <td>部分放電分析</td>
                    <td>現場作業管理</td>
                    <td>デジタル書庫</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div> */}
          {/**ページトップ */}
          <div className={styles.desc_topreturn_outside + " animation_bottom"}>
            <div className={styles.pageTopDiv} onClick={this.returnTop}>
              <div>▲</div>
              <div>PAGE TOP</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
