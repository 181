/*const animationTop = document.querySelectorAll(".animation_top");
const animationRight = document.querySelectorAll(".animation_right");
const animationBottom = document.querySelectorAll(".animation_bottom");
const animationLeft = document.querySelectorAll(".animation_left");
//const headerFix = document.querySelectorAll(".headerFix");

const animationLefttt = document.querySelectorAll(".animation_left");

const animationStep01 = document.querySelectorAll(".animation_left");
const animationStep02 = document.querySelectorAll(".animation_step02");
const animationStep03 = document.querySelectorAll(".animation_step03");*/

let offset = 100; // アニメーションのタイミング
window.addEventListener("scroll", () => {
  const animationTop = document.querySelectorAll(".animation_top");
  const animationRight = document.querySelectorAll(".animation_right");
  const animationBottom = document.querySelectorAll(".animation_bottom");
  // const animationLeft = document.querySelectorAll(".animation_left");
  const headerFix = document.querySelectorAll(".headerFix");

  // const animationLefttt = document.querySelectorAll(".animation_left");

  // const animationStep01 = document.querySelectorAll(".animation_left");
  // const animationStep02 = document.querySelectorAll(".animation_step02");
  // const animationStep03 = document.querySelectorAll(".animation_step03");
  // console.log("どうaaaaa？");
  let scroll = window.scrollY; // スクロール量を取得
  let height = window.innerHeight; // 画面の高さを取得
  for (let animation of animationTop) {
    let position = animation.getBoundingClientRect().top + scroll; // アニメーションのさせたい位置を取得
    if (scroll > position - height + offset) {
      animation.classList.add("active");
    } else {
      animation.classList.remove("active");
    }
  }

  for (let animation of animationRight) {
    let position = animation.getBoundingClientRect().top + scroll; // アニメーションのさせたい位置を取得
    if (scroll > position - height + offset) {
      animation.classList.add("active");
    } else {
      animation.classList.remove("active");
    }
  }

  for (let animation of animationBottom) {
    let position = animation.getBoundingClientRect().top + scroll; // アニメーションのさせたい位置を取得
    if (scroll > position - height + offset) {
      animation.classList.add("active");
    } else {
      // animation.classList.remove("active");
    }
  }

  // for (let animation of animationLeft) {
  //   let position = animation.getBoundingClientRect().top + scroll; // アニメーションのさせたい位置を取得
  //   if (scroll > position - height + offset) {
  //     animation.classList.add("active");
  //   } else {
  //     animation.classList.remove("active");
  //   }
  // }

  //header固定
  for (let animation of headerFix) {
    let position = animation.getBoundingClientRect().top + scroll; // アニメーションのさせたい位置を取得
    if (scroll > 100) {
      animation.classList.add("headerFixed");
    } else {
      animation.classList.remove("headerFixed");
    }
  }

  //step animation
});
