import React from "react";
import styles from "./EntryComplete.module.scss";

import {
  BreadcrumbHeader,
  Breadcrumb,
  BreadcrumbList,
} from "../common/components/BreadcrumbHeader";

interface IUploadViewProps {
  status: number;
  breadcrumb: {};
}

export class EntryComplete extends React.Component<any, IUploadViewProps> {
  constructor(props: any) {
    super(props);
    this.state = {
      status: 12,
      breadcrumb: [
        { name: "ANALYSIS PORTAL <X-chAIn>紹介ページ", href: "../" },
        { name: "申し込みページ", href: "/" },
      ],
    };
  }

  /**
   * コンポーネント読み込み後処理
   * @returns Promise
   */
  componentDidMount() {
    
  }

  /**
   * 予約実装部品向けの処理
   * @returns 常にfalse
   */
  handleReservedFunctionClicked() {
    alert('coming soon.');
    return false;
  }

  render() {
    return (
      <div
        style={{
          fontSize: "16px !important",
          height: "100%",
          maxWidth: "1440px !important",
          margin: "0 auto",
          textAlign: "center",
        }}
      >
        {/*パンクズリスト className={}*/}
        <div>
          <BreadcrumbHeader
            breadcrumb={this.state.breadcrumb}
          ></BreadcrumbHeader>
        </div>
        <div className={styles.entry_div_outside}>
          <div className={styles.entry_title}>
            ANALYSIS PORTAL&nbsp;&lt;X-chAIn&gt; サイト会員申し込み
          </div>
          <table className={styles.entry_state_table}>
            <tbody>
              <tr>
                <td className={styles.entry_state_td_state}>
                  1.必須情報の入力
                </td>
                <td>
                  <label className={styles.c_bulletLargeArrow_style01}></label>
                </td>
                <td className={styles.entry_state_td_state}>2.確認</td>
                <td>
                  <label className={styles.c_bulletLargeArrow_style01}></label>
                </td>
                <td
                  className={
                    styles.entry_state_td_state +
                    " " +
                    styles.entry_state_td_state_now
                  }
                >
                  3.完了
                </td>
              </tr>
            </tbody>
          </table>
          <div className={styles.entry_menu}>
            <div className={styles.entry_menu_title}>3.完了</div>
            <div className={styles.entry_menu_info}>
              <p className={styles.entry_menu_info_p1}>
                ご登録は未だ完了していません。
              </p>
              <p className={styles.entry_menu_info_p2}>
                ANALYSIS PORTAL&nbsp;&lt;X-chAIn&gt;サイト
                へのご登録を受け付けました。　
              </p>

              <div className={styles.entry_info}>
                <p className={styles.entry_menu_info_p3}>
                  登録完了メールが届くまで、数日お待ちください。
                </p>
              </div>
            </div>
            <div className={styles.entry_move_login}>
              <a className={styles.c_bulletArrow_style01} href={"."}>
              ANALYSIS PORTAL&nbsp;&lt;X-chAIn&gt;紹介ページ へ戻る
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
