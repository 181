import React from "react";
import styles from "./ResetPasswordAccept.module.scss";

interface IUploadViewProps {}
export class ResetPasswordAccept extends React.Component<
  any,
  IUploadViewProps
> {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <div
        style={{
          fontSize: "16px !important",
          height: "100%",
          maxWidth: "1440px !important",
          margin: "0 auto",
          textAlign: "center",
        }}
      >
        <div className={styles.reset_div_outside}>
          <div className={styles.reset_title}>パスワード初期化受付完了</div>
          <div className={styles.reset_menu_div}>
            <div className={styles.reset_contents}>
              <p className={styles.reset_contents_title}>
                パスワード初期化のご案内メールを送信いたしました。
              </p>
              <p className={styles.reset_contents_p}>
                期限内にパスワードの再設定手続きを行ってください。
              </p>
              <p className={styles.reset_contents_p}>
                しばらくしてもメールが届かない場合は、入力いただいたメールアドレスに誤りがある可能性があります。
              </p>
            </div>
          </div>
          <div className={styles.reset_move_top}>
            <a className={styles.c_bulletArrow_style01} href={"."}>
              <span lang="en">ANALYSIS PORTAL&nbsp;&lt;X-chAIn&gt;</span>
              紹介ページ へ戻る
            </a>{" "}
          </div>
        </div>
      </div>
    );
  }
}
