import Button from "@mui/material/Button";
import "gridjs/dist/theme/mermaid.css";
import React from "react";
import "react-pro-sidebar/dist/css/styles.css";
import styles from "./UploadDiv.module.scss";
type Props = {};
interface SlidebarState {
  close: boolean;
}
interface ModelPageState {
  message: string;
  open: boolean;
  showMenu: any;
  deleteDia: boolean;
  changeName: boolean;
  copyModel: boolean;
  createModel: boolean;
}
//<Props,State>
export class UploadDiv extends React.Component {
  constructor(props: any) {
    super(props); // stateを初期化
    this.state = {};
  }
  render(): React.ReactNode {
    return (
      <div className={styles.UploadDiv}>
        {/*** タイトルエリア */}
        <div className={styles.title}> 設定アップロード</div>
        <div className={styles.infoArea}>
          <div>相関組設定</div>
          <div>共起信号設定</div>
          <div>学習モデル切替設定</div>
          <div>学習モデル名設定</div>
        </div>
        {/**アップローボタン */}
        <div className={styles.buttonArea}>
          <label htmlFor="contained-button-file">
            <input
              accept=".zip"
              className={styles.uploadInput}
              id="contained-button-file"
              multiple
              type="file"
            />
            <Button
              sx={{
                width: "170px",
                height: "50px",
                fontSize: "18px",
                /*background: "rgb(105,105,105)",*/
              }}
              className={styles.Button}
              variant="contained"
              component="span"
            >
              アップロード
            </Button>
          </label>
        </div>
      </div>
    );
  }
}

export default UploadDiv;

//https://gridjs.io/docs/examples/row-buttons grid.js
