import React from "react";
import styles from "./BreadcrumbHeader.module.scss";

export interface Breadcrumb {
  name: string;
  href?: string;
}
export interface BreadcrumbList {
  breadcrumb: Breadcrumb[];
}

/**
 * ポータルサイト用ヘッダ(≠三菱電気ヘッダ)
 */
export class BreadcrumbHeader extends React.Component<any, BreadcrumbList> {
  constructor(props: any) {
    super(props);
    this.state = {
      breadcrumb: props.breadcrumb,
    };
  }

  createli(list: Breadcrumb[]) {
    //console.log(list);
    let resList = new Array();
    for (let i in list) {
      // 最終行のとき
      if (Number(i) == list.length - 1) {
        resList.push(
          <li key={i}>
            <a aria-current="page">{list[i].name}</a>
          </li>
        );
      } else {
        // それ以外のとき
        resList.push(
          <li key={i}>
            <a href={list[i].href}>{list[i].name}</a>
          </li>
        );
      }
    }
    return resList;
  }

  render() {
    let liList = this.createli(this.props.breadcrumb);
    return (
      <div className={styles.l_breadcrumbDiv}>
        <div className={styles.l_breadcrumb}>
          <ol className="breadcrumb-list">{liList}</ol>
          {/*<!-- /.breadcrumb-list -->*/
          /**テスト */}
        </div>
      </div>
    );
  }
}
