import PersonIcon from "@mui/icons-material/Person";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import Button from "@mui/material/Button";
// ダイアログ
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import React from "react";
import { AwsAmplifyUtils } from "../../common/components/AwsAmplifyUtils";
import CommonConfig from "../../common/config/config.json";
import profile from "./profile.module.scss";

export class Profile extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      trialDia: false,
      preview: null,
      inputNickName: null,
    };
    console.log(this.state);
  }
  openURL = (url: string, blank: boolean) => {
    if (blank) {
      window.open(url, "_blank");
    } else {
      window.location.href = url;
    }
  };

  openTest = () => {
    alert("coming soon");
  };

  trialDia(open: boolean) {
    if (open) {
      this.setState({
        trialDia: open,
        preview: null,
        inputNickName: null,
      });
    } else {
      this.setState({
        trialDia: open,
      });
    }
  }

  /**登録ボタン */
  set = () => {
    console.log("登録ボタンが押下されました。");
    var iconFlag = false;

    /**画像登録確認 */
    if (this.state.preview != null) {
      iconFlag = true;
    }
    /**ニックネーム登録 */
    if (this.state.inputNickName != null && this.state.inputNickName != "") {
      this.setNickname(this.props.userId, this.state.inputNickName, iconFlag);
    } else {
      // 画像登録trueなら登録
      if (iconFlag) {
        this.setUserIcon(this.props.userId, this.state.preview);
      } else {
        alert("登録するニックネーム、アイコンを設定してください。");
      }
    }
  };

  componentDidMount() { }

  // 写真を選択ボタンにファイル選択イベントを設定
  clickIconBtn = () => {
    let fileElem = document.getElementById("contained-button-file");
    if (fileElem) {
      fileElem.click();
    }
  };

  /**
   * 写真を登録
   * @param userId ユーザID
   * @param base64Data base64エンコードした画像
   */
  setUserIcon = (userId: any, base64Data: any) => {
    const headers = { "Content-Type": "application/json" };
    let data = {
      userId: userId,
      photoData: base64Data,
    };

    AwsAmplifyUtils.callAPI()
      .then((api) => {
        return api.API.post(
          "api",
          api.apiPrefix + "/userinfomanager/updatephoto",
          {
            headers: headers,
            body: data,
          }
        );
      })
      .then(async (res) => {
        console.log(res);
        if (res.errorCode == 0) {
          // state更新
          this.setState({
            profile: {
              icon: base64Data,
            },
            preview: null,
          });
          /**ページを閉じる */
          alert("登録が完了しました。");
          this.props.getUserInfo(this.props.userId);
          this.setState({
            trialDia: false,
          });
        } else {
          if (res.errorCode == 1) {
            alert(res.errorMessage);
          } else {
            alert(res.errorMessage);
          }
        }
      })
      .catch((e) => {
        console.log(e);
        alert(e);
      })
      .finally(() => {
        //何もしない
      });
  };

  /**
   * ニックネームを登録
   * @param userId ユーザID
   * @param nickname ニックネーム
   * @param iconFlag ニックネーム登録後にアイコンを登録するかどうか?
   */
  setNickname = (userId: any, nickname: any, iconFlag: Boolean) => {
    const headers = { "Content-Type": "application/json" };
    let data = {
      userId: userId,
      nickName: nickname,
    };

    AwsAmplifyUtils.callAPI()
      .then((api) => {
        return api.API.post(
          "api",
          api.apiPrefix + "/userinfomanager/updatenickname",
          {
            headers: headers,
            body: data,
          }
        );
      })
      .then(async (res) => {
        console.log(res);
        if (res.errorCode == 0) {
          // アイコンの登録をする
          if (iconFlag) {
            this.setUserIcon(userId, this.state.preview);
          } else {
            alert("登録が完了しました。");
            this.props.getUserInfo(userId);
            this.setState({
              trialDia: false,
            });
          }
        } else {
          if (res.errorCode == 1) {
            alert(res.errorMessage);
          } else {
            alert(res.errorMessage);
          }
        }
      })
      .catch((e) => {
        console.log(e);
        alert(e);
      })
      .finally(() => {
        //何もしない
      });
  };

  /**
   * 「写真を選択」時のプレビュー表示
   * @param e
   */
  onFileChange = (e: any) => {
    const files = e.target.files;

    if (files.length > 0) {
      var file = files[0];

      var reader = new FileReader();
      reader.onload = (e) => {
        var tmp = e.target && e.target.result ? e.target.result : null;
        this.setState({ preview: tmp });
      };
      reader.readAsDataURL(file);
    } else {
      this.setState({ preview: null });
    }
  };

  // ニックネームのonchange()メソッド
  changeNickname: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    this.setState({ inputNickName: e.target.value });
  };

  render(): React.ReactNode {
    // アイコン設定
    var iconSrc = null;
    var iconPreviewSrc = null;
    // プレビューのアイコンがあるなら反映
    if (this.state.preview != null && this.state.preview != "") {
      iconSrc = this.props.profile.icon;
      iconPreviewSrc = this.state.preview;
    } else {
      // ないなら、データベースに登録されているアイコンがあれば反映
      if (this.props.profile.icon != null && this.props.profile.icon != "") {
        iconSrc = this.props.profile.icon;
        iconPreviewSrc = this.props.profile.icon;
      } else {
        iconSrc = "";
        iconPreviewSrc = "";
      }
    }

    return (
      <div>
        <div className={profile.ProfileArea}>
          <div
            className={
              this.props.getData
                ? profile.UserIconDiv
                : profile.UserIconDiv + " " + profile.hidden
            }
          >
            <div
              className={
                iconSrc != ""
                  ? profile.dialogUserIcon + " " + profile.bcg_unset
                  : profile.dialogUserIcon
              }
            >
              <img
                className={iconSrc != "" ? profile.disp : profile.disp_none}
                src={iconSrc}
                alt="ユーザアイコン"
              />
              <div className={iconSrc != "" ? profile.disp_none : profile.disp}>
                <PersonIcon sx={{ color: "white", fontSize: 100 }}></PersonIcon>
              </div>
            </div>
            <div className={profile.userName}>{this.props.nicknameVal}さん</div>
          </div>
          {/**プロファイル */}
          <div className={profile.listArea}>
            <button onClick={() => this.trialDia(true)}>
              プロフィールの確認・変更
            </button>
            <button onClick={() => this.openURL("./passwordChange", true)}>
              パスワードの変更
            </button>
            <button onClick={() => this.openURL("./agreement", true)}>
              ご契約内容の確認・変更
            </button>
            <button
              onClick={() =>
                window.open(
                  CommonConfig.faq.url,
                  "FAQ"
                )
              }
            >
              <span lang="en">FAQ</span>・お問合せ
            </button>
            <button onClick={() => this.openURL("./logout", false)}>
              ログアウト
            </button>
          </div>
        </div>
        {/**　削除の確認ダイアログここ部品化要 */}
        <div>
          <Dialog
            open={this.state.trialDia}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"プロフィールの確認・変更"}
            </DialogTitle>
            <DialogContent>
              <div className={profile.dialogUserInfoArea}>
                <div className={profile.flex}>
                  <div className={profile.dialogInfoDiv}>
                    <div className={profile.dialogTitle}>サイト会員情報</div>
                    <div className={profile.dialogInfoArea}>
                      <div className={profile.nicknameDiv}>
                        <div className={profile.nicknameTitle}>
                          ニックネーム：
                        </div>
                        <div className={profile.nicknameInput}>
                          <input
                            type="text"
                            className={profile.nickname}
                            defaultValue={this.props.profile.nickname}
                            onChange={(e) => this.changeNickname(e)}
                          />
                        </div>
                      </div>
                      <div>アカウント登録日：{this.props.profile.date}</div>
                      <div>ご利用期間：{this.props.profile.duration}年</div>
                    </div>
                  </div>
                  <div className={profile.dialogUserIconDiv}>
                    <div
                      className={
                        iconPreviewSrc != ""
                          ? profile.dialogUserIcon + " " + profile.bcg_unset
                          : profile.dialogUserIcon
                      }
                    >
                      <img
                        className={
                          iconPreviewSrc != ""
                            ? profile.disp
                            : profile.disp_none
                        }
                        src={iconPreviewSrc}
                        alt="ユーザアイコン"
                      />
                      <div
                        className={
                          iconPreviewSrc != ""
                            ? profile.disp_none
                            : profile.disp
                        }
                      >
                        <PersonIcon
                          sx={{ color: "white", fontSize: 100 }}
                        ></PersonIcon>
                      </div>
                    </div>
                    <div>
                      <input
                        className={profile.disp_none}
                        accept="image/*"
                        id="contained-button-file"
                        multiple
                        type="file"
                        onChange={(e) => this.onFileChange(e)}
                      />
                      <Button
                        variant="contained"
                        component="span"
                        startIcon={<PhotoCameraIcon />}
                        onClick={() => this.clickIconBtn()}
                      >
                        写真を選択
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <div className={profile.dialogDateArea}></div>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                component="span"
                onClick={() => this.set()}
              >
                登録
              </Button>
              <Button onClick={() => this.trialDia(false)}>閉じる</Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    );
  }
}

export default Profile;
