import React from "react";
import PersonIcon from "@mui/icons-material/Person";
import styles from "./Service.module.scss";
import IZ from "./icon/異常兆候分析.svg";
import ST from "./icon/最適運転分析豚モノクロ_土赤茶色.svg";
import DS from "./icon/デジタル書庫.svg";
import { AwsAmplifyUtils } from "../../common/components/AwsAmplifyUtils";

const explanation = {
  apr: "AI分析によりアナログ信号の'いつもと違う(異常兆候)'を検出します。",
  ems: "発電プラントの運転実績　　データを分析し、最経済と　なる運用を提示します。",
  digital:
    "論文や報告書などの各種　　ドキュメント情報の蓄積・　検索が可能です。",
};
const SERVICE_APR = 1;
const SERVICE_EMS = 2;
const SERVICE_DIGITAL = 3;
export class Service extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      apr: {
        contracted: false,
      },
      ems: {
        contracted: false,
      },
      digital: {
        contracted: false,
      },
    };
  }

  openURL = (url: string) => {
    window.open(url, "_blank");
  };
  openTest = () => {
    alert("coming soon");
  };
  sideMenuClose = () => {
    let f = this.state.close;
    this.setState({ close: !f });
  };

  componentDidMount() {
    // configure
    AwsAmplifyUtils.configure();

    // 契約ID（=cognitoグループ名称)
    AwsAmplifyUtils.getCognitoGroup().then((value) => {
      console.log(value);
      // 契約情報を取得
      this.getContractInfo(value);
    });
  }

  /**
   * 契約情報を取得
   * @param contractId 契約ID（=cognitoグループ名称)
   */
  getContractInfo = (contractId: any) => {
    const headers = { "Content-Type": "application/json" };
    let data = {
      contractId: contractId,
    };

    AwsAmplifyUtils.callAPI()
      .then((api) => {
        return api.API.post(
          "api",
          api.apiPrefix + "/contractmanager/getcontractinfo",
          {
            headers: headers,
            body: data,
          }
        );
      })
      .then(async (res) => {
        console.log(res);
        if (res.errorCode == 0) {
          var obj = {
            apr: { contractId: false },
            ems: { contractId: false },
            digital: { contractId: false },
          } as any;
          for (var i in res.data) {
            if (res.data[i].serviceId == SERVICE_APR) {
              obj = {
                ...obj,
                apr: {
                  contracted: true,
                  date: res.data[i].contractDate,
                  plan: res.data[i].planName,
                  count: res.data[i].useCount - res.data[i].usedCount,
                  availableDays: res.data[i].availableDays,
                },
              };
            } else if (res.data[i].serviceId == SERVICE_EMS) {
              obj = {
                ...obj,
                ems: {
                  contracted: true,
                  date: res.data[i].contractDate,
                  plan: res.data[i].planName,
                  count: res.data[i].useCount - res.data[i].usedCount,
                  availableDays: res.data[i].availableDays,
                },
              };
            } else if (res.data[i].serviceId == SERVICE_DIGITAL) {
              obj = {
                ...obj,
                digital: {
                  contracted: true,
                  date: res.data[i].contractDate,
                  plan: res.data[i].planName,
                  count: res.data[i].useCount - res.data[i].usedCount,
                  availableDays: res.data[i].availableDays,
                },
              };
            }
          }

          // state更新
          this.setState({
            apr: obj.apr,
            ems: obj.ems,
            digital: obj.digital,
          });
        } else {
          if (res.errorCode == 1) {
            alert(res.errorMessage);
          } else {
            alert(res.errorMessage);
          }
        }
      })
      .catch((e) => {
        console.log(e);
        alert(e);
      })
      .finally(() => {
        //何もしない
      });
  };

  render(): React.ReactNode {
    // 契約済みのサービス
    var contApr;
    var contEms;
    var contDigital;
    var contDisp = false;

    /* おすすめのサービス*/
    /*
    var recomApr;
    var recomEms;
    var recomDigital;
    */

    // 異常兆候分析
    if (this.state.apr.contracted) {
      // 契約済みのサービス
      contApr = (
        <ContractedService
          title="異常兆候分析"
          serviceUrl="./service/apr/"
          serviceTarget="serviceApr"
          img={IZ}
          plan={this.state.apr.plan}
          count={this.state.apr.count}
          usehref={"./service/apr/description#aprUse"}
          useTarget={"descriptionApr"}
        />
      );
      contDisp = true;
    } else {
      // おすすめのサービス
      /*
      recomApr = (
        <RecomService
          title="異常兆候分析"
          url="./service/apr/description"
          urlTarget="descriptionApr"
          img={IZ}
          str={explanation.apr}
        />
      );
      */
    }

    // 最適運転分析
    if (this.state.ems.contracted) {
      contDisp = true;
    } else {
    }
    // デジタル書庫
    if (this.state.digital.contracted) {
      contDisp = true;
    } else {
    }

    return (
      <div className={styles.ServiceArea}>
        {/**契約済みサービスエリア */}
        <div
          className={
            contDisp
              ? styles.contractedArea
              : styles.contractedArea + " " + styles.disp_none
          }
        >
          <div className={styles.contractedDiv}>
            <div className={styles.title}>ご契約済みのサービス</div>
            <div className={styles.comment}>
              以下の項目よりご利用のサービスを選択頂けます。
            </div>
            <div className={styles.cardArea}>
              {contApr}
              {contEms}
              {contDigital}
            </div>
          </div>
        </div>
        {/* インターバル
        }
        <div className={styles.interval}></div>
        {/* おすすめのサービス}
        <div className={styles.contractedArea}>
          <div className={styles.contractedDiv}>
            <div className={styles.title}>おすすめのサービス</div>
            <div className={styles.comment}>
              {this.props.nicknameVal}
              さん　と同じサービスをご契約中のアカウントは、以下のサービスもあわせて利用しています。
            </div>
            <div className={styles.cardArea}>
              {recomApr}
              {recomEms}
              {recomDigital}
            </div>
          </div>
            </div>
            */}
      </div>
    );
  }
}

export default Service;
/**
 * ご契約済みのサービス
 */
export class ContractedService extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  openURL = (url: string, target: string) => {
    window.open(url, target);
  };

  render(): React.ReactNode {
    return (
      <div className={styles.cardArea}>
        <div className={styles.card} >
          <div className={styles.clickArea} onClick={() =>
            this.openURL(this.props.serviceUrl, this.props.serviceTarget)
          }>
            <div className={styles.cardTitle}>{this.props.title}</div>
            <div className={styles.cardImg}>
              {/*イメージ図*/}
              <img
                className={styles.img}
                src={this.props.img}
                alt="サービスアイコン"
              ></img>
            </div>
            <div className={styles.cardInfo}>
              <div>
                <div className={styles.planDiv_left}>契約プラン：</div>
                <div className={styles.planDiv_right}>{this.props.plan}</div>
              </div>
              <div>残り分析回数：{this.props.count}回</div>
            </div>
            <div className={styles.line}></div>
          </div>
          <div className={styles.help}>
            <div>
              使い方は
              <a href={this.props.usehref} target={this.props.useTarget}>
                こちら
              </a>
            </div>
            <div>
              契約変更・更新は
              <a href="./agreement" target="agreement">
                こちら
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
/**
 * おすすめのサービス
 */
export class RecomService extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  openURL = (url: string, target: string) => {
    window.open(url, target);
  };

  render(): React.ReactNode {
    return (
      <div className={styles.card}>
        <div className={styles.cardTitle}>{this.props.title}</div>
        <div
          className={styles.cardImg}
          onClick={() => this.openURL(this.props.url, this.props.urlTarget)}
        >
          {/*イメージ図*/}
          <img
            className={styles.img}
            src={this.props.img}
            alt="サービスアイコン"
          ></img>
        </div>
        <div className={styles.intoro}>{this.props.str}</div>
      </div>
    );
  }
}
