/*
 * FileName : index.tsx
 * Function : view
 * Designed : Morimoto.Hiroshi(SEQ)
 * Created  : Morimoto.Hiroshi(SEQ)
 * Date     : 2021/01/22
 * Version  : 1.0
 * COPYRIGHT (C) 2021 MITSUBISHI ELECTRIC CORPORATION ALL RIGHTS RESERVED
 */
import React from "react";
import ReactDom from "react-dom";

import { App } from "./App";

ReactDom.render(<App />, document.getElementById("root"));
