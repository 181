import CircularProgress from "@mui/material/CircularProgress";
import React from "react";
import ReactDOM from "react-dom";
import { useNavigate } from "react-router-dom";
import {
  AwsAmplifyUtils,
  AWS_TOKEN_SAVE,
} from "../common/components/AwsAmplifyUtils";
import { BreadcrumbHeader } from "../common/components/BreadcrumbHeader";
import CommonConfig from "../common/config/config.json";
import InputEvent from "../common/utility/InputEvent";
import styles from "./Login.module.scss";

export interface IUploadViewProps {
  status: number;
  breadcrumb: {};
  formError: Boolean;
  formNewPasswordError: Boolean;
  formConfirmPasswordError: Boolean;
  formCompleteInvalidError: Boolean;
  formCompleteLimitExceededError: Boolean;
  formCompleteUnknownError: Boolean;
  username: string;
  password: string;
  saveLogin: boolean;
  reUrl: string;
  newPassword: string;
  confirmPassword: string;
}

// hookを使用するためクラスを関数でラップ
export default function Login(props: any) {
  // useNavigationを取得し、クラスにnavigationを渡す
  const navigate = useNavigate();

  return <LoginClass {...props} navigate={navigate} />;
}

export class LoginClass extends React.Component<any, IUploadViewProps> {
  constructor(props: any) {
    super(props);
    this.state = {
      status: 12,
      breadcrumb: [
        { name: "ANALYSIS PORTAL <X-chAIn>紹介ページ", href: "../" },
        { name: "ログインページ", href: "/" },
      ],
      formError: false,
      formNewPasswordError: false,
      formConfirmPasswordError: false,
      formCompleteInvalidError: false,
      formCompleteLimitExceededError: false,
      formCompleteUnknownError: false,
      username: "",
      password: "",
      saveLogin: this.getLoginStatusFlag(),
      reUrl: this.getUrlparam("page"),
      newPassword: "",
      confirmPassword: "",
    };
    this.handleUserNameChanged = this.handleUserNameChanged.bind(this);
    this.handlePasswordChanged = this.handlePasswordChanged.bind(this);
    this.handleNewPasswordChanged = this.handleNewPasswordChanged.bind(this);
    this.handleConfirmPasswordChanged =
      this.handleConfirmPasswordChanged.bind(this);
    this.handleLoggedChanged = this.handleLoggedChanged.bind(this);
    this.login = this.login.bind(this);

    AwsAmplifyUtils.configure();
  }

  /**
   * ログイン状態記憶するかどうかのフラグ取得
   * @returns ログイン状態記憶するかどうか（true：記憶する）
   */
  getLoginStatusFlag() {
    // ログイン状態はローカルストレージから取り出す
    let saveLoginConfig = AwsAmplifyUtils.getAuthStorageType();
    let isSave = false;
    if (saveLoginConfig === AWS_TOKEN_SAVE.LOCAL_STORAGE) {
      isSave = true;
    }
    return isSave;
  }

  /**
   * キー押下処理
   *
   * @param e キーイベント
   */
  keyDown(e: any) {
    if (InputEvent.checkClickEnter(e)) {
      // ログイン処理
      this.login();
    }
  }

  /**
   * ログインボタン押下時処理
   */
  login() {
    const ele = <CircularProgress className={styles.login_loading} />;
    ReactDOM.render(ele, document.getElementById("loadingIcon"));

    if (this.state.saveLogin) {
      AwsAmplifyUtils.setAuthStorageType(AWS_TOKEN_SAVE.LOCAL_STORAGE);
    } else {
      AwsAmplifyUtils.setAuthStorageType(AWS_TOKEN_SAVE.SESSION_STORAGE);
    }
    // ログイン
    AwsAmplifyUtils.signIn(this.state.username, this.state.password)
      .then((user) => {
        // ユーザ情報設定
        this.props.setUserInfo(user);

        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          // 初回パスワード設定エリアを表示
          this.props.navigate("/passwordComplete", {
            state: { userId: this.state.username },
          });
        } else {
          window.location.href = this.state.reUrl;
        }
      })
      .catch((error) => {
        console.log("signIn error", error);
        ReactDOM.render(<></>, document.getElementById("loadingIcon"));
        // ログインエラーメッセージを表示
        this.setState({ formError: true });
      });
  }

  account() {
    window.location.href = "../entry";
  }

  /**
   * ユーザ名欄入力内容変更時処理
   * @param e
   */
  handleUserNameChanged(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({
      username: e.target.value,
    });
  }

  /**
   * パスワード欄入力内容変更時処理
   * @param e
   */
  handlePasswordChanged(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({
      password: e.target.value,
    });
  }

  /**
   * 新しいパスワード欄入力内容変更時処理
   * @param e
   */
  handleNewPasswordChanged(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({
      newPassword: e.target.value,
    });
  }

  /**
   * 新しいパスワード（確認）欄入力内容変更時処理
   * @param e
   */
  handleConfirmPasswordChanged(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({
      confirmPassword: e.target.value,
    });
  }

  /**
   * ログイン継続ボタン押下時処理
   * @param e
   */
  handleLoggedChanged(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({
      saveLogin: e.target.checked,
    });
  }

  /**
   * 会員ID確認ボタン効果時処理
   */
  handleCheckMemberIdClicked() {
    window.open(CommonConfig.inquiry.url, "_blank");
  }

  /**
   * パスワード初期化ボタン効果時処理
   */
  handleInitPasswordClicked() {
    window.open("./resetpasswordquestion", "_blank");
  }

  /**
   * 予約実装部品向けの処理
   * @returns 常にfalse
   */
  handleReservedFunctionClicked() {
    alert("coming soon.");
    return false;
  }

  getUrlparam = (name: string) => {
    let resData = "/";
    // ?移行を取得
    let param = window.location.search;
    if (param != "") {
      // 1文字目(?)を削除する
      param = param.slice(1);
      // &で分割する
      let list1 = param.split("&");
      // 分割したリストから該当するパラメータ名を見つける
      for (let i in list1) {
        // =で分割する
        let tmp = list1[i].split("=");
        // 変数名が一致すれば
        if (tmp.length == 2 && tmp[0] == name) {
          resData = tmp[1];
        }
      }
    }
    return resData;
  };
  render() {
    return (
      <div
        style={{
          fontSize: "16px !important",
          height: "100%",
          maxWidth: "1440px !important",
          margin: "0 auto",
          textAlign: "center",
        }}
      >
        {/*パンクズリスト className={}*/}
        <div>
          <BreadcrumbHeader
            breadcrumb={this.state.breadcrumb}
          ></BreadcrumbHeader>
        </div>
        <div className={styles.login_div_outside}>
          <div className={styles.login_title}>ログイン</div>
          <div className={styles.login_menu_div}>
            <div className={styles.login_menu_title}>
              三菱電機
              <span lang="en">
                ANALYSIS PORTAL&nbsp;&lt;X-chAIn&gt;
              </span>
              サイト会員
              にご登録済のお客様はこちらからログインしてください。
            </div>
            <table className={styles.login_menu_table}>
              <tbody>
                <tr>
                  <td className={styles.login_menu_td_left}>
                    会員
                    <span lang="en">ID</span>
                  </td>
                  <td className={styles.login_menu_td_right}>
                    <div
                      className={
                        this.state.formError ? styles.error : styles.errorNone
                      }
                    >
                      会員
                      <span lang="en">ID</span>
                      もしくはパスワードに間違いがないか今一度お確かめ下さい。
                    </div>
                    <input
                      type="text"
                      onChange={this.handleUserNameChanged}
                      onKeyDown={(e) => this.keyDown(e)}
                    ></input>
                  </td>
                </tr>
                <tr>
                  <td className={styles.login_menu_td_left}>パスワード</td>
                  <td className={styles.login_menu_td_right}>
                    <input
                      type="password"
                      onChange={this.handlePasswordChanged}
                      onKeyDown={(e) => this.keyDown(e)}
                    ></input>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className={styles.login_menu_checkbox}>
              <input
                type="checkbox"
                onChange={this.handleLoggedChanged}
                checked={this.state.saveLogin}
              ></input>
              <label>ブラウザを閉じてもログインを継続する</label>
            </div>
            <div className={styles.login_menu_button}>
              <input
                type="button"
                value="ログイン"
                onClick={this.login}
              ></input>
            </div>
            <div className={styles.login_menu_line}></div>
            <div className={styles.login_menu_id}>
              <div className={styles.login_menu_id_title}>
                会員
                <span lang="en">
                  ID
                </span>
                を忘れた方はこちらお問い合わせください。
              </div>
              <div className={styles.login_menu_id_label}>
                <a
                  className={styles.c_bulletArrow_style01}
                  href={"javascript:void(0)"}
                  onClick={this.handleCheckMemberIdClicked}
                >
                  お問い合わせフォームページ
                </a>
              </div>
            </div>
            <div className={styles.login_menu_pass}>
              <div className={styles.login_menu_pass_title}>
                パスワードを忘れた方はこちらから初期化をしてください。
              </div>
              <div className={styles.login_menu_pass_label}>
                <a
                  className={styles.c_bulletArrow_style01}
                  href={"javascript:void(0)"}
                  onClick={this.handleInitPasswordClicked}
                >
                  パスワード初期化ページ
                </a>
              </div>
            </div>
          </div>
          <div className={styles.login_account} onClick={this.account}>
            <input type="button" value="サイト会員申し込み"></input>
          </div>
        </div>
        <div id="loadingIcon"></div>
      </div>
    );
  }
}
