import React from "react";
import styles from "./Agreement.module.scss";
import Button from "@mui/material/Button";
import { AwsAmplifyUtils } from "../../common/components/AwsAmplifyUtils";
import CommonConfig from "../../common/config/config.json";

const SERVICE_APR = 1;
const SERVICE_EMS = 2;
const SERVICE_DIGITAL = 3;
export class Agreement extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      apr: {
        contracted: false,
      },
      ems: {
        contracted: false,
      },
      digital: {
        contracted: false,
      },
    };
  }
  openURL = (url: string) => {
    window.open(url, "_blank");
  };
  openTest = () => {
    alert("coming soon");
  };
  closeTest = () => {
    window.close();
  };

  componentDidMount() {
    // configure
    AwsAmplifyUtils.configure();
    // 契約ID（=cognitoグループ名称)
    AwsAmplifyUtils.getCognitoGroup().then((value) => {
      // 契約情報を取得
      this.getContractInfo(value);
    });
  }

  /**
   * ご利用期間を計算
   * @param resistDate
   */
  calcDuration = (resistDate: any, availableDays: any) => {
    // ページを読み込んだ日時=現在日時
    var loadDate = new Date();
    // 契約日
    var distDate = new Date(resistDate);

    // 利用可能日時を加算
    distDate.setDate(distDate.getDate() + availableDays);

    // 日時の差を取得
    var diffMilliSec = distDate.getTime() - loadDate.getTime();

    //　何日に変換
    var diffDays = Math.floor(diffMilliSec / 1000 / 60 / 60 / 24);
    return diffDays;
  };

  /**
   * 契約情報を取得
   * @param contractId 契約ID（=cognitoグループ名称)
   */
  getContractInfo = (contractId: any) => {
    const headers = { "Content-Type": "application/json" };
    let data = {
      contractId: contractId,
    };

    AwsAmplifyUtils.callAPI()
      .then((api) => {
        return api.API.post(
          "api",
          api.apiPrefix + "/contractmanager/getcontractinfo",
          {
            headers: headers,
            body: data,
          }
        );
      })
      .then(async (res) => {
        console.log(res);
        if (res.errorCode == 0) {
          var obj = {
            apr: { contractId: false },
            ems: { contractId: false },
            digital: { contractId: false },
          } as any;
          for (var i in res.data) {
            if (res.data[i].serviceId == SERVICE_APR) {
              obj = {
                ...obj,
                apr: {
                  contracted: true,
                  date: res.data[i].contractDate,
                  plan: res.data[i].planName,
                  count: res.data[i].useCount - res.data[i].usedCount,
                  availableDays: this.calcDuration(
                    res.data[i].contractDate,
                    res.data[i].availableDays
                  ),
                },
              };
            } else if (res.data[i].serviceId == SERVICE_EMS) {
              obj = {
                ...obj,
                ems: {
                  contracted: true,
                  date: res.data[i].contractDate,
                  plan: res.data[i].planName,
                  count: res.data[i].useCount - res.data[i].usedCount,
                  availableDays: this.calcDuration(
                    res.data[i].contractDate,
                    res.data[i].availableDays
                  ),
                },
              };
            } else if (res.data[i].serviceId == SERVICE_DIGITAL) {
              obj = {
                ...obj,
                digital: {
                  contracted: true,
                  date: res.data[i].contractDate,
                  plan: res.data[i].planName,
                  count: res.data[i].useCount - res.data[i].usedCount,
                  availableDays: this.calcDuration(
                    res.data[i].contractDate,
                    res.data[i].availableDays
                  ),
                },
              };
            }
          }

          // state更新
          this.setState({
            apr: obj.apr,
            ems: obj.ems,
            digital: obj.digital,
          });
        } else {
          if (res.errorCode == 1) {
            alert(res.errorMessage);
          } else {
            alert(res.errorMessage);
          }
        }
      })
      .catch((e) => {
        console.log(e);
        alert(e);
      })
      .finally(() => {
        //何もしない
      });
  };

  render(): React.ReactNode {
    // 契約済みのサービス
    var contApr;
    var contEms;
    var contDigital;

    // 異常兆候分析
    if (this.state.apr.contracted) {
      // 契約済みのサービス
      contApr = (
        <AgreementService
          title="異常兆候分析サービス"
          date={this.state.apr.date}
          plan={this.state.apr.plan}
          count={this.state.apr.count}
          days={this.state.apr.availableDays}
        />
      );
    }

    // 最適運転分析
    if (this.state.ems.contracted) {
    } else {
    }
    // デジタル書庫
    if (this.state.digital.contracted) {
    } else {
    }

    return (
      <div className={styles.area}>
        <div>
          <div className={styles.title}>ご契約中サービス</div>
          {contApr}
          {contEms}
          {contDigital}
        </div>

        <div className={styles.closeButoonArea}>
          <Button onClick={() => this.closeTest()} variant="contained">
            閉じる
          </Button>
        </div>
      </div>
    );
  }
}

export default Agreement;

/**
 * ご契約済みのサービス
 */
export class AgreementService extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  openTest = () => {
    alert("coming soon");
  };

  openInquiry = () => {
    window.location.href = CommonConfig.inquiry.url;
  }

  render(): React.ReactNode {
    return (
      <div>
        <div className={styles.row}>
          <div className={styles.cell1}>{this.props.title}</div>
          <div className={styles.cell2}></div>
          <div className={styles.cell3}>ご契約日：{this.props.date}</div>
          <div className={styles.cell4}></div>
        </div>
        <div className={styles.row}>
          <div className={styles.cell1}>　{this.props.plan}</div>
          <div className={styles.cell2}>
            <label className={styles.remain_left}>残り分析回数：</label>
            <label className={styles.remain_right}>{this.props.count}回</label>
          </div>
          <div className={styles.cell3}></div>
          <div className={styles.cell4}></div>
        </div>
        <div className={styles.row}>
          <div className={styles.cell1}></div>
          <div className={styles.cell2}>
            <label className={styles.remain_left}>残り期間：</label>
            <label className={styles.remain_right}>{this.props.days}日</label>
          </div>
          <div className={styles.cell3}></div>
          <div className={styles.row + " " + styles.cell4}>
            <Button onClick={() => this.openInquiry()} variant="contained">
              更新
            </Button>
          </div>
        </div>
        <div className={styles.line}></div>
      </div>
    );
  }
}
