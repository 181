/// テーブル用の
/*import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";*/
import BarChartIcon from "@mui/icons-material/BarChart";
import DeleteIcon from "@mui/icons-material/Delete";
import DoneIcon from "@mui/icons-material/Done";
import ErrorIcon from "@mui/icons-material/Error";
import HelpIcon from "@mui/icons-material/Help";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import {
  Alert,
  FormControl,
  InputLabel,
  Link,
  MenuItem,
  Radio,
  Select,
} from "@mui/material";
import TextField from "@mui/material/TextField";
/// テーブル用の
/*import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";*/
import { Grid, _ } from "gridjs-react";
import "gridjs/dist/theme/mermaid.css";
import React from "react";
import Dropzone from "react-dropzone";
import ContentHederTitle from "../../ContentHederTitle";
import "./AnalysisModelPage.css";
import styles from "./AnalysisModelPage.module.scss";

type Props = {};

/**
 * sortA: true=降順(最新が一番↑)
 */
interface AnalysisModelPageState {
  dragOver: boolean;
  files: Array<any>;
  files2: Array<any>;
  demoError: boolean;
  csvUser: boolean;
}
//<Props,State>

export class AnalysisModelPage extends React.Component<
  any,
  AnalysisModelPageState
> {
  constructor(props: any) {
    super(props); // stateを初期化
    this.state = {
      dragOver: false,
      files: new Array(),
      files2: new Array(),
      demoError: false,
      csvUser: true,
    };
  }
  changeRadio(f: boolean) {
    this.setState({
      csvUser: f,
    });
  }
  demoError = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    this.setState({
      demoError: !this.state.demoError,
    });
  };

  createError() {
    let res = (
      <div>
        <Alert
          sx={{ paddingTop: "0px", paddingBottom: "0px" }}
          severity="error"
        >
          タイトルが不正です。
        </Alert>
        <Alert
          sx={{ paddingTop: "0px", paddingBottom: "0px" }}
          severity="error"
        >
          学習データが未入力です。
        </Alert>
      </div>
    );
    return res;
  }
  returnTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  createColums() {
    let hedItem = [
      { name: "No.", width: "40px" },
      { name: "ファイル名", width: "380px" },
      { name: "アップロード日時", width: "200px" },
      { name: "データ期間", width: "295px" },
      { name: "", width: "40px" },
      { name: "", width: "40px" },
    ];
    return hedItem;
  }
  createColums2() {
    let hedItem = [
      { name: "No.", width: "40px" },
      { name: "ファイル名", width: "380px" },
      { name: "アップロード日時", width: "200px" },
      { name: "グループ数", width: "295px" },
      { name: "", width: "40px" },
      { name: "", width: "40px" },
    ];
    return hedItem;
  }
  createRows() {
    let state = new Array();
    state = this.state.files;
    let Data = new Array();
    for (let index: number = 0; index < state.length; index++) {
      let a = (
        <div>
          <ErrorIcon
            sx={{ color: "red" }}
            className={styles.moreIcon}
          ></ErrorIcon>
        </div>
      );
      if (index == 1) {
        a = (
          <div>
            <DoneIcon
              sx={{ color: "green" }}
              className={styles.moreIcon}
            ></DoneIcon>
          </div>
        );
      }
      Data.push([
        index + 1,
        state[index].name,
        "2022/0/18 14:19:58",
        "yyyy/mm/dd　～　yyyy/mm/dd",
        _(a),
        _(
          <div>
            <DeleteIcon
              sx={{ color: "gray" }}
              className={styles.icon}
            //onClick={(event) => this.ttt()}
            ></DeleteIcon>
          </div>
        ),
        ,
      ]);
    }
    return Data;
  }
  createRows2() {
    let state = new Array();
    state = this.state.files2;
    let Data = new Array();
    for (let index: number = 0; index < state.length; index++) {
      let a = (
        <div>
          <ErrorIcon
            sx={{ color: "red" }}
            className={styles.moreIcon}
          ></ErrorIcon>
        </div>
      );
      if (index == 1) {
        a = (
          <div>
            <DoneIcon
              sx={{ color: "green" }}
              className={styles.moreIcon}
            ></DoneIcon>
          </div>
        );
      }
      Data.push([
        index + 1,
        state[index].name,
        "2022/0/18 14:19:58",
        12 + index,
        _(a),
        _(
          <div>
            <DeleteIcon
              sx={{ color: "gray" }}
              className={styles.icon}
            //onClick={(event) => this.ttt()}
            ></DeleteIcon>
          </div>
        ),
        ,
      ]);
    }
    return Data;
  }
  fileGet(file: any) {
    let list = this.state.files;
    for (let index: number = 0; index < file.length; index++) {
      list.push({
        name: file[index].name,
      });
    }
    this.setState({
      dragOver: false,
      files: list,
    });
  }
  fileGet2(file: any) {
    let list = this.state.files2;
    for (let index: number = 0; index < file.length; index++) {
      list.push({
        name: file[index].name,
      });
    }
    this.setState({
      dragOver: false,
      files2: list,
    });
  }
  dragLeave() {
    this.setState({
      dragOver: false,
    });
  }
  dragOver() {
    this.setState({
      dragOver: true,
    });
  }
  render(): React.ReactNode {
    let active = "";
    if (this.state.dragOver) {
      active = styles.dropZoneActive;
    }
    let columns = this.createColums();
    let rows = this.createRows();
    let columns2 = this.createColums2();
    let rows2 = this.createRows2();

    let clickDataFormDownload = (event: any) => {
      alert("comming soon");
    };

    // デモ用のエラーデータ
    let errorDiv;
    if (this.state.demoError) {
      errorDiv = this.createError();
    }
    return (
      <div className={styles.AnalysisDiv}>
        <div className={styles.ReportPage}>
          <ContentHederTitle
            title={"異常兆候分析サービス"}
            icon={BarChartIcon}
          ></ContentHederTitle>
        </div>
        <div className={styles.ErrorDiv}>{errorDiv}</div>
        <div className={styles.SetteingDiv}>
          {/**タイトルエリア */}
          <div className={styles.SettingTitleDiv}>
            <div className={styles.SettingTitle}>基本情報</div>
            <div className={styles.SettingTitleComeent}>
              レポートのタイトルを入力します。
            </div>
            <div className={styles.interval_before}></div>
            <div className={styles.inputTitleArea}>
              <div className={styles.lerningCsvTileDiv}>
                <div>タイトル</div>
                <HelpIcon sx={{ color: "gray" }}></HelpIcon>
                <div className={styles.required_icon}>必須</div>
              </div>
              <TextField
                size="small"
                style={{ width: "350px", background: "white" }}
                variant="outlined"
                defaultValue=""
              /*helperText="Incorrect entry."*/
              ></TextField>
            </div>
            <div className={styles.interval}></div>
            <div className={styles.inputBaseArea}>
              <div className={styles.inputTitleArea}>
                <div className={styles.lerningCsvTileDiv}>
                  <div>選択モデル</div>
                  <HelpIcon sx={{ color: "gray" }}></HelpIcon>
                </div>
                <div>
                  <FormControl
                    fullWidth
                    className={styles.changeModelDiv}
                    style={{ width: "265px" }}
                  >
                    <InputLabel id="demo-simple-select-label"></InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={10}
                    >
                      <MenuItem value={10}>分析環境1</MenuItem>
                      <MenuItem value={20}>分析環境2</MenuItem>
                      <MenuItem value={30}>分析環境3</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className={styles.modelInfoArea}>
                <div>関連環境:　分析環境1</div>
                <div className={styles.modelInfoState}>
                  状態:
                  <div>
                    <DoneIcon sx={{ color: "green" }} />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.interval_after}></div>
          </div>

          {/**csvデータアップロードエリア */}
          <div className={styles.SettingTitleDiv}>
            <div className={styles.SettingTitle}>データアップロード</div>
            <div className={styles.SettingTitleComeent}>
              異常兆候分析のインプットとなる分析データをアップロードします。
              <a className={styles.link} href="./format/sample.csv" download>データフォームダウンロード</a>
            </div>
            <div className={styles.interval_before}></div>
            {/**ラジオボタン1 */}
            <div className={styles.radioArea}>
              <Radio
                onChange={() => this.changeRadio(true)}
                checked={this.state.csvUser}
                name="raido-buttons"
                value="csv"
              ></Radio>
              <div className={styles.raidoText}>CSVアップロード</div>
              <div className={styles.raidoIcon}>
                <HelpIcon sx={{ color: "gray" }}></HelpIcon>
              </div>
            </div>

            {/**　学習用データ */}
            <div className={styles.lerningCsvArea}>
              <div className={styles.lerningCsvTileDiv}>
                <div>学習用データ</div>
                <HelpIcon sx={{ color: "gray" }}></HelpIcon>
                <div className={styles.required_icon}>必須</div>
              </div>
              <Dropzone
                onDrop={
                  (acceptedFiles) =>
                    this.fileGet(acceptedFiles) /*console.log(acceptedFiles)*/
                }
                onDragOver={(event) => this.dragOver()}
                onDragLeave={(event) => this.dragLeave()}
                disabled={!this.state.csvUser}
              >
                {({ getRootProps, getInputProps }) => (
                  <section className={styles.dropZone + " " + active}>
                    <div className={styles.maxSize} {...getRootProps()}>
                      <input {...getInputProps()} />
                      <p className={styles.dropZoneComment + " " + styles.maxSize} >
                        ここに指定のフィルをドロップ or
                        クリックでファイルを指定してください。
                      </p>
                    </div>
                  </section>
                )}
              </Dropzone>
            </div>
            <div className={styles.tableDiv}>
              <Grid sort={false} data={rows} columns={columns} />
            </div>
            <div className={styles.interval}></div>
            {/**ラジオボタン2 */}
            <div className={styles.radioArea}>
              <Radio
                onChange={() => this.changeRadio(false)}
                checked={!this.state.csvUser}
                name="raido-buttons"
                value="csv"
              ></Radio>
              <div className={styles.raidoText}>DB保存データを仕様</div>
              <div className={styles.raidoIcon}>
                <HelpIcon sx={{ color: "gray" }}></HelpIcon>
              </div>
            </div>
            {/**　使用環境情報 */}
            <div className={styles.userDBStateArea}>
              <div>使用環境:分析環境1</div>
            </div>
            <div className={styles.interval_after}></div>
          </div>
          {/**分析期間エリア */}
          <div className={styles.SettingTitleDiv}>
            <div className={styles.SettingTitle}>分析期間設定</div>
            <div className={styles.SettingTitleComeent}>
              異常兆候分析の分析期間を設定します。
            </div>
          </div>
          <div className={styles.interval_before}></div>
          {/**分析期間のカレンダー */}
          <div className={styles.interval_after}>
            <div className={styles.lerningCsvTileDiv}>
              <div>分析期間</div>
              <HelpIcon sx={{ color: "gray" }}></HelpIcon>
              <div className={styles.required_icon}>必須</div>
            </div>
            <div className={styles.calendarArea}>
              <TextField
                size="small"
                id="date"
                label="開始日"
                type="date"
                defaultValue=""
                sx={{ width: 220 }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <div className={styles.calendarSpace}>~</div>
              <TextField
                size="small"
                id="date"
                label="終了日"
                type="date"
                defaultValue=""
                sx={{ width: 220 }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
          </div>
          <div className={styles.interval_after}></div>
          {/**条件設定エリア */}
          <div className={styles.SettingTitleDiv}>
            <div className={styles.SettingTitle}>条件設定</div>
            <div className={styles.SettingTitleComeent}>
              設定データを追加することで、より精度の高い異常兆候分析が可能です。
              <Link
                className={styles.link}
                onClick={clickDataFormDownload}
              >
                データフォームダウンロード
              </Link>
            </div>
            <div className={styles.interval_before}></div>
            {/**　設定データ */}
            <div className={styles.lerningCsvArea}>
              <div className={styles.lerningCsvTileDiv}>
                <div>設定データ</div>
                <HelpIcon sx={{ color: "gray" }}></HelpIcon>
              </div>
              <Dropzone
                onDrop={
                  (acceptedFiles) =>
                    this.fileGet2(acceptedFiles) /*console.log(acceptedFiles)*/
                }
                onDragOver={(event) => this.dragOver()}
                onDragLeave={(event) => this.dragLeave()}
              >
                {({ getRootProps, getInputProps }) => (
                  <section className={styles.dropZone + " " + active}>
                    <div {...getRootProps()} className={styles.maxSize}>
                      <input {...getInputProps()} />
                      <p className={styles.dropZoneComment + " " + styles.maxSize}>
                        ここに指定のフィルをドロップ or
                        クリックでファイルを指定してください。
                      </p>
                    </div>
                  </section>
                )}
              </Dropzone>
            </div>
            <div className={styles.tableDiv}>
              <Grid sort={false} data={rows2} columns={columns2} />
            </div>
            <div className={styles.interval_after}></div>
          </div>
        </div>
        {/**ページトップ */}
        <div className={styles.pageTopDiv} onClick={this.returnTop}>
          <div>▲</div>
          <div>PAGE TOP</div>
        </div>
        {/**分析開始ボタン */}
        <div className={styles.addButonArea}>
          <button className={styles.addButon}>分析を開始</button>
        </div>
        <div className={styles.interval_after}></div>
        {/**分析開始ボタン　動的のやつ */}
        <div className={styles.StartMoveDiv} onClick={this.demoError}>
          <div className={styles.StartMoveDivIcon}>
            <ShowChartIcon
              className={styles.StartMoveIcon}
              sx={{
                fontSize: "46px",
                color: "white",
                top: "5px",
                position: "relative",
              }}
            ></ShowChartIcon>
            <div className={styles.sideButton}>分析開始</div>
          </div>
          {/*<div className={styles.StartMoveText}>分析を開始</div>*/}
        </div>
      </div>
    );
  }
}

export default AnalysisModelPage;
