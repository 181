import React from "react";
import MainServices from "../common/config/MainServices.json";
import UtilityServices from "../common/config/UtilityServices.json";
import "../common/scss/common.module.scss";
import { InputEvent } from "../common/utility/InputEvent";
//import { ReactComponent as DS } from "./icon/デジタル書庫.svg";
//import { ReactComponent as GB } from "./icon/現場作業・点検情報管理.svg";
import DS from "./icon/デジタル書庫.svg";
import ST from "./icon/最適運転分析豚モノクロ_土赤茶色.svg";
import GB from "./icon/現場作業・点検情報管理.svg";
import IZ from "./icon/異常兆候分析.svg";
import BH from "./icon/部分放電分析.svg";
import styles from "./Service.module.scss";

/**
 * トップ画面：Service部分コンポーネント
 */
export class Service extends React.Component<any> {
  /**
   * コンストラクタ
   * @param props 親コンポーネントからの情報
   */
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  /**
   * 工事中メッセージ表示
   */
  showUnderConstructionMessage(e: any) {
    if (InputEvent.checkClickEnter(e)) {
      alert("coming soon");
    }
  }

  /**
   * 別ウインドウを開く
   * @param url URL
   */
  openWindow(url: string) {
    window.open(url);
  }

  /**
   * 同タブで開く
   * @param url URL
   */
  openTab(url: string, e: any) {
    if (InputEvent.checkClickEnter(e)) {
      window.location.href = url;
    }
  }

  /**
   * メインのサービスのアイコンを取得する
   * @param i アイコン番号
   * @returns  アイコンエリアJSX
   */
  getIcon(i: string) {
    let images: { [key: string]: JSX.Element } = {
      "0": <img src={IZ} alt={"異常兆候分析"} />,
      "1": <img src={ST} alt={"最適運転分析"} />,
      "2": <img src={BH} alt={"部分放電分析"} />,
    };

    return images[i] !== undefined ? images[i] : <img src={BH} />;
  }

  /**
   * その他のサービスのアイコンを取得する
   * @param i アイコン番号
   * @returns アイコンエリアJSX
   */
  getOtherIcon(i: string) {
    let images: { [key: string]: JSX.Element } = {
      "0": (
        <img
          className={styles.poclabImg}
          src={"https://www.mitsubishielectric.co.jp/eig/energysystems/assets/img/poc/ogp.png"}
          alt={"PocLab"}
        />
      ),
      "1": <img src={DS} alt={"デジタル書庫"} />,
    };

    return images[i] !== undefined ? images[i] : <img src={DS} />;
  }

  /**
   *
   * @param data
   * @returns
   */
  createServiceGridItem(data: any[]) {
    let resList = new Array();

    for (let i in data) {
      let iconSVG = <div>{this.getIcon(i)}</div>;

      let clickFunc = data[i].underConstruction
        ? (e: any) => {
          this.showUnderConstructionMessage(e);
        }
        : (e: any) => this.openTab(data[i].href, e);

      resList.push(
        <div key={i} className={styles.service_grid_item}
          onClick={(e) => clickFunc(e)}
          onKeyDown={(e) => clickFunc(e)}>
          <div className={styles.service_grid_item_div}>
            <div className={styles.service_grid_item_top}>
              <div className={styles.service_grid_item_num}>
                {"0" + Number(Number(i) + 1)}
              </div>
              <div className={styles.service_grid_item_title}>
                {data[i].title}
              </div>
            </div>
            <div className={styles.service_grid_item_div_img_div}>
              <div
                className={styles.service_grid_item_a}
                tabIndex={0}
                role="button"
              >
                {iconSVG}
              </div>
            </div>
            <div className={styles.service_grid_item_text}>{data[i].text}</div>
            <div className={styles.service_grid_item_link}></div>
          </div>{" "}
        </div>
      );
    }
    return resList;
  }

  /**
   *
   * @param data
   * @returns
   */
  createOtherGridItem(data: any[]) {
    let resData = new Array();

    for (let i in data) {
      resData.push(
        <div key={i} className={styles.others_grid_item}>
          <table>
            <td className={styles.others_grid_img_div}>
              <img src={data[i].imgPath}></img>
            </td>
            <td valign="top">
              <div className={styles.others_grid_item_title}>
                {data[i].title}
              </div>
              <div className={styles.others_grid_item_text}>{data[i].text}</div>
            </td>
          </table>
        </div>
      );
    }

    return resData;
  }

  /**
   *
   * @param data
   * @returns
   */
  createOtherGridItem2(data: any[]) {
    let resList = new Array();

    for (let i in data) {
      let iconSVG = <div> {this.getOtherIcon(i)} </div>;
      let genbaClassName;

      if (i == "0") {
        //genbaClassName = styles.genbaTitle;
      }

      let clickFunc = data[i].underConstruction
        ? (e: any) => {
          this.showUnderConstructionMessage(e);
        }
        : (e: any) => this.openTab(data[i].href, e);


      resList.push(
        <div key={i} className={styles.service_grid_item}
          onClick={(e) => clickFunc(e)}
          onKeyDown={(e) => clickFunc(e)}>
          <div className={styles.service_grid_item_div}>
            <div className={styles.service_grid_item_top}>
              <div className={styles.service_grid_item_num}>
                {"0" + Number(Number(i) + 1)}
              </div>
              <div
                className={
                  styles.service_grid_item_title + " " + genbaClassName
                }
              >
                {data[i].title}
              </div>
            </div>
            <div className={styles.service_grid_item_div_img_div}>
              <div
                className={styles.service_grid_item_a}
                tabIndex={0}
                role="button"
              >
                {iconSVG}
              </div>
            </div>
            <div className={styles.service_grid_item_text}>{data[i].text}</div>
            <div className={styles.service_grid_item_link}></div>
          </div>{" "}
        </div>
      );
    }
    return resList;
  }

  /**
   * 描画処理
   * @returns 描画するJSX
   */
  render() {
    console.log("data", MainServices);
    let obj = this.createServiceGridItem(MainServices);
    let other = this.createOtherGridItem2(UtilityServices);

    return (
      <div className={styles.service__div}>
        <div className={styles.service__item}>
          <div className={styles.ttl_title + " animation_bottom"} lang="en">Service</div>
          <div className={styles.ttl_underLine + " animation_bottom"}></div>
        </div>
        <div className={styles.service_area + " animation_top"}>
          <div className={styles.service_div}>
            <div className={styles.service_div_area + " animation_bottom"}>
              <div className={styles.service_div_area_title}>分析サービス</div>
              <div className={styles.service_grid}>{obj}</div>
            </div>
          </div>
          {/**その他事項 */}
          {/*
          <div className={styles.others_div_area + " animation_bottom"}>
            <div className={styles.service_div_area_title}>
              関連リンク
            </div>
            <div className={styles.service_grid}>{other}</div>
          </div>
    */}
        </div>
      </div>
    );
  }
}
