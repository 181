import React from "react";
import styles from "./PasswordChange.module.scss";

import {
  AwsAmplifyUtils,
  AWS_TOKEN_SAVE,
} from "../common/components/AwsAmplifyUtils";

interface IUploadViewProps {
  status: number;
  formOldPasswordError: Boolean;
  formNewPasswordError: Boolean;
  formConfirmPasswordError: Boolean;
  formChangeInvalidError: Boolean;
  formChangeLimitExceededError: Boolean;
  formChangeUnknownError: Boolean;
  userId: string;
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
  completed: Boolean;
}
export class PasswordChange extends React.Component<any, IUploadViewProps> {
  constructor(props: any) {
    super(props);
    this.state = {
      status: 12,
      formOldPasswordError: false,
      formNewPasswordError: false,
      formConfirmPasswordError: false,
      formChangeInvalidError: false,
      formChangeLimitExceededError: false,
      formChangeUnknownError: false,
      userId: "",
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
      completed: false,
    };
    this.handleOldPasswordChanged = this.handleOldPasswordChanged.bind(this);
    this.handleNewPasswordChanged = this.handleNewPasswordChanged.bind(this);
    this.handleConfirmPasswordChanged =
      this.handleConfirmPasswordChanged.bind(this);
    this.changePassword = this.changePassword.bind(this);

    AwsAmplifyUtils.configure();

    // ユーザ名取得
    AwsAmplifyUtils.checkAuthenticated().then((value) => {
      this.setState({ userId: value.username });
    });
  }

  /**
   * パスワード変更ボタン押下時処理
   */
  changePassword() {
    this.setState({ formNewPasswordError: false });
    this.setState({ formConfirmPasswordError: false });
    this.setState({ formOldPasswordError: false });
    this.setState({ formChangeInvalidError: false });
    this.setState({ formChangeLimitExceededError: false });
    this.setState({ formChangeUnknownError: false });
    this.setState({ completed: false });

    // 新しいパスワードのフォーマットチェック
    if (this.state.newPassword == "") {
      this.setState({ formNewPasswordError: true });
      return;
    }

    // 新しいパスワードと確認パスワードの一致チェック
    if (this.state.newPassword != this.state.confirmPassword) {
      this.setState({ formConfirmPasswordError: true });
      return;
    }
    // 認証
    AwsAmplifyUtils.signIn(this.state.userId, this.state.oldPassword)
      .then((user) => {
        // パスワード変更処理
        AwsAmplifyUtils.changePassword(
          user,
          this.state.oldPassword,
          this.state.newPassword
        )
          .then((res) => {
            // 完了時メッセージを表示
            console.log("changePassword completed");

            //パスワード同期API実行
            this.syncPassword(user);
          })
          .catch((error) => {
            console.log("changePassword error", error);
            // 不正なパスワード
            if (
              error.name == "InvalidPasswordException" ||
              error.name == "InvalidParameterException"
            ) {
              this.setState({ formChangeInvalidError: true });
            } else if (error.name == "LimitExceededException") {
              this.setState({ formChangeLimitExceededError: true });
            } else {
              this.setState({ formChangeUnknownError: true });
            }
          });
      })
      .catch((error) => {
        console.log("signIn error", error);
        // パスワード不一致エラーメッセージを表示
        this.setState({ formOldPasswordError: true });
      });
  }

  /**
   * パスワード同期API実行
   * @param user CognitoUser オブジェクト
   */
  syncPassword(user: any) {
    const headers = { "Content-Type": "application/json" };
    let data = {
      userId: this.state.userId,
      accessToken: user.signInUserSession.accessToken.jwtToken,
      newPassword: this.state.newPassword,
    };
    AwsAmplifyUtils.callAPI(true)
      .then((api) => {
        return api.API.post(
          "api",
          api.apiPrefix + "/passwordresetmanager/password/sync",
          {
            headers: headers,
            body: data,
          }
        );
      })
      .then(async (res) => {
        if (res.errorCode === "OK") {
          const msgtitle = "パスワード同期API成功";
          console.log(msgtitle);

          this.setState({ completed: true });
          this.setState({
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
          });
        } else {
          const msgtitle = "パスワード同期API実行エラー";
          console.log(msgtitle);
          this.setState({ formChangeUnknownError: true });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  /**
   * 現在のパスワード欄入力内容変更時処理
   * @param e
   */
  handleOldPasswordChanged(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({
      oldPassword: e.target.value,
    });
  }

  /**
   * 新しいパスワード欄入力内容変更時処理
   * @param e
   */
  handleNewPasswordChanged(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({
      newPassword: e.target.value,
    });
  }

  /**
   * 新しいパスワード（確認）欄入力内容変更時処理
   * @param e
   */
  handleConfirmPasswordChanged(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({
      confirmPassword: e.target.value,
    });
  }

  /**
   * 予約実装部品向けの処理
   * @returns 常にfalse
   */
  handleReservedFunctionClicked() {
    alert("coming soon.");
    return false;
  }

  render() {
    return (
      <div
        style={{
          fontSize: "16px !important",
          height: "100%",
          maxWidth: "1440px !important",
          margin: "0 auto",
          textAlign: "center",
        }}
      >
        <div className={styles.login_div_outside}>
          <div className={styles.login_title}>パスワード変更</div>
          <div
            className={
              this.state.completed ? styles.completed : styles.errorNone
            }
          >
            パスワードの変更が完了しました。
          </div>
          <div className={styles.login_menu_div}>
            <div className={styles.login_menu_title}>
              <p className={styles.title}>
                三菱電機 ANALYSIS PORTAL&nbsp;&lt;X-chAIn&gt;
                サイト会員のパスワードを変更します。
              </p>
              <div className={styles.policy}>
                <p>
                  パスワードは半角8文字以上で、英大文字・英小文字・数字・記号を最低1文字ずつ使用してください。
                </p>
                <p>記号には以下の文字が使用できます。</p>
                <div className={styles.policy_mark}>
                  <p>
                    ^ $ * . [ ] {} ( ) ? " ! @ # % &amp; / \ , &gt; &lt; ' : ; |
                    _ ~ ` = + -
                  </p>
                </div>
              </div>
            </div>
            <table className={styles.login_menu_table}>
              <tbody>
                <tr>
                  <td className={styles.login_menu_td_left}>
                    現在のパスワード
                  </td>
                  <td className={styles.login_menu_td_right}>
                    <div
                      className={
                        this.state.formOldPasswordError
                          ? styles.error
                          : styles.errorNone
                      }
                    >
                      パスワードに間違いがないか今一度お確かめ下さい。
                    </div>
                    <input
                      type="password"
                      onChange={this.handleOldPasswordChanged}
                      value={this.state.oldPassword}
                    ></input>
                  </td>
                </tr>
                <tr>
                  <td className={styles.login_menu_td_left}>
                    新しいパスワード
                  </td>
                  <td className={styles.login_menu_td_right}>
                    <div
                      className={
                        this.state.formNewPasswordError
                          ? styles.error
                          : styles.errorNone
                      }
                    >
                      新しいパスワードを入力してください。
                    </div>
                    <div
                      className={
                        this.state.formChangeInvalidError
                          ? styles.error
                          : styles.errorNone
                      }
                    >
                      パスワードは半角8文字以上で、英大文字・英小文字・数字・記号を最低1文字ずつ使用してください。
                    </div>
                    <div
                      className={
                        this.state.formChangeLimitExceededError
                          ? styles.error
                          : styles.errorNone
                      }
                    >
                      パスワード変更回数が上限を超えました。しばらく待ってから再度お試しください。
                    </div>
                    <div
                      className={
                        this.state.formChangeUnknownError
                          ? styles.error
                          : styles.errorNone
                      }
                    >
                      予期しないエラーが発生しました。管理者にお問い合わせください。
                    </div>
                    <input
                      type="password"
                      onChange={this.handleNewPasswordChanged}
                      value={this.state.newPassword}
                    ></input>
                  </td>
                </tr>
                <tr>
                  <td className={styles.login_menu_td_left}>
                    新しいパスワードを再入力
                  </td>
                  <td className={styles.login_menu_td_right}>
                    <div
                      className={
                        this.state.formConfirmPasswordError
                          ? styles.error
                          : styles.errorNone
                      }
                    >
                      新しいパスワードと確認用パスワードが一致していません。
                    </div>
                    <input
                      type="password"
                      onChange={this.handleConfirmPasswordChanged}
                      value={this.state.confirmPassword}
                    ></input>
                  </td>
                </tr>
              </tbody>
            </table>

            <div className={styles.login_menu_button}>
              <input
                type="button"
                value="パスワード変更"
                onClick={this.changePassword}
              ></input>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
