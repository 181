import { render } from "@testing-library/react";
import React, { useDebugValue } from "react";
import ReactDOM from "react-dom";
import {
  BreadcrumbHeader,
  Breadcrumb,
  BreadcrumbList,
} from "../common/components/BreadcrumbHeader";

import { ServiceList } from "../common/components/ServiceList";
import { MainImg } from "./MainImg";
import { PickUp } from "./PickUp";
import { Service } from "./Service";
import { Register } from "./Register";
import styles from "./EeneportalTopPage.module.scss";
import "../common/scss/common.module.scss";
import "./Animation.js";
import "./Animation.css";

interface eneportalProps {
  logged: boolean;
}
export class EeneportalTopPage extends React.Component<
  eneportalProps,
  BreadcrumbList
> {
  constructor(props: eneportalProps) {
    super(props);
    this.state = {
      breadcrumb: [{ name: "ANALYSIS PORTAL <X-chAIn>紹介ページ", href: "/" }],
    };
  }

  render() {
    return (
      <div>
        {/*パンクズリスト*/}
        <div className={styles.l_breadcrumb}>
          <BreadcrumbHeader
            breadcrumb={this.state.breadcrumb}
          ></BreadcrumbHeader>
        </div>

        {/*サービス一覧ヘッダ */}
        <div className="headerFix">
          <ServiceList logged={this.props.logged}></ServiceList>
        </div>
        <div>
          {/**mainビジュアル */}{" "}
          <div className={"content_font"}>
            <div>
              <MainImg></MainImg>
            </div>
            {/**説明エリア */}
            <div className={styles.content_inrr}>
              {/** pickup */}
              {/* 仮 */}
              {/*
              <div>
                <PickUp></PickUp>
              </div>
              */}
              {/**　サービス情報　 */}
              <div>
                <Service></Service>
              </div>
              {/**register */}
              <div>
                <Register logged={this.props.logged}></Register>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EeneportalTopPage;
