import React from "react";
import "./App.css";
import AppStyles from "./App.module.scss";
import AnalysisModelPage from "./parts/page/analysisModelPage/AnalysisModelPage";
import AnalysisPage from "./parts/page/analysisPage/AnalysisPage";
import ModelPage from "./parts/page/modelPage/ModelPage";
import ReportPage from "./parts/page/reportList/ReportPage";
import Slidebar from "./parts/Slidebar";
import "../common/scss/common.module.scss";
import styles from "./index.module.scss";
import Modal from "react-modal";
import loadingIcon from "./loading.gif";

/**
 * Props
 */
type Props = {
  /** ログイン状態 */
  logged: boolean;
};

/**
 * State
 */
interface SlidebarState {
  /** ドロワーの状態 */
  close: boolean;

  /** 選択中のメニューアイテム */
  select: string;
}

/**
 * APRサービス
 */
export class AprService extends React.Component<Props, any> {
  /**
   * コンストラクタ
   * @param props Props
   */
  constructor(props: Props) {
    super(props);

    this.state = {
      close: false,
      isSideSubMenuOpen: false,
      select: "report",
      loading: false,
      transReason: "",
    };
  }

  sideMenuClose() {
    let f = this.state.close;
    this.setState({ close: !f });
  }

  /**
   * サイドバーのサブメニュー開閉状態切り替え
   * @param isOpen 開くかどうか(true：開く)
   */
  setSideSubMenuOpenStatus(isOpen: boolean) {
    this.setState({ isSideSubMenuOpen: isOpen });
  }

  menuSelectHandler(menuItem: string) {
    this.setState({ select: menuItem });
  }

  setLoading(state: Boolean) {
    this.setState({ loading: state });
  }

  /**
   * 遷移要因を設定する
   * 
   * @param reason 遷移要因
   */
  setTransReason(reason: string) {
    this.setState({ transReason: reason });
  }

  render(): React.ReactNode {
    // ページごとのコンポーネントとパラメータ
    const pages: {
      [index: string]: { component: JSX.Element; moveMenu: boolean };
    } = {
      analysis: {
        component: (
          <AnalysisPage
            setLoading={this.setLoading.bind(this)}
            onMenuSelect={this.menuSelectHandler.bind(this)}
            setTransReason={this.setTransReason.bind(this)}
            setSideSubMenuOpenStatus={this.setSideSubMenuOpenStatus.bind(this)}
          />
        ),
        moveMenu: true,
      },
      analysisModel: { component: <AnalysisModelPage />, moveMenu: true },
      report: {
        component: <ReportPage
          setLoading={this.setLoading.bind(this)}
          setTransReason={this.setTransReason.bind(this)}
          transReason={this.state.transReason}
        />,
        moveMenu: false,
      },
      model: {
        component: <ModelPage close={this.state.close} />,
        moveMenu: false,
      },
    };

    return (
      <div className={styles.content}>
        <Modal
          isOpen={this.state.loading}
          className={styles.loader_dialog}
          ariaHideApp={false}
        >
          <div className={styles.loader_modal}></div>
          <div className={styles.loader}>
            <div className={styles.message}>
              <img
                className={styles.img_loading}
                src={loadingIcon}
                alt="読み込み中..."
              />
              <span className={styles.span_loading} lang="en">
                Please wait...
              </span>
            </div>
          </div>
        </Modal>
        <div className={styles.Main_Content}>
          <div className="App">
            <div className={AppStyles.AppArea}>
              <Slidebar
                select={this.state.select}
                close={this.state.close}
                isSideSubMenuOpen={this.state.isSideSubMenuOpen}
                setSideSubMenuOpenStatus={this.setSideSubMenuOpenStatus.bind(this)}
                trial={true}
                moveMenu={pages[this.state.select].moveMenu}
                onMenuSelect={this.menuSelectHandler.bind(this)}
              ></Slidebar>
              <div>{pages[this.state.select].component}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AprService;
