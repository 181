import React from "react";
import styles from "./ResetPasswordQuestion.module.scss";
import { useNavigate } from "react-router-dom";
import { AwsAmplifyUtils } from "../common/components/AwsAmplifyUtils";

interface IUploadViewProps {
  questionList: Array<QuestionContents>;
  formValid: Boolean;
  formData: Contents;
  formDataValue: ContentsValue;
}

// フォーマットのエラーコード
var ErrorCode = {
  CORRECT: 0,
  NONE: 1,
  INVALID: 2,
};

type QuestionContents = {
  questionId: number;
  question: string;
};

export interface Contents {
  userId: number;
  questionId: number;
  answer: number;
}

export interface ContentsValue {
  userId: string;
  questionId: number;
  answer: string;
}

// Hookを使用するためクラスを関数でラップ
export default function ResetPasswordQuestionNavigate(props: any) {
  // useNavigationを取得し、クラスにnavigationを渡す
  const navigate = useNavigate();

  return <ResetPasswordQuestion {...props} navigate={navigate} />;
}

class ResetPasswordQuestion extends React.Component<any, IUploadViewProps> {
  constructor(props: any) {
    super(props);
    this.state = {
      // 秘密の質問リスト
      questionList: [],

      // フォームデータが正常か (true/false: 正常/異常)
      formValid: true,

      // 各フォームデータの正当性 (true/false: 正常/異常)
      formData: {
        userId: ErrorCode.CORRECT,
        questionId: ErrorCode.CORRECT,
        answer: ErrorCode.CORRECT,
      },

      // 各フォームデータの値
      formDataValue: {
        userId: "",
        questionId: -1,
        answer: "",
      },
    };
  }

  /**
   * 初期データロード
   */
  componentDidMount() {
    // configure
    AwsAmplifyUtils.configure();
    // 「秘密の質問」取得
    this.getQuestionInfo();
  }

  /**
   * 秘密の質問取得APIを実行
   */
  getQuestionInfo = () => {
    const headers = { "Content-Type": "application/json" };
    let data = {};
    AwsAmplifyUtils.callAPI(true)
      .then((api) => {
        return api.API.post(
          "api",
          api.apiPrefix + "/passwordresetmanager/question/get",
          {
            headers: headers,
            body: data,
          }
        );
      })
      .then((res) => {
        if (res.errorCode === "OK") {
          console.log(res.questionList);
          const n = res;
          const ndata = new Array<QuestionContents>();
          n.questionList.forEach((m: QuestionContents) => {
            ndata.push(m);
          });

          console.log(ndata);

          // 秘密の質問のsetState
          this.setState({
            questionList: ndata,
          });
          console.log(this.state);
        } else {
          alert(res.errorMessage);
        }
      })
      .catch((e) => {
        console.log(e);
        alert(e);
      })
      .finally(() => {
        //何もしない
      });
  };

  confirm = async () => {
    await this.validate();
    console.log(this.state.formValid);

    if (this.state.formValid) {
      this.props.navigate("../resetpasswordmail", {
        state: this.state.formDataValue,
      });
    } else {
      // 何もしない
    }
  };

  // バリデーション
  validate() {
    let value: ContentsValue;
    let result: Contents;
    let formValid: Boolean;
    value = this.state.formDataValue;
    result = this.state.formData;

    console.log(value);

    // 必須項目のチェック
    result.userId = value.userId != "" ? ErrorCode.CORRECT : ErrorCode.NONE;
    result.questionId =
      value.questionId != -1 ? ErrorCode.CORRECT : ErrorCode.NONE;
    result.answer = value.answer != "" ? ErrorCode.CORRECT : ErrorCode.NONE;

    formValid =
      result.userId == ErrorCode.CORRECT &&
      result.questionId == ErrorCode.CORRECT &&
      result.answer == ErrorCode.CORRECT;

    this.setState({
      formValid: formValid,
    });

    console.log(this.state);
  }

  //「会員ID」のonchange()メソッド
  changeUserId: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    console.log(e.target.value);
    this.state.formDataValue.userId = e.target.value;
  };

  //「秘密の質問」のonchange()メソッド
  changeQuestion: React.ChangeEventHandler<HTMLSelectElement> = (e) => {
    console.log(e.target.value);
    this.state.formDataValue.questionId = parseInt(e.target.value);
  };

  //「秘密の質問(回答)」のonchange()メソッド
  changeAnswer: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    console.log(e.target.value);
    this.state.formDataValue.answer = e.target.value;
  };

  render() {
    // 「秘密の質問」リスト生成
    var questionRenderList = [];

    questionRenderList.push(<option value={-1}>{}</option>);

    this.state.questionList.forEach((m: QuestionContents) => {
      questionRenderList.push(
        <option value={m.questionId}>{m.question}</option>
      );
    });
    return (
      <div
        style={{
          fontSize: "16px !important",
          height: "100%",
          maxWidth: "1440px !important",
          margin: "0 auto",
          textAlign: "center",
        }}
      >
        <div className={styles.reset_div_outside}>
          <div className={styles.reset_title}>パスワード初期化</div>
          <div className={styles.reset_menu_div}>
            <p className={styles.reset_menu_title}>
              三菱電機
              <span lang="en">ANALYSIS PORTAL&nbsp;&lt;X-chAIn&gt;</span>
              サイト会員のパスワードを初期化します。
              <br />
              パスワードの初期化には、「会員<span lang="en">ID</span>
              」と会員登録時に設定頂いた「秘密の質問」の2つの情報が必要になります。
              <br />
              <br />
              上記入力後に遷移するページにて、初期化後のパスワードを連絡するメールアドレスを入力してください。
            </p>
            <table className={styles.reset_menu_table}>
              <tbody>
                <tr>
                  <td className={styles.reset_menu_td_left}>
                    会員<span lang="en">ID</span>
                  </td>
                  <td className={styles.reset_menu_td_right}>
                    <div
                      className={
                        this.state.formData.userId == ErrorCode.NONE
                          ? styles.error
                          : styles.errorNone
                      }
                    >
                      「会員<span lang="en">ID</span>」が入力されていません。
                    </div>
                    <input
                      type="text"
                      onChange={(e) => this.changeUserId(e)}
                    ></input>
                  </td>
                </tr>
                <tr>
                  <td className={styles.reset_menu_td_left}>秘密の質問</td>
                  <td className={styles.reset_menu_td_right}>
                    <div
                      className={
                        this.state.formData.questionId == ErrorCode.NONE
                          ? styles.error
                          : styles.errorNone
                      }
                    >
                      「秘密の質問」が選択されていません。
                    </div>
                    <select
                      className={styles.reset_menu_select}
                      onChange={(e) => this.changeQuestion(e)}
                      defaultValue={this.state.formDataValue.questionId}
                    >
                      {questionRenderList}
                    </select>
                  </td>
                </tr>
                <tr>
                  <td className={styles.reset_menu_td_left}>
                    秘密の質問(回答)
                  </td>
                  <td className={styles.reset_menu_td_right}>
                    <div
                      className={
                        this.state.formData.answer == ErrorCode.NONE
                          ? styles.error
                          : styles.errorNone
                      }
                    >
                      「秘密の質問(回答)」が入力されていません。
                    </div>
                    <input type="text" onChange={(e) => this.changeAnswer(e)} />
                  </td>
                </tr>
              </tbody>
            </table>
            <div className={styles.reset_menu_button}>
              <input type="button" value="確認" onClick={this.confirm} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
