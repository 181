import CloseIcon from "@mui/icons-material/Close";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import SendIcon from "@mui/icons-material/Send";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import "gridjs/dist/theme/mermaid.css";
import React from "react";
import "react-pro-sidebar/dist/css/styles.css";
import DownloadDiv from "./DownloadDiv";
import { InfoDiv } from "./InfoDiv";
import { LearningDiv } from "./LearningDiv";
import styles from "./ModelSettingPage.module.scss";
import UploadDiv from "./UploadDiv";

type Props = {};
interface SlidebarState {
  close: boolean;
}
interface ModelSettingPageState {
  status: string;
  selectPage: string;
  openMenu: boolean;
}
//<Props,State>
export class ModelSettingPage extends React.Component<
  any,
  ModelSettingPageState
> {
  constructor(props: ModelSettingPageState) {
    super(props); // stateを初期化
    this.state = {
      status: "enable",
      selectPage: "Info",
      openMenu: false,
    };
  }
  openMenu = () => {
    this.setState({ openMenu: !this.state.openMenu });
  };
  close() {
    window.alert(
      "設定ページを閉じて、モデル一覧の初期表示に戻る予定です。\nモックのため、本ボタンから閉じることは出来ません。\n左端のチェックボックスを外して閉じて下さい。"
    );
  }
  changeState = (status: string) => {
    console.log(status);
    this.setState({
      selectPage: status,
    });
  };
  render(): React.ReactNode {
    console.log("ステータス", this.state.selectPage);
    console.log("どう？", this.state.openMenu);
    let InfoStyle = "";
    let UpDwStyle = "";
    let LearnStyle = "";
    let InfoSelect = "";
    let UpDwSelect = "";
    let LearnSelect = "";
    let UploadDivStyle = "";
    let DownloadDivStyle = "";
    if (this.state.selectPage == "Info") {
      UpDwStyle = "none";
      LearnStyle = "none";
      UploadDivStyle = "none";
      DownloadDivStyle = "none";
      InfoSelect = styles.infoButtonSelect;
      /*} else if (this.state.selectPage == "UpDw") {
      InfoStyle = "none";
      LearnStyle = "none";
      UpDwSelect = styles.infoButtonSelect;*/
    } else if (this.state.selectPage == "Up") {
      InfoStyle = "none";
      LearnStyle = "none";
      DownloadDivStyle = "none";
      UpDwSelect = styles.infoButtonSelect;
    } else if (this.state.selectPage == "Dw") {
      InfoStyle = "none";
      LearnStyle = "none";
      UploadDivStyle = "none";
      UpDwSelect = styles.infoButtonSelect;
    } else if (this.state.selectPage == "Lean") {
      InfoStyle = "none";
      UpDwStyle = "none";
      UploadDivStyle = "none";
      DownloadDivStyle = "none";
      LearnSelect = styles.infoButtonSelect;
    } else {
      // 通らない
    }
    return (
      <div className={styles.ModelSettingDiv}>
        {/**リストボックスエリア */}
        <div className={styles.pageListArea}>
          <div className={styles.listArea}>
            <List
              sx={{ width: "100%", maxWidth: 360 }}
              component="nav"
              aria-labelledby="nested-list-subheader"
              subheader={
                <ListSubheader className={styles.listHeader}component="div" id="nested-list-subheader">
                  <div className={styles.flex}>
                    <div className={styles.listIconDiv}>
                      <SendIcon />
                    </div>
                    <div>Model Editor</div>
                  </div>
                </ListSubheader>
              }
            >
              <ListItemButton
                onClick={() => this.changeState("Info")}
                selected={this.state.selectPage == "Info"}
              >
                <ListItemText primary="基本情報" />
              </ListItemButton>
              <ListItemButton
                onClick={this.openMenu}
                selected={
                  this.state.selectPage == "Up" || this.state.selectPage == "Dw"
                }
              >
                <div>
                  <ListItemText primary="アップロード" />
                  <ListItemText primary="ダウンロード" />
                </div>
                <div className={styles.openMenuIcon}>
                  {this.state.openMenu ? <ExpandLess /> : <ExpandMore />}
                </div>
              </ListItemButton>
              <Collapse in={this.state.openMenu} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItemButton
                    sx={{ pl: 4 }}
                    onClick={() => this.changeState("Up")}
                  >
                    <ListItemText primary="設定アップロード" />
                  </ListItemButton>
                  <ListItemButton
                    sx={{ pl: 4 }}
                    onClick={() => this.changeState("Dw")}
                  >
                    <ListItemText primary="設定ダウンロード" />
                  </ListItemButton>
                </List>
              </Collapse>
              <ListItemButton
                selected={this.state.selectPage == "Lean"}
                onClick={() => this.changeState("Lean")}
              >
                <ListItemText primary="学習実行" />
              </ListItemButton>
            </List>
          </div>
        </div>
        {/**情報エリア */}
        <div className={styles.ModelSettingInfoDiv}>
          <div className={styles.modelSettingDispDiv}>
            {/**クローズボタンエリア */}
            <div className={styles.ModelSettingCloseDiv}>
              <CloseIcon
                className={styles.icon}
                onClick={this.close}
              ></CloseIcon>
            </div>
            <div style={{ display: InfoStyle }}>
              <InfoDiv></InfoDiv>
            </div>
            {/*<div style={{ display: UpDwStyle }}>
              <UpDwDiv></UpDwDiv>
    </div>*/}
            <div style={{ display: LearnStyle }}>
              <LearningDiv></LearningDiv>
            </div>
            <div style={{ display: UploadDivStyle }}>
              <UploadDiv></UploadDiv>
            </div>
            <div style={{ display: DownloadDivStyle }}>
              <DownloadDiv></DownloadDiv>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ModelSettingPage;

//https://gridjs.io/docs/examples/row-buttons grid.js
