import Button from "@mui/material/Button";
/// テーブル用の
/*import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";*/
import { Grid, _ } from "gridjs-react";
import "gridjs/dist/theme/mermaid.css";
import React from "react";
import "react-pro-sidebar/dist/css/styles.css";
import styles from "./InfoDiv.module.scss";
type Props = {};
interface SlidebarState {
  close: boolean;
}
interface ModelPageState {
  message: string;
  open: boolean;
  showMenu: any;
  deleteDia: boolean;
  changeName: boolean;
  copyModel: boolean;
  createModel: boolean;
}
//<Props,State>
export class InfoDiv extends React.Component {
  constructor(props: any) {
    super(props); // stateを初期化
    this.state = {};
  }
  createColumns() {
    let hedItem = [
      { name: "No." },
      { name: "実行開始日時" },
      { name: "実行終了日時" },
      { name: "種別" },
      { name: "状態" },
      { name: "実行条件" },
    ];
    return hedItem;
  }
  createData() {
    let resData = new Array();
    for (let index = 0; index < 15; index++) {
      let tmpData = new Array();
      if (index == 5) {
        tmpData.push(Number(index) + 1);
        tmpData.push("YYYY/MM/DD HH:MM");
        tmpData.push("YYYY/MM/DD HH:MM");
        tmpData.push(_(<div className={styles.two}>修正</div>));
        tmpData.push(_(<div className={styles.two}>成功</div>));
        //tmpData.push("YYYY/MM/DD HH:MM　から　YYYY/MM/DD HH:MMまで");
        tmpData.push(
          _(
            <div>
              <div className={styles.flex}>
                <div className={styles.periodTitle}>対象期間</div>
                <div>YYYY/MM/DD HH:MM　から　YYYY/MM/DD HH:MMまで</div>
              </div>
              <div className={styles.flex}>
                <div className={styles.periodTitle}>除外期間</div>
                <div>-</div>
              </div>
            </div>
          )
        );
      } else if (index == 2) {
        tmpData.push(Number(index) + 1);
        tmpData.push("YYYY/MM/DD HH:MM");
        tmpData.push("YYYY/MM/DD HH:MM");
        tmpData.push(_(<div className={styles.two}>修正</div>));
        tmpData.push(_(<div className={styles.two}>成功</div>));
        //tmpData.push("YYYY/MM/DD HH:MM　から　YYYY/MM/DD HH:MMまで");
        tmpData.push(
          _(
            <div>
              <div className={styles.flex}>
                <div className={styles.periodTitle}>対象期間</div>
                <div>YYYY/MM/DD HH:MM　から　YYYY/MM/DD HH:MMまで</div>
              </div>
              <div className={styles.flex}>
                <div className={styles.periodTitle}>対象期間</div>
                <div>YYYY/MM/DD HH:MM　から　YYYY/MM/DD HH:MMまで</div>
              </div>
              <div className={styles.flex}>
                <div className={styles.periodTitle}>対象期間</div>
                <div>YYYY/MM/DD HH:MM　から　YYYY/MM/DD HH:MMまで</div>
              </div>
              <div className={styles.flex}>
                <div className={styles.periodTitle}>除外期間</div>
                <div>-</div>
              </div>
            </div>
          )
        );
      } else {
        tmpData.push(Number(index) + 1);
        tmpData.push("YYYY/MM/DD HH:MM");
        tmpData.push("YYYY/MM/DD HH:MM");
        tmpData.push(_(<div className={styles.two}>学習</div>));
        tmpData.push(_(<div className={styles.two}>成功</div>));
        //tmpData.push("YYYY/MM/DD HH:MM　から　YYYY/MM/DD HH:MMまで");
        tmpData.push(
          _(
            <div>
              <div className={styles.flex}>
                <div className={styles.periodTitle}>対象期間</div>
                <div>YYYY/MM/DD HH:MM　から　YYYY/MM/DD HH:MMまで</div>
              </div>
              <div className={styles.flex}>
                <div className={styles.periodTitle}>除外期間</div>
                <div>-</div>
              </div>
            </div>
          )
        );
      }
      resData.push(tmpData);
    }
    return resData;
  }
  render(): React.ReactNode {
    let columns = this.createColumns();
    let rows = this.createData();
    return (
      <div className={styles.InfoDiv}>
        {/*** タイトルエリア */}
        <div className={styles.title}>基本情報</div>
        {/**情報エリア */}
        <div className={styles.infoArea}>
          <div>モデル名称:　XXXXXXX</div>
          <div>関連環境:　分析環境1</div>
          <div>信号点数:　XXXX点</div>
          <div>学習充足度:　XX%</div>
          <div>(学習量が最も多い信号の)学習量:　XXX日</div>
          <div>作業ユーザ:　MELCO</div>
          <div>前回更新日時:　YYYY/MM/DD HH:MM</div>
        </div>
        <div className={styles.interval}></div>
        <div className={styles.learningInfoDiv}>
          <div className={styles.learningInfo}>学習実行履歴</div>

          {/*<div className={styles.flex}>
            <div className={styles.periodLabel}>学習モデル作成期間</div>
            <Button
              className={styles.periodButton}
              variant="contained"
              component="span"
            >
              過去期間表示
            </Button>
    </div>*/}
        </div>
        <div className={styles.tableDiv}>
          <Grid
            pagination={true}
            data={rows}
            sort={false}
            columns={columns}
            width="860px"
          />
          {/**data={rows} */}
        </div>
      </div>
    );
  }
}

export default InfoDiv;

//https://gridjs.io/docs/examples/row-buttons grid.js
