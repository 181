import React from "react";
import styles from "./ResetPasswordMail.module.scss";
import { useLocation } from "react-router-dom";
import { AwsAmplifyUtils } from "../common/components/AwsAmplifyUtils";

interface IUploadViewProps {
  formValid: Boolean;
  formData: Contents;
  formDataValue: ContentsValue;
  questionDataValue: questionDataValue;
}

// フォーマットのエラーコード
var ErrorCode = {
  CORRECT: 0,
  NONE: 1,
  INVALID: 2,
};

export interface Contents {
  email: number;
}

export interface ContentsValue {
  email: string;
}

export interface questionDataValue {
  userId: string;
  questionId: number;
  answer: string;
}

// hookを使用するためクラスを関数でラップ
export default function ResetPasswordMailLocation(props: any) {
  // useNavigationを取得し、クラスにnavigationを渡す
  const location = useLocation();

  return <ResetPasswordMail {...props} location={location} />;
}

export class ResetPasswordMail extends React.Component<any, IUploadViewProps> {
  constructor(props: any) {
    super(props);
    this.state = {
      // フォームデータが正常か (true/false: 正常/異常)
      formValid: true,

      // 各フォームデータの正当性 (true/false: 正常/異常)
      formData: {
        email: ErrorCode.CORRECT,
      },

      // 各フォームデータの値
      formDataValue: {
        email: "",
      },

      questionDataValue: props.location.state,
    };
  }

  submit = async () => {
    await this.validate();
    console.log(this.state.formValid);

    if (this.state.formValid) {
      //パスワードリセットAPIの呼出
      this.resetPassword();
    } else {
      // 何もしない
    }
  };

  // バリデーション
  validate() {
    let value: ContentsValue;
    let result: Contents;
    let formValid: Boolean;
    value = this.state.formDataValue;
    result = this.state.formData;

    console.log(value);

    // メールアドレスのフォーマット
    result.email = Boolean(
      value.email.match(
        /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
      )
    )
      ? ErrorCode.CORRECT
      : ErrorCode.INVALID;

    formValid = result.email == ErrorCode.CORRECT;

    this.setState({
      formValid: formValid,
    });

    console.log(this.state);
  }

  /**
   * パスワードリセットAPIを実行
   */
  resetPassword = () => {
    const headers = { "Content-Type": "application/json" };
    let data = {
      userId: this.state.questionDataValue.userId,
      questionId: this.state.questionDataValue.questionId,
      answer: this.state.questionDataValue.answer,
      mailAddress: this.state.formDataValue.email,
    };
    AwsAmplifyUtils.callAPI(true)
      .then((api) => {
        return api.API.post(
          "api",
          api.apiPrefix + "/passwordresetmanager/password/reset",
          {
            headers: headers,
            body: data,
          }
        );
      })
      .then((res) => {
        // パスワード初期化メール送信画面に遷移
        window.location.href = "../resetpasswordaccept";
      })
      .catch((e) => {
        // パスワード初期化メール送信画面に遷移
        window.location.href = "../resetpasswordaccept";
      })
      .finally(() => {
        //何もしない
      });
  };

  // メールアドレスのonchange()メソッド
  changeEmail: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    console.log(e.target.value);
    this.state.formDataValue.email = e.target.value;
  };

  render() {
    return (
      <div
        style={{
          fontSize: "16px !important",
          height: "100%",
          maxWidth: "1440px !important",
          margin: "0 auto",
          textAlign: "center",
        }}
      >
        <div className={styles.reset_div_outside}>
          <div className={styles.reset_title}>パスワード初期化</div>
          <div className={styles.reset_menu_div}>
            <p className={styles.reset_menu_title}>
              初期化後のパスワードを連絡するメールアドレスを入力してください。
            </p>
            <table className={styles.reset_menu_table}>
              <tbody>
                <tr>
                  <td className={styles.reset_menu_td_left}>メールアドレス</td>
                  <td className={styles.reset_menu_td_right}>
                    <div
                      className={
                        this.state.formData.email == ErrorCode.NONE
                          ? styles.error
                          : styles.errorNone
                      }
                    >
                      「メールアドレス」が入力されていません。
                    </div>
                    <div
                      className={
                        this.state.formData.email == ErrorCode.INVALID
                          ? styles.error
                          : styles.errorNone
                      }
                    >
                      「有効なメールアドレス」ではありません。
                    </div>
                    <input
                      type="text"
                      onChange={(e) => this.changeEmail(e)}
                    ></input>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className={styles.reset_menu_button}>
              <input
                type="button"
                value="パスワード初期化"
                onClick={this.submit}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
