export class InputEvent {
    /**
     * クリックorEnterキー押下時にtrueを返す
     * @param e イベント
     */
    static checkClickEnter(e: any) {
        if (e.type == "click" || (e.type == "keydown" && e.keyCode == 13)) {
            return true;
        }
        return false;
    }
}
export default InputEvent;