import DoneIcon from "@mui/icons-material/Done";
import ErrorIcon from "@mui/icons-material/Error";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Button from "@mui/material/Button";
// ダイアログ
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
/// テーブル用の
/*import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";*/
import { Grid, _ } from "gridjs-react";
import "gridjs/dist/theme/mermaid.css";
import React from "react";
import "react-pro-sidebar/dist/css/styles.css";
import Data from "../../../../mock_data/aprModelData.json";
import ContentHeder from "../../ContentHeder";
import "./ModelPage.css";
import styles from "./ModelPage.module.scss";
import ModelSettingPage from "./settingPage/ModelSettingPage";

type Props = {};
interface SlidebarState {
  close: boolean;
}
interface ModelPageState {
  message: string;
  open: boolean;
  showMenu: any;
  deleteDia: boolean;
  search: Array<string>;
  changeName: boolean;
  copyModel: boolean;
  createModel: boolean;
}
//<Props,State>
export class ModelPage extends React.Component<SlidebarState, ModelPageState> {
  menu: null;
  constructor(props: SlidebarState | Readonly<SlidebarState>) {
    super(props); // stateを初期化
    this.state = {
      open: false,
      message: "Please Click Anywhere you like.",
      showMenu: null,
      deleteDia: false,
      search: [],
      changeName: false,
      copyModel: false,
      createModel: false,
    };
    // メニュー要素への参照を初期化（後ほどレンダラーの中でrefを割り当てます。）
    this.menu = null;
    // const [currentRow, setCurrentRow] = React.useState(null);
  }

  /***
   * 検索
   * @param search 検索テキスト
   */
   searchChanged = (search: Array<string>) => {
    this.setState({search: search});
  }

  handleOpenMenu = (
    event: React.MouseEvent<SVGSVGElement, MouseEvent>,
    row: any
  ) => {
    console.log("クリックイベント", event);
    // 既に開いてる状態であれば、閉じる
    if (row == this.state.showMenu) {
      this.setState({ showMenu: null });
    } else {
      let state = { row: row, x: event.clientX, y: event.clientY };
      this.setState({
        showMenu: state,
      });
    }
  };
  handleCloseMenu = () => {
    this.setState({
      showMenu: null,
      deleteDia: false,
      changeName: false,
      copyModel: false,
      createModel: false,
    });
  };
  deleteModel = () => {
    console.log("モデルを削除します。");
    this.setState({ deleteDia: true });
    //this.setState({ showMenu: null });
  };
  changeName = () => {
    console.log("モデル名を変更します。");
    this.setState({ changeName: true });
  };
  copyModel = () => {
    console.log("モデルをコピーします。");
    this.setState({ copyModel: true });
  };
  createModel = () => {
    console.log("モデルを新規作成します。");
    this.setState({
      createModel: true,
    });
  };
  createTableHead() {
    let resData = new Array();
    let hedItem = [
      {
        name: "モデル名",
        /*formatter: (cell: any) => html(`<a>${cell}</a>`),*/
        width: "274px",
      },
      "開発環境",
      "作業ユーザ",
      "状態",
      "更新日時",
      {
        name: "",
        class: "ああ",
      },
    ];
    console.log("だた", hedItem);
    return hedItem;
  }
  createTableRow() {
    let resData = new Array();
    for (let i = 0; i < Data.length; i++) {
      let icon = <ErrorIcon sx={{ color: "red" }} />;
      if (Data[i].state == "使用可") {
        icon = <DoneIcon color="success" />;
      }
      let tmp = new Array();
      console.log(Data[i].modelName);
      tmp.push(
        _(
          <div className={styles.modelNameCell}>
            <a>{Data[i].modelName}</a>
          </div>
        )
      );
      tmp.push(Data[i].env);
      tmp.push(Data[i].user);
      tmp.push(
        _(
          <div className={styles.stateTd}>
            <div className={styles.stateDiv}>{Data[i].state}</div>
            {icon}
          </div>
        )
      );
      tmp.push(Data[i].date);

      tmp.push(
        _(
          <div>
            <MoreVertIcon
              className={styles.moreIcon}
              // onClick={() => this.test2(Data[i].modelName)}
              onClick={(event) => this.handleOpenMenu(event, i)}
            ></MoreVertIcon>
          </div>
        )
      );
      resData.push(tmp);
    }

    return resData;
  }

  render(): React.ReactNode {
    // サイドメニューの非表示
    let none;
    let settingNone = styles.settingDivNone;
    let expStyle = "";
    if (this.props.close) {
      none = styles.none;
      settingNone = "";
      expStyle = styles.settingExp;
    }

    let columns = this.createTableHead();

    let rows = this.createTableRow();
    //let rows = new Array();
    let showMenu = {};
    showMenu = { display: "none" };
    // メニューリストが一致すれば表示する
    if (this.state.showMenu != null) {
      showMenu = {
        left: this.state.showMenu.x - 230 + "px",
        top: this.state.showMenu.y - 65 + "px",
      };
    }
    return (
      <div className={styles.ModelDiv}>
        <div className={styles.ModelPage + " " + none}>
          <ContentHeder
            close={this.props.close}
            searchChanged={this.searchChanged}
          ></ContentHeder>
          <div className={styles.tableArea}>
            <div
              className={styles.tableDiv}
              style={{ width: 1150, paddingLeft: 50 }}
            >
              <Grid sort={true} data={rows} columns={columns} />
            </div>
          </div>
          {/**クリックメニューリスト部品化要 */}
          <div>
            <MenuList
              className={styles.MenuList}
              id="long-menu"
              style={showMenu}
            >
              <MenuItem
                key="Edit"
                onClick={() => {
                  this.changeName();
                }}
              >
                名前の変更
              </MenuItem>
              <MenuItem
                key="Delete"
                onClick={() => {
                  this.deleteModel();
                }}
              >
                削除
              </MenuItem>
              <MenuItem
                key="Copy"
                onClick={() => {
                  this.copyModel();
                }}
              >
                複製
              </MenuItem>
            </MenuList>
          </div>
          {/**　削除の確認ダイアログここ部品化要 */}
          <div>
            <Dialog
              open={this.state.deleteDia}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"モデル「****」を削除しますか？"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  モデル削除によって、実績値が消えることはありません。
                  学習データは、削除されます。等の説明文を記載する。
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleCloseMenu} autoFocus>
                  キャンセル
                </Button>
                <Button onClick={this.handleCloseMenu}>削除</Button>
              </DialogActions>
            </Dialog>
          </div>
          {/**　名前変更・コピーの確認ダイアログここ部品化要 */}
          <div>
            <Dialog
              open={this.state.changeName}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              sx={{ width: "600px", margin: "auto" }}
            >
              <DialogTitle
                id="alert-dialog-title"
                sx={{
                  marginTop: "20px",
                  marginLeft: "30px",
                  width: "310px",
                  marginRight: "30px",
                }}
              >
                {"モデル名の編集"}
              </DialogTitle>
              <DialogContent
                sx={{
                  marginLeft: "30px",
                  width: "310px",
                  marginRight: "30px",
                }}
              >
                <div>
                  <TextField
                    className={styles.changeModelNameDiv}
                    id="outlined-basic"
                    label="モデル名"
                    variant="outlined"
                    defaultValue="定常運転モデル"
                  />
                </div>
                <div>
                  <FormControl
                    fullWidth
                    className={styles.changeModelDiv}
                    disabled
                  >
                    <InputLabel id="demo-simple-select-label">
                      選択モデル
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={10}
                      label="選択モデル"
                    >
                      <MenuItem value={10}>分析環境1</MenuItem>
                      <MenuItem value={20}>分析環境2</MenuItem>
                      <MenuItem value={30}>分析環境3</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </DialogContent>
              <DialogActions
                sx={{
                  marginBottom: "20px",
                  marginLeft: "30px",
                  width: "310px",
                  marginRight: "30px",
                }}
              >
                <Button onClick={this.handleCloseMenu} autoFocus>
                  キャンセル
                </Button>
                <Button onClick={this.handleCloseMenu}>決定</Button>
              </DialogActions>
            </Dialog>
          </div>
          {/**　モデルのコピーの確認ダイアログここ部品化要 */}
          <div>
            <Dialog
              open={this.state.copyModel}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              sx={{ width: "600px", margin: "auto" }}
            >
              <DialogTitle
                id="alert-dialog-title"
                sx={{
                  marginTop: "20px",
                  marginLeft: "30px",
                  width: "310px",
                  marginRight: "30px",
                }}
              >
                {"モデルの複製"}
              </DialogTitle>
              <DialogContent
                sx={{
                  marginLeft: "30px",
                  width: "310px",
                  marginRight: "30px",
                }}
              >
                <div>
                  <TextField
                    className={styles.changeModelNameDiv}
                    id="outlined-basic"
                    label="モデル名"
                    variant="outlined"
                    defaultValue=""
                  />
                </div>
                <div>
                  <FormControl
                    fullWidth
                    className={styles.changeModelDiv}
                    disabled
                  >
                    <InputLabel id="demo-simple-select-label">
                      選択モデル
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={10}
                      label="選択モデル"
                    >
                      <MenuItem value={10}>分析環境1</MenuItem>
                      <MenuItem value={20}>分析環境2</MenuItem>
                      <MenuItem value={30}>分析環境3</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </DialogContent>
              <DialogActions
                sx={{
                  marginBottom: "20px",
                  marginLeft: "30px",
                  width: "310px",
                  marginRight: "30px",
                }}
              >
                {" "}
                <Button onClick={this.handleCloseMenu} autoFocus>
                  キャンセル
                </Button>
                <Button onClick={this.handleCloseMenu}>決定</Button>
              </DialogActions>
            </Dialog>
          </div>
          {/**　モデルの追加ダイアログここ部品化要 */}
          <div>
            <Dialog
              open={this.state.createModel}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              sx={{ width: "600px", margin: "auto" }}
            >
              <DialogTitle
                id="alert-dialog-title"
                sx={{
                  marginTop: "20px",
                  marginLeft: "30px",
                  width: "310px",
                  marginRight: "30px",
                }}
              >
                {"モデル新規作成"}
              </DialogTitle>
              <DialogContent
                sx={{
                  marginLeft: "30px",
                  width: "310px",
                  marginRight: "30px",
                }}
              >
                <div>
                  <TextField
                    className={styles.changeModelNameDiv}
                    id="outlined-basic"
                    label="モデル名"
                    variant="outlined"
                    defaultValue=""
                  />
                </div>
                <div>
                  <FormControl fullWidth className={styles.changeModelDiv}>
                    <InputLabel id="demo-simple-select-label">
                      選択モデル
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="選択モデル"
                    >
                      <MenuItem value={10}>分析環境1</MenuItem>
                      <MenuItem value={20}>分析環境2</MenuItem>
                      <MenuItem value={30}>分析環境3</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </DialogContent>
              <DialogActions
                sx={{
                  marginBottom: "20px",
                  marginLeft: "30px",
                  width: "310px",
                  marginRight: "30px",
                }}
              >
                <Button onClick={this.handleCloseMenu} autoFocus>
                  キャンセル
                </Button>
                <Button onClick={this.handleCloseMenu}>決定</Button>
              </DialogActions>
            </Dialog>
          </div>

          <div className={styles.addButonArea}>
            <button className={styles.addButon} onClick={this.createModel}>
              モデルを追加
            </button>
          </div>
        </div>
        <div className={expStyle + " " + settingNone + " " + styles.settingDiv}>
          {/*} <img src={PNG}></img>*/}
          <ModelSettingPage />
        </div>
      </div>
    );
  }
}

export default ModelPage;

//https://gridjs.io/docs/examples/row-buttons grid.js
