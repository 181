import React from "react";
import profile from "./Logout.module.scss";
import Button from "@mui/material/Button";
import { AwsAmplifyUtils } from "../common/components/AwsAmplifyUtils";
import CircularProgress from "@mui/material/CircularProgress";

interface LogoutContext {
  isLoading: boolean;
}

export class Logout extends React.Component<any, LogoutContext> {
  constructor(props: any) {
    super(props);
    this.state = {
      isLoading: true,
    };
  }

  async componentDidMount() {
    AwsAmplifyUtils.signOut()
    .then(() => {
      console.log("user logout.");
    })
    .catch((err) => {
      console.log(err);
    })
    .finally(() => {
      this.setState({isLoading: false});
    })
  }

  openTop = () => {
    window.location.href = "./";
  };

  render(): React.ReactNode {
    const {isLoading} = this.state;
    if (isLoading) {
      return <CircularProgress className={profile.logout_loading} />;
    } else {
      return (
        <div className={profile.area}>
          <div className={profile.title}>ログアウトしました。</div>
          <Button onClick={this.openTop} variant="contained">
            <span lang="en">
              TOP
            </span>
            ページに戻る
          </Button>
        </div>
      );
    }
  }
}
