import React from "react";
import styles from "./Entry.module.scss";
import CircleIcon from "@mui/icons-material/PlayCircle";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import { useNavigate, useLocation } from "react-router-dom";
import WindowIcon from "./icon/icon_blank.svg";

import {
  BreadcrumbHeader,
  Breadcrumb,
  BreadcrumbList,
} from "../common/components/BreadcrumbHeader";
import { ResetTvRounded, TurnRightOutlined } from "@mui/icons-material";

export interface IUploadViewProps {
  status: number;
  breadcrumb: {};
  searchAddressEnabled: boolean;
  searchAddressError: boolean;
  serviceCheckEnabled: boolean;
  purposeTextEnabled: boolean;
  confirmEnabled: boolean;
  formValid: Boolean;
  formData: Contents;
  formDataValue: ContentsValue;
}

export interface Contents {
  industry: Number;
  company: Number;
  postCode: Number;
  address: Number;
  department: Number;
  position: Number;
  name: Number;
  kana: Number;
  email: Number;
  telephone: Number;
  FAX: Number;
  purpose: Number;
  service: Number;
  term: Number;
  personalInfo: Number;
}

export interface ContentsValue {
  industry: string;
  company: string;
  postCode: string;
  address: string;
  department: string;
  position: string;
  name: string;
  kana: string;
  email: string;
  telephone: string;
  FAX: string;
  purpose: string;
  purposeOther: string;
  service: boolean[];
  term: boolean;
  personalInfo: boolean;
}

export var industryList = [
  "",
  "電力・ガス・エネルギー",
  "半導体・液晶関連装置",
  "電子部品製造装置",
  "工作機械",
  "成形機・実装機",
  "搬送装置",
  "巻取・伸線・圧延機械",
  "印刷機械",
  "食品・医療・薬品関連装置",
  "その他機械装置",
  "配電盤・制御盤・開閉装置",
  "自動車・自動車部品",
  "船舶・航空・車輛・建機",
  "電気・電子・精密機器",
  "金属・鉄鋼・素材・化学",
  "食品・医療品・化粧品",
  "賃加工業・受注加工業",
  "その他製造業",
  "エンジニアリング・システムインテグレータ・プラント",
  "受託開発ソフトウェア（システム設計・プログラム開発等）",
  "建設業（建設工事・電気工事）",
  "設計業（建築・機械）",
  "電気保安管理業",
  "官公庁・自治体・教育・研究機関・職業訓練",
  "商社",
  "その他の業種・団体",
];

export var purposeList = [
  "",
  "サービスの内容をもっと知りたい。",
  "分析サービスを実際に使ってみたい",
  "その他",
];

export var serviceList = [
  "異常兆候分析サービス"/*,
  "最適運転分析サービス",
  "部分放電分析サービス",
  "現場情報点検サービス",
  "デジタル書庫サービス",*/
];

// フォーマットのエラーコード
var ErrorCode = {
  CORRECT: 0,
  NONE: 1,
  INVALID: 2,
};

// Hookを使用するためクラスを関数でラップ
export default function Entry(props: any) {
  // useNavigationを取得し、クラスにnavigationを渡す
  const navigate = useNavigate();
  // useLocationを取得し、クラスにlocationを渡す
  const location = useLocation();

  return <EntryClass {...props} navigate={navigate} location={location} />;
}

class EntryClass extends React.Component<any, IUploadViewProps> {
  constructor(props: any) {
    super(props);

    if (props.location.state) {
      this.state = props.location.state;
    } else {
      this.state = {
        status: 12,
        breadcrumb: [
          { name: "ANALYSIS PORTAL <X-chAIn>紹介ページ", href: "../" },
          { name: "申し込みページ", href: "/" },
        ],
        // 郵便番号から住所を検索するボタンの有効化
        searchAddressEnabled: false,
        // 郵便番号から住所を検索した結果（true/false: 異常発生/異常なし）
        searchAddressError: false,
        // ご希望のサービスチェックフォームの有効化
        serviceCheckEnabled: false,
        // 会員登録の目的 (その他入力欄)の有効化
        purposeTextEnabled: false,
        // 確認画面へ進むボタンの有効化
        confirmEnabled: false,
        // フォームデータが正常か (true/false: 正常/異常)
        formValid: true,
        // 各フォームデータの正当性 (true/false: 正常/異常)
        formData: {
          industry: ErrorCode.CORRECT,
          company: ErrorCode.CORRECT,
          postCode: ErrorCode.CORRECT,
          address: ErrorCode.CORRECT,
          department: ErrorCode.CORRECT,
          position: ErrorCode.CORRECT,
          name: ErrorCode.CORRECT,
          kana: ErrorCode.CORRECT,
          email: ErrorCode.CORRECT,
          telephone: ErrorCode.CORRECT,
          FAX: ErrorCode.CORRECT,
          purpose: ErrorCode.CORRECT,
          service: ErrorCode.CORRECT,
          term: ErrorCode.CORRECT,
          personalInfo: ErrorCode.CORRECT,
        },
        // 各フォームデータの値
        formDataValue: {
          industry: "0",
          company: "",
          postCode: "",
          address: "",
          department: "",
          position: "",
          name: "",
          kana: "",
          email: "",
          telephone: "",
          FAX: "",
          purpose: "0",
          purposeOther: "",
          service: [true],
          term: false,
          personalInfo: false,
        },
      };
    }
  }

  // 確認画面へ進む
  confirm = async () => {
    await this.validate();
    console.log(this.state.formValid);

    if (this.state.formValid) {
      this.props.navigate("../entryConfirm", {
        state: this.state,
      });
    } else {
      this.returnTop();
    }
  };

  // 業種のonchange()メソッド
  changeIndustry: React.ChangeEventHandler<HTMLSelectElement> = (e) => {
    console.log(e.target.value);
    this.state.formDataValue.industry = e.target.value;
  };

  // 会社・団体名のonchange()メソッド
  changeCompany: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    console.log(e.target.value);
    this.state.formDataValue.company = e.target.value;
  };

  // 郵便番号のonchange()メソッド
  changePostCode: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    console.log(e.target.value);
    this.state.formDataValue.postCode = e.target.value;
    this.setState({ searchAddressEnabled: Boolean(e.target.value) });
  };

  // 郵便番号から住所を検索する
  searchAddress: React.MouseEventHandler<HTMLInputElement> = async (e) => {

    let value: ContentsValue;
    let result: Contents;
    let formValid: Boolean;
    value = this.state.formDataValue;
    result = this.state.formData;

    // 郵便番号検索APIのURL
    let zipcloudURL = "https://zipcloud.ibsnet.co.jp/api/search";
    let postalCode = this.state.formDataValue.postCode.replace("-", "");

    const axios = require("axios");
    const axiosJsonpAdapter = require("axios-jsonp");

    this.setState({ searchAddressEnabled: false });
    this.setState({ searchAddressError: false });

    result = this.state.formData;

    // 郵便番号のフォーマット
    result.postCode = !Boolean(value.postCode) || (Boolean(value.postCode) && Boolean(value.postCode.match(/^\d{3}-?\d{4}$/))) ? ErrorCode.CORRECT : ErrorCode.INVALID;

    if (result.postCode == ErrorCode.INVALID) {
      this.setState({ searchAddressEnabled: true });
      return;
    }

    axios
      .get(`${zipcloudURL}?zipcode=${postalCode}`, {
        adapter: axiosJsonpAdapter,
      })
      .then((response: any) => {

        if (response.data.results) {

          let prefecture = response.data.results[0].address1; // 都道府県
          let city = response.data.results[0].address2; // 市区町村
          let address = response.data.results[0].address3; // 番地
          this.state.formDataValue.address = prefecture + city + address;
          console.log(prefecture + city + address);

        } else {
          this.setState({ searchAddressError: true });
        }

      })
      .catch((_error: any) => {
        console.log("エラーが発生しました", _error);
        this.setState({ searchAddressError: true });
      })
      .finally(() => {
        setTimeout(() => {
          this.setState({ searchAddressEnabled: true });
        }, 1000);
      });
  };

  // 住所のonchange()メソッド
  changeAddress: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    console.log(e.target.value);
    this.state.formDataValue.address = e.target.value;
  };

  // 部署名のonchange()メソッド
  changeDepartment: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    console.log(e.target.value);
    this.state.formDataValue.department = e.target.value;
  };

  // 役職名のonchange()メソッド
  changePosition: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    console.log(e.target.value);
    this.state.formDataValue.position = e.target.value;
  };

  // 名前のonchange()メソッド
  changeName: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    console.log(e.target.value);
    this.state.formDataValue.name = e.target.value;
  };

  // フリガナのonchange()メソッド
  changeKana: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    console.log(e.target.value);
    this.state.formDataValue.kana = e.target.value;
  };

  // メールアドレスのonchange()メソッド
  changeEmail: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    console.log(e.target.value);
    this.state.formDataValue.email = e.target.value;
  };

  // 電話番号のonchange()メソッド
  changeTelephone: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    console.log(e.target.value);
    this.state.formDataValue.telephone = e.target.value;
  };

  // FAX番号のonchange()メソッド
  changeFax: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    console.log(e.target.value);
    this.state.formDataValue.FAX = e.target.value;
  };

  //会員登録の目的のonchange()メソッド
  changePurpose: React.ChangeEventHandler<HTMLSelectElement> = (e) => {
    let check: boolean;
    let text: boolean;
    check = false;
    text = false;
    switch (e.target.value) {
      case "0":
        break;
      case "1":
        break;
      case "2":
        check = true;
        text = false;
        break;
      case "3":
        check = false;
        text = true;
        break;
      default:
        break;
    }
    this.setState({ serviceCheckEnabled: check, purposeTextEnabled: text });
    this.state.formDataValue.purpose = e.target.value;
  };

  // 目的(その他入力欄)のonchange()メソッド
  changePurposeOther: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    console.log(e.target.value);
    this.state.formDataValue.purposeOther = e.target.value;
  };

  // ご希望のサービスのonchange()メソッド
  changeService: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    console.log(e.target.checked);
    this.state.formDataValue.service[Number(e.target.id)] = e.target.checked;
  };

  // 利用規約についてのお取り扱いのonchange()メソッド
  changeTerm: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    console.log(e.target.checked);
    this.state.formDataValue.term = e.target.checked;
  };

  // 個人情報のお取り扱いのonchange()メソッド
  changePersonalInfo: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    console.log(e.target.checked);
    this.state.formDataValue.personalInfo = e.target.checked;

    // チェックボックスと確認画面へ進むボタンの連携
    this.setState({ confirmEnabled: e.target.checked });
  };

  // ページの先頭に戻る
  returnTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  privacy() { }

  // バリデーション
  validate() {
    let value: ContentsValue;
    let result: Contents;
    let formValid: Boolean;
    value = this.state.formDataValue;
    result = this.state.formData;

    console.log(value);

    // 郵便番号のフォーマット
    result.postCode =
      !Boolean(value.postCode) ||
        (Boolean(value.postCode) &&
          Boolean(value.postCode.match(/^\d{3}-?\d{4}$/)))
        ? ErrorCode.CORRECT
        : ErrorCode.INVALID;

    // メールアドレスのフォーマット
    result.email = Boolean(
      value.email.match(
        /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
      )
    )
      ? ErrorCode.CORRECT
      : ErrorCode.INVALID;

    // フリガナのフォーマット
    result.kana =
      !Boolean(value.kana) ||
        (Boolean(value.kana) && Boolean(value.kana.match(/^[^\x01-\x7E]+$/)))
        ? ErrorCode.CORRECT
        : ErrorCode.INVALID;

    // 電話番号のフォーマット
    result.telephone =
      Boolean(
        value.telephone.match(
          /^0([0-9]-[0-9]{4}|[0-9]{2}-[0-9]{3}|[0-9]{3}-[0-9]{2}|[0-9]{4}-[0-9])-[0-9]{4}$/
        )
      ) || Boolean(value.telephone.match(/^0[1-9]0-[0-9]{4}-[0-9]{4}$/))
        ? ErrorCode.CORRECT
        : ErrorCode.INVALID;

    // FAX番号のフォーマット
    result.FAX =
      !Boolean(value.FAX) ||
        (Boolean(value.FAX) &&
          Boolean(
            value.FAX.match(
              /^0([0-9]-[0-9]{4}|[0-9]{2}-[0-9]{3}|[0-9]{3}-[0-9]{2}|[0-9]{4}-[0-9])-[0-9]{4}$/
            )
          ))
        ? ErrorCode.CORRECT
        : ErrorCode.INVALID;

    // 必須項目のチェック
    result.industry =
      value.industry != "0" ? ErrorCode.CORRECT : ErrorCode.NONE;
    result.company = Boolean(value.company)
      ? ErrorCode.CORRECT
      : ErrorCode.NONE;
    result.address = Boolean(value.address)
      ? ErrorCode.CORRECT
      : ErrorCode.NONE;
    result.department = Boolean(value.department)
      ? ErrorCode.CORRECT
      : ErrorCode.NONE;
    result.position = Boolean(value.position)
      ? ErrorCode.CORRECT
      : ErrorCode.NONE;
    result.name = Boolean(value.name) ? ErrorCode.CORRECT : ErrorCode.NONE;
    result.email = Boolean(value.email) ? result.email : ErrorCode.NONE;
    result.telephone = Boolean(value.telephone)
      ? result.telephone
      : ErrorCode.NONE;
    result.purpose = value.purpose != "0" ? ErrorCode.CORRECT : ErrorCode.NONE;
    result.term = value.term ? ErrorCode.CORRECT : ErrorCode.NONE;
    result.personalInfo = value.personalInfo
      ? ErrorCode.CORRECT
      : ErrorCode.NONE;

    formValid =
      result.industry == ErrorCode.CORRECT &&
      result.company == ErrorCode.CORRECT &&
      result.postCode == ErrorCode.CORRECT &&
      result.address == ErrorCode.CORRECT &&
      result.department == ErrorCode.CORRECT &&
      result.position == ErrorCode.CORRECT &&
      result.kana == ErrorCode.CORRECT &&
      result.email == ErrorCode.CORRECT &&
      result.telephone == ErrorCode.CORRECT &&
      result.FAX == ErrorCode.CORRECT &&
      result.purpose == ErrorCode.CORRECT &&
      result.service == ErrorCode.CORRECT &&
      result.term == ErrorCode.CORRECT &&
      result.personalInfo == ErrorCode.CORRECT;

    this.setState({
      formValid: formValid,
    });

    console.log(this.state);
  }

  /**
   * 予約実装部品向けの処理
   * @returns 常にfalse
   */
  handleReservedFunctionClicked() {
    alert("coming soon.");
    return false;
  }

  render() {
    // 業種リスト生成
    var industryRenderList = [];

    for (var i in industryList) {
      industryRenderList.push(<option value={i}>{industryList[i]}</option>);
    }

    // 目的リスト生成
    var purposeRenderList = [];

    for (var i in purposeList) {
      purposeRenderList.push(<option value={i}>{purposeList[i]}</option>);
    }

    return (
      <div
        style={{
          fontSize: "16px !important",
          height: "100%",
          maxWidth: "1440px !important",
          margin: "0 auto",
          textAlign: "center",
        }}
      >
        {/*パンクズリスト className={}*/}
        <div>
          <BreadcrumbHeader
            breadcrumb={this.state.breadcrumb}
          ></BreadcrumbHeader>
        </div>
        <div className={styles.entry_div_outside}>
          <div className={styles.entry_title}>
            ANALYSIS PORTAL&nbsp;&lt;X-chAIn&gt; サイト会員申し込み
          </div>
          <table className={styles.entry_state_table}>
            <tbody>
              <tr>
                <td
                  className={
                    styles.entry_state_td_state +
                    " " +
                    styles.entry_state_td_state_now
                  }
                >
                  1.必須情報の入力
                </td>
                <td>
                  <label className={styles.c_bulletLargeArrow_style01}></label>
                </td>
                <td className={styles.entry_state_td_state}>2.確認</td>
                <td>
                  <label className={styles.c_bulletLargeArrow_style01}></label>
                </td>
                <td className={styles.entry_state_td_state}>3.完了</td>
              </tr>
            </tbody>
          </table>
          <div className={styles.entry_menu}>
            <div className={styles.entry_menu_title}>1.必須項目の入力</div>
            <div className={styles.entry_menu_info}>
              <div
                className={
                  this.state.formValid ? styles.errorNone : styles.errorLarge
                }
              >
                入力内容に誤りがあります。ご確認の上、再入力ください。
              </div>
              <p>
                三菱電機ウェブサイトにお越しいただき、誠にありがとうございます。
              </p>
              <p>
                ANALYSIS PORTAL&nbsp;
                &lt;X-chAIn&gt;会員様向けサイトの登録申し込みは、次のフォームから受け付けております。
              </p>
              <p>
                必須項目をご入力の上、「確認画面へ進む」ボタンを押して確認画面へお進み下さい。
              </p>
            </div>
            <table className={styles.entry_menu_name}>
              <tbody>
                <tr>
                  <td className={styles.entry_menu_td_left}>
                    業種
                    <label className={styles.entry_menu_required}>必須</label>
                  </td>
                  <td className={styles.entry_menu_td_right}>
                    <div
                      className={
                        this.state.formData.industry == ErrorCode.NONE
                          ? styles.error
                          : styles.errorNone
                      }
                    >
                      業種が選択されていません。
                    </div>

                    <select
                      className={styles.inputRequired}
                      onChange={(e) => this.changeIndustry(e)}
                      defaultValue={this.state.formDataValue.industry}
                    >
                      {industryRenderList}
                    </select>
                  </td>
                </tr>
                <tr>
                  <td className={styles.entry_menu_td_left}>
                    会社・団体名
                    <label className={styles.entry_menu_required}>必須</label>
                  </td>
                  <td className={styles.entry_menu_td_right}>
                    <div
                      className={
                        this.state.formData.company == ErrorCode.NONE
                          ? styles.error
                          : styles.errorNone
                      }
                    >
                      会社・団体名を入力してください。
                    </div>
                    <input
                      type="text"
                      className={styles.inputRequired}
                      onChange={(e) => this.changeCompany(e)}
                      placeholder="例)　三菱電機株式会社"
                      defaultValue={this.state.formDataValue.company}
                    ></input>
                  </td>
                </tr>
                <tr className={styles.entry_menu_tr_2line}>
                  <td className={styles.entry_menu_td_left}>郵便番号</td>
                  <td className={styles.entry_menu_td_right}>
                    <div
                      className={
                        this.state.formData.postCode == ErrorCode.INVALID
                          ? styles.error
                          : styles.errorNone
                      }
                    >
                      有効な郵便番号ではありません。
                    </div>
                    <div
                      className={
                        this.state.searchAddressError
                          ? styles.error
                          : styles.errorNone
                      }

                    >
                      郵便番号から住所を検索できませんでした。
                    </div>
                    <p>(ハイフン[ ‐ ]で区切って半角数字で入力してください)</p>
                    <input
                      type="text"
                      className={styles.inputAddress}
                      onChange={(e) => this.changePostCode(e)}
                      placeholder="例)　100-8310"
                      defaultValue={this.state.formDataValue.postCode}
                    />
                    <input
                      type="button"
                      className={styles.addressButton}
                      value="郵便番号から住所を検索する"
                      onClick={(e) => this.searchAddress(e)}
                      disabled={!this.state.searchAddressEnabled}
                    />
                  </td>
                </tr>
                <tr>
                  <td className={styles.entry_menu_td_left}>
                    ご住所
                    <label className={styles.entry_menu_required}>必須</label>
                  </td>
                  <td className={styles.entry_menu_td_right}>
                    <div
                      className={
                        this.state.formData.address == ErrorCode.NONE
                          ? styles.error
                          : styles.errorNone
                      }
                    >
                      ご住所を入力してください。
                    </div>
                    <input
                      key={this.state.formDataValue.address}
                      type="text"
                      className={styles.inputRequired}
                      onChange={(e) => this.changeAddress(e)}
                      placeholder="例)　東京都千代田区丸の内2-7-3"
                      defaultValue={this.state.formDataValue.address}
                    ></input>
                  </td>
                </tr>
              </tbody>
            </table>
            <table className={styles.entry_menu_address}>
              <tbody>
                <tr>
                  <td className={styles.entry_menu_td_left}>
                    部署名
                    <label className={styles.entry_menu_required}>必須</label>
                  </td>
                  <td className={styles.entry_menu_td_right}>
                    <div
                      className={
                        this.state.formData.department == ErrorCode.NONE
                          ? styles.error
                          : styles.errorNone
                      }
                    >
                      部署名を入力してください。
                    </div>
                    <input
                      type="text"
                      className={styles.inputRequired}
                      onChange={(e) => this.changeDepartment(e)}
                      placeholder="例)　電力事業部　営業課"
                      defaultValue={this.state.formDataValue.department}
                    ></input>
                  </td>
                </tr>
                <tr>
                  <td className={styles.entry_menu_td_left}>
                    役職名
                    <label className={styles.entry_menu_required}>必須</label>
                  </td>
                  <td className={styles.entry_menu_td_right}>
                    <div
                      className={
                        this.state.formData.position == ErrorCode.NONE
                          ? styles.error
                          : styles.errorNone
                      }
                    >
                      役職名を入力してください。
                    </div>
                    <input
                      type="text"
                      className={styles.inputRequired}
                      onChange={(e) => this.changePosition(e)}
                      placeholder="例)　課長"
                      defaultValue={this.state.formDataValue.position}
                    ></input>
                  </td>
                </tr>
                <tr>
                  <td className={styles.entry_menu_td_left}>
                    お名前
                    <label className={styles.entry_menu_required}>必須</label>
                  </td>
                  <td className={styles.entry_menu_td_right}>
                    <div
                      className={
                        this.state.formData.name == ErrorCode.NONE
                          ? styles.error
                          : styles.errorNone
                      }
                    >
                      お名前を入力してください。
                    </div>
                    <input
                      type="text"
                      className={styles.inputRequired}
                      onChange={(e) => this.changeName(e)}
                      placeholder="例)　三菱　太郎"
                      defaultValue={this.state.formDataValue.name}
                    ></input>
                  </td>
                </tr>
                <tr className={styles.entry_menu_tr_2line}>
                  <td className={styles.entry_menu_td_left}>フリガナ</td>
                  <td className={styles.entry_menu_td_right}>
                    <div
                      className={
                        this.state.formData.kana == ErrorCode.INVALID
                          ? styles.error
                          : styles.errorNone
                      }
                    >
                      フリガナは全角で入力してください。
                    </div>
                    <p>(全角で入力してください)</p>
                    <input
                      type="text"
                      onChange={(e) => this.changeKana(e)}
                      placeholder="例)　ミツビシ　タロウ"
                      defaultValue={this.state.formDataValue.kana}
                    ></input>
                  </td>
                </tr>

                <tr className={styles.entry_menu_tr_2line}>
                  <td className={styles.entry_menu_td_left}>
                    メールアドレス
                    <label className={styles.entry_menu_required}>必須</label>
                  </td>
                  <td className={styles.entry_menu_td_right}>
                    <div
                      className={
                        this.state.formData.email == ErrorCode.NONE
                          ? styles.error
                          : styles.errorNone
                      }
                    >
                      メールアドレスを入力してください。
                    </div>
                    <div
                      className={
                        this.state.formData.email == ErrorCode.INVALID
                          ? styles.error
                          : styles.errorNone
                      }
                    >
                      有効なメールアドレスではありません。
                    </div>
                    <p>(半角で入力してください)</p>
                    <input
                      type="text"
                      className={styles.inputRequired}
                      onChange={(e) => this.changeEmail(e)}
                      placeholder="例)　Mitsubishi.Taro@MitsubishiElectric.co.jp"
                      defaultValue={this.state.formDataValue.email}
                    ></input>
                  </td>
                </tr>
                <tr className={styles.entry_menu_tr_2line}>
                  <td className={styles.entry_menu_td_left}>
                    お電話番号
                    <label className={styles.entry_menu_required}>必須</label>
                  </td>
                  <td className={styles.entry_menu_td_right}>
                    <div
                      className={
                        this.state.formData.telephone == ErrorCode.NONE
                          ? styles.error
                          : styles.errorNone
                      }
                    >
                      お電話番号を入力してください。
                    </div>
                    <div
                      className={
                        this.state.formData.telephone == ErrorCode.INVALID
                          ? styles.error
                          : styles.errorNone
                      }
                    >
                      有効な電話番号ではありません。
                    </div>
                    <p>(ハイフン[ ‐ ]で区切って半角数字で入力してください)</p>
                    <input
                      type="text"
                      className={styles.inputRequired}
                      onChange={(e) => this.changeTelephone(e)}
                      placeholder="例)　03-XXXX-XXXX"
                      defaultValue={this.state.formDataValue.telephone}
                    ></input>
                  </td>
                </tr>
                <tr className={styles.entry_menu_tr_2line}>
                  <td className={styles.entry_menu_td_left}>FAX番号</td>
                  <td className={styles.entry_menu_td_right}>
                    <div
                      className={
                        this.state.formData.FAX == ErrorCode.INVALID
                          ? styles.error
                          : styles.errorNone
                      }
                    >
                      有効なFAX番号ではありません。
                    </div>
                    <p>(ハイフン[ ‐ ]で区切って半角数字で入力してください)</p>
                    <input
                      type="text"
                      onChange={(e) => this.changeFax(e)}
                      placeholder="例)　03-XXXX-XXXX"
                      defaultValue={this.state.formDataValue.FAX}
                    ></input>
                  </td>
                </tr>
              </tbody>
            </table>
            <table className={styles.entry_menu_select}>
              <tbody>
                <tr className={styles.entry_menu_tr_2line}>
                  <td className={styles.entry_menu_td_left}>
                    会員登録の目的
                    <label className={styles.entry_menu_required}>必須</label>
                  </td>
                  <td className={styles.entry_menu_td_right}>
                    <div
                      className={
                        this.state.formData.purpose == ErrorCode.NONE
                          ? styles.error
                          : styles.errorNone
                      }
                    >
                      会員登録の目的が選択されていません。
                    </div>
                    <select
                      className={styles.inputRequired}
                      onChange={(e) => this.changePurpose(e)}
                      defaultValue={this.state.formDataValue.purpose}
                    >
                      {purposeRenderList}
                    </select>
                    <div
                      className={
                        this.state.purposeTextEnabled
                          ? styles.entry_menu_select_purpose
                          : styles.entry_menu_select_purpose +
                          " " +
                          styles.pointer_events_none
                      }
                    >
                      <label>その他入力欄：</label>
                      <input
                        type="text"
                        onChange={(e) => this.changePurposeOther(e)}
                        defaultValue={this.state.formDataValue.purposeOther}
                      ></input>
                    </div>
                  </td>
                </tr>
                <tr className={styles.entry_menu_tr_2line}>
                  <td className={styles.entry_menu_td_left}>
                    ご希望のサービス
                  </td>
                  <td
                    className={
                      this.state.serviceCheckEnabled
                        ? styles.entry_menu_td_right
                        : styles.entry_menu_td_right
                    }
                  >
                    <div className={styles.entry_menu_select_service}>
                      <div>
                        <input
                          type="checkbox"
                          id="0"
                          onChange={(e) => this.changeService(e)}
                          defaultChecked={
                            this.state.formDataValue.service[0]
                          }
                        />
                        <label>{serviceList[0]}</label>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <table className={styles.entry_menu_privacy}>
              <tbody>
                <tr className={styles.entry_menu_tr_3line}>
                  <td className={styles.entry_menu_td_left}>
                    利用規約について
                    <label className={styles.entry_menu_required}>必須</label>
                  </td>
                  <td className={styles.entry_menu_td_right}>
                    <div
                      className={
                        this.state.formData.term == ErrorCode.NONE
                          ? styles.error
                          : styles.errorNone
                      }
                    >
                      利用規約についてが選択されていません。
                    </div>
                    <p>
                      {
                        "ANALYSYS PORTAL<X-chAIn>会員様向けサイトをご利用いただく際の利用規約について、"
                      }
                      <br />
                      {"ご同意のうえ、次へお進みください。"}
                    </p>
                    <p>
                      <a
                        className={styles.c_bulletArrow_style01}
                        href={
                          "./static/pdf/ANALYSIS PORTAL X-chAIn 利用規約.pdf"
                        }
                        target="_blank"
                      >
                        {
                          "ANALYSYS PORTAL<X-chAIn>会員様向けサイトの利用規約はこちら"
                        }
                      </a>
                    </p>
                    <p className={styles.consent}>
                      <input
                        type="checkbox"
                        onChange={(e) => this.changeTerm(e)}
                        defaultChecked={this.state.formDataValue.term}
                      />
                      <label>同意する</label>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <table className={styles.entry_menu_term}>
              <tbody>
                <tr>
                  <td className={styles.entry_menu_term_td}>
                    <p>
                      お問い合わせの前に必ずお読みいただき、ご同意いただける場合は、「個人情報のお取扱いについて同意する」にチェックのうえ、
                      確認画面へお進みください。この同意はお客様の任意でご判断いただけます。ただし、同意いただけない場合は、お問い合わせを受け付けることはできません。
                    </p>
                    <p className={styles.entry_menu_term_bold}>
                      個人情報のお取扱い
                    </p>
                    <p>
                      お問い合わせの際にご記入いただきましたお客様の個人情報（氏名、メールアドレス、電話番号など）につきまして、下記のとおり責任を持って管理いたします。
                    </p>
                    <p>
                      <ul>
                        <li>
                          当社は、お客様から送信されたご意見・ご要望への対応およびその確認などにお客様の個人情報を利用させていただき、この目的のためにご意見・ご要望の記録を残すことがあります。
                          <br />
                          または、ご意見・ご要望に対し適切な回答をするために、当社関係会社に個人情報を含むご意見・ご要望内容を転送する
                          <br />
                          ことがございます。
                        </li>
                        <li>
                          お客様の個人情報につきまして、上記の利用目的以外の目的で、第三者へ開示することはございません。
                        </li>
                        <li>
                          15歳以下のお客様の個人情報については、必ず保護者の方の同意の下に登録していただきますようお願いいたします。
                        </li>
                      </ul>
                    </p>
                    <p
                      style={{
                        margin: "-4px 20px 20px 20px",
                      }}
                    >
                      その他、当該個人情報の訂正や削除等の求めなど個人情報のお取扱いの詳細は当社コーポレートサイト「
                      <a
                        href="https://www.mitsubishielectric.co.jp/privacy/"
                        target="_blank"
                      >
                        個人情報保護方針
                      </a>
                      <img
                        className={"icon-blank"}
                        src={WindowIcon}
                        alt="新しいウィンドウが開きます"
                      ></img>
                      」<br />
                      からご確認いただけます。
                    </p>
                    <p
                      style={{
                        margin: "-4px 20px 20px 20px",
                      }}
                    >
                      なお、個人情報に関するお問い合わせについては、
                      <a
                        href="https://www.mitsubishielectric.co.jp/contact/ssl/php/197/kiyaku.php?fid=197&amp;moto=1347"
                        target="_blank"
                      >
                        こちら
                      </a>
                      <img
                        className={"icon-blank"}
                        src={WindowIcon}
                        alt="新しいウィンドウが開きます"
                      ></img>
                      からお願いします。
                    </p>
                    <p className={styles.entry_menu_term_right1}>
                      三菱電機株式会社
                    </p>
                    <p className={styles.entry_menu_term_right2}>
                      個人情報保護事務局
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className={styles.entry_menu_agree}>
              <label className={styles.entry_menu_agree_red}>※必須</label>
              <input
                type="checkbox"
                onChange={(e) => this.changePersonalInfo(e)}
                defaultChecked={this.state.formDataValue.personalInfo}
              />
              <label>個人情報のお取扱いについて同意する</label>
            </div>
            <div className={styles.entry_menu_confirm}>
              <button
                className={
                  this.state.confirmEnabled
                    ? styles.confirmButton + " " + styles.confirmButtonEnabled
                    : styles.confirmButton + " " + styles.pointer_events_none
                }
                onClick={this.confirm}
              >
                <CircleIcon className={styles.confirmButtonSvg} />
                確認画面へ進む
              </button>
            </div>
            <div className={styles.entry_menu_pageTop}>
              <div>
                <input
                  type="button"
                  className={styles.pageTopButton}
                  value="▲ページトップへ戻る"
                  onClick={this.returnTop}
                />
              </div>
            </div>
            <div className={styles.entry_menu_ssl}>
              当ページはSSLによって保護されています。
            </div>
          </div>
        </div>
      </div>
    );
  }
}
