import AddchartIcon from "@mui/icons-material/Addchart";
import BuildIcon from "@mui/icons-material/Build";
import DescriptionIcon from "@mui/icons-material/Description";
import DnsIcon from "@mui/icons-material/Dns";
import HelpIcon from "@mui/icons-material/Help";
import TimelineIcon from "@mui/icons-material/Timeline";
import Button from "@mui/material/Button";
// ダイアログ
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import React from "react";
import { Menu, MenuItem, ProSidebar, SubMenu } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import "./Slidebar.css";
import styles from "./Slidebar.module.scss";
import UserInfo from "./UserInfo";
import InputEvent from "../../common/utility/InputEvent";

/**
 * State
 */
interface SlidebarState {
  close: boolean;
  trialDia: boolean;
}

/**
 * Props
 */
interface slideBarProp {
  close: boolean;
  isSideSubMenuOpen: boolean;
  setSideSubMenuOpenStatus: (isOpen: boolean) => void;
  trial: boolean;
  select: string;
  moveMenu: boolean;
  onMenuSelect: (menuItem: string) => void;
}

/**
 * サイドバー部品
 */
export class Slidebar extends React.Component<slideBarProp, SlidebarState> {
  /**
   * コンストラクタ
   * @param props Props
   */
  constructor(props: slideBarProp) {
    super(props);

    this.state = {
      close: this.props.close,
      trialDia: false,
    };
    console.log("isSideSubMenuOpen:", this.props.isSideSubMenuOpen);
    this.openReportList = this.openReportList.bind(this);
    this.keyDown = this.keyDown.bind(this);
  }

  /**
   * 選択中のメニューを得る
   * @returns 選択中のメニュー
   */
  getSelectMenu() {
    // 項目リスト
    let slideSelect = [
      "analysis",
      "analysisModel",
      "report",
      "database",
      "model",
    ];

    let resData = new Array();

    for (let i in slideSelect) {
      // 項目が一致すれば
      if (this.props.select == slideSelect[i]) {
        resData.push(styles.selectedMenu);
      } else {
        resData.push("");
      }
    }
    return resData;
  }

  /**
   *
   * @param open
   */
  trialDia(open: boolean) {
    this.setState({
      trialDia: open,
    });
  }

  createTrialDiv() {
    return (
      <div id="helpIcon">
        <HelpIcon
          tabIndex={0}
          onClick={() => this.trialDia(true)}
          onKeyDown={
            (e) => {
              this.keyDown(e, () => this.trialDia(true));
            }
          }
        />
      </div>
    );
  }

  /**
   * キー押下時処理
   * 
   * @param e キー押下イベント
   * @param f 実行ファンクション
   */
  keyDown(e: any, f: any) {
    if (InputEvent.checkClickEnter(e)) {
      f();
    }
  }

  /**
   * レポート画面表示
   */
  openReportList() {
    this.props.onMenuSelect("report");
  }

  /**
   * サブメニューの開閉切り替え
   */
  toggleSubMenuOpenStatus() {
    this.props.setSideSubMenuOpenStatus(!this.props.isSideSubMenuOpen);
  }

  /**
   * 描画処理
   * @returns
   */
  render(): React.ReactNode {
    //　スライドバーを閉じるとき
    let none: any | undefined;
    let icon: string | undefined;

    if (this.props.close) {
      none = styles.barClose;
      icon = "iconClose";
    }

    // トライアル版のときは機能を制限する
    let SubMenuId: string;
    let clickAnalysis: () => void;
    let clickModel: () => void;
    let trialDiv: JSX.Element | undefined;
    let MenuItemId: string;

    // トライアル版
    if (this.props.trial) {
      SubMenuId = "SlidebarSubMenuItem_disable";
      clickAnalysis = () => {
        this.props.onMenuSelect("analysis");
      };
      clickModel = () => { };
      trialDiv = this.createTrialDiv();
      MenuItemId = "disableMenu";

      // 通常版
    } else {
      SubMenuId = "SlidebarSubMenuItem";

      clickAnalysis = () => {
        this.props.onMenuSelect("analysis");
      };

      clickModel = () => {
        //alert("comming soon");
        //this.props.onMenuSelect("model");
      };

      trialDiv = undefined;

      MenuItemId = "";
    }

    let clickDataBase = () => {
      //alert("comming soon");
    };

    let clickAnalysisModel = () => {
      //alert("comming soon");
      //this.props.onMenuSelect("analysisModel");
    };

    let selectMenu = this.getSelectMenu();

    // メニューをスクロールに追従させるか？
    let moveMenu = undefined;
    if (this.props.moveMenu) {
      moveMenu = styles.moveMenu;
    }

    return (
      <div className={styles.SlideBar + " " + none}>
        <ProSidebar collapsed={this.props.close}>
          <Menu className={moveMenu}>
            <MenuItem className={styles.menuTitle} icon={<TimelineIcon />}>
              <span lang="en">
                Abnormal Detection
              </span>
            </MenuItem>
            <div className={styles.userInfo}>
              {/**ユーザ情報 */}
              <UserInfo close={this.props.close}></UserInfo>
            </div>
            <SubMenu
              className={icon + " " + selectMenu[0] + " " + selectMenu[1]}
              title="分析"
              icon={<AddchartIcon />}
              open={this.props.isSideSubMenuOpen}
              onClick={() => {
                // サブメニューの開閉切り替え
                this.toggleSubMenuOpenStatus();
              }}
            >
              <MenuItem
                onClick={(e) => {
                  // クリック処理の伝搬を抑止しておく
                  // ※これがないとサブメニューが閉じてしまう
                  e.stopPropagation();
                  clickAnalysis();
                }}
                onKeyDown={
                  (e) => {
                    this.keyDown(e, clickAnalysis);
                  }
                }
                id="SlidebarSubMenuItem"
              >
                波形比較
              </MenuItem>
              {/** 
              <MenuItem
                onClick={(e) => {
                  // クリック処理の伝搬を抑止しておく
                  // ※これがないとSubMenuが閉じてしまう
                  e.stopPropagation();
                  clickAnalysisModel();
                }}
                onKeyDown={
                  (e) => {
                    this.keyDown(e, clickAnalysisModel);
                  }
                }
                id={SubMenuId}
              >
                <div
                  className={
                    this.props.trial
                      ? styles.flex + " " + styles.disabled_color
                      : styles.flex
                  }
                >
                  異常兆候分析
                  {trialDiv}
                </div>
              </MenuItem>*/}
            </SubMenu>
            <MenuItem
              className={icon + " " + selectMenu[2]}
              icon={<DescriptionIcon />}
              onClick={() => this.openReportList()}
              onKeyDown={
                (e) => {
                  this.keyDown(e, this.openReportList);
                }
              }
            >
              レポート一覧
            </MenuItem>
            {/**            <MenuItem
              className={icon + " " + selectMenu[3]}
              icon={<DnsIcon />}
              id={MenuItemId}
              onClick={clickDataBase}
              onKeyDown={
                (e) => {
                  this.keyDown(e, clickDataBase);
                }
              }
            >
              <div className={styles.flex}>
                データベース
                {trialDiv}
              </div>
            </MenuItem>
            <MenuItem
              id={MenuItemId}
              className={icon + " " + styles.disableMenu + " " + selectMenu[4]}
              icon={<BuildIcon />}
              onClick={clickModel}
              onKeyDown={
                (e) => {
                  this.keyDown(e, clickModel);
                }
              }
            >
              <div className={styles.flex}>
                モデル
                {trialDiv}
              </div>
            </MenuItem> */}
          </Menu>

        </ProSidebar>
        {/**　削除の確認ダイアログここ部品化要 */}
        <div>
          <Dialog
            open={this.state.trialDia}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"本機能は本プランでは使用できません。"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                問い合わせページからスタンダードプランへの
                <br />
                アップグレードをお申し込みください。
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => this.trialDia(false)}>閉じる</Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    );
  }
}

export default Slidebar;

/*<Props>*/
