import { CognitoUser } from "amazon-cognito-identity-js";
import { Amplify, API, APIClass, Auth } from "aws-amplify";
import Config from "../config/config.json";

/**
 * 認証トークン保存先種別
 */
export const AWS_TOKEN_SAVE = {
  /** セッションストレージ */
  SESSION_STORAGE: "session",
  /** ローカルストレージ */
  LOCAL_STORAGE: "local",
};

/**
 * aws-amplifyが提供するAPIをラップするユーティリティ
 */
export class AwsAmplifyUtils {
  /**
   * コンフィグ設定
   */
  static configure() {
    Amplify.configure({
      Auth: Config["aws-amplify"].Auth,
      API: Config["aws-amplify"].API,
    });
    let setting = this.getAuthStorageType();
    this.setAuthStorageType(setting);
  }

  /**
   * 認証トークン保存先を得る
   *
   * @returns AWS_TOKEN_SAVE
   */
  static getAuthStorageType() {
    let setting = localStorage.getItem("AwsAmplifyStorageSetting");
    let storage = AWS_TOKEN_SAVE.SESSION_STORAGE;
    if (setting === AWS_TOKEN_SAVE.LOCAL_STORAGE) {
      storage = setting;
    }
    return storage;
  }

  /**
   * 認証トークン保存先を設定
   *
   * @param type AWS_TOKEN_SAVE
   */
  static setAuthStorageType(type: string) {
    switch (type) {
      case AWS_TOKEN_SAVE.SESSION_STORAGE:
        Auth.configure({ storage: sessionStorage });
        localStorage.setItem("AwsAmplifyStorageSetting", type);
        break;
      case AWS_TOKEN_SAVE.LOCAL_STORAGE:
        Auth.configure({ storage: localStorage });
        localStorage.setItem("AwsAmplifyStorageSetting", type);
        break;
    }
  }

  /**
   * 認証ユーザ情報を得る
   *
   * @returns Primise
   * @throws 認証されていない場合は例外
   */
  static checkAuthenticated() {
    return Auth.currentAuthenticatedUser();
  }

  /**
   * ログイン有無を得る
   *
   * @returns ログイン有無
   */
  static async isLogged() {
    try {
      await Auth.currentAuthenticatedUser();
      return true;
    } catch (err) {
      return false;
    }
  }

  /**
   * 現在のセッション情報を得る
   *
   * @returns Promise
   * @throws 有効なセッションがない場合は例外
   */
  static checkCurrentSession() {
    return Auth.currentSession();
  }

  /**
   * ログイン
   *
   * @param userId ユーザID
   * @param userPassword ユーザパスワード
   * @returns Promise
   */
  static signIn(userId: string, userPassword: string) {
    return Auth.signIn({
      username: userId,
      password: userPassword,
    });
  }

  /**
   * ログアウト
   *
   * @returns Promise
   */
  static signOut() {
    return Auth.signOut();
  }
  static async currentUserInfo() {
    return await Auth.currentUserInfo();
  }

  /**
   * パスワード変更
   *
   * @param user ユーザ情報
   * @param oldpw 旧パスワード
   * @param newpw 新パスワード
   * @returns Promise
   */
  static changePassword(user: CognitoUser, oldpw: string, newpw: string) {
    return Auth.changePassword(user, oldpw, newpw);
  }

  /**
   * 初回パスワード設定
   *
   * @param user ユーザ情報
   * @param newpw 新パスワード
   * @returns Promise
   */
  static completePassword(user: CognitoUser, newpw: string) {
    return Auth.completeNewPassword(user, newpw);
  }

  /**
   * ログイン中のユーザーのCognitoの顧客IDを得る
   * @returns  Promise<顧客ID>
   */
  public static getCognitoGroup(): Promise<string> {
    return new Promise((resolve, reject) => {
      Auth.currentSession().then((user) => {
        const { payload } = user.getIdToken();
        if (
          payload &&
          payload["cognito:groups"] &&
          payload["cognito:groups"].length > 0
        ) {
          resolve(payload["cognito:groups"][0]);
        } else {
          reject();
        }
      });
    });
  }

  /**
   * APIを呼ぶ
   * @param isPublic パブリックAPIか？
   * @returns Promise<APIクラスのインスタンス, プライベート/ブリックAPI用プレフィックス
   */
  public static callAPI(
    isPublic: boolean = false
  ): Promise<{ API: APIClass; apiPrefix: string }> {
    if (isPublic) {
      return new Promise((resolve, reject) => {
        resolve({ API: API, apiPrefix: "/public" });
      });
    } else {
      return new Promise((resolve, reject) => {
        this.getCognitoGroup().then((user) => {
          resolve({ API: API, apiPrefix: "/" + user });
        });
      });
    }
  }
}
