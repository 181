import React from "react";
import styles from "./EntryConfirm.module.scss";
import {
  IUploadViewProps,
  Contents,
  ContentsValue,
  industryList,
  purposeList,
  serviceList,
} from "./Entry";
import { useNavigate, useLocation } from "react-router-dom";

import {
  BreadcrumbHeader,
  Breadcrumb,
  BreadcrumbList,
} from "../common/components/BreadcrumbHeader";
import { AwsAmplifyUtils } from "../common/components/AwsAmplifyUtils";

// hookを使用するためクラスを関数でラップ
export default function EntryConfirm(props: any) {
  // useNavigationを取得し、クラスにnavigationを渡す
  const navigate = useNavigate();
  // useLocationを取得し、クラスにlocationを渡す
  const location = useLocation();

  return (
    <EntryConfirmClass {...props} navigate={navigate} location={location} />
  );
}

class EntryConfirmClass extends React.Component<any, IUploadViewProps> {
  constructor(props: any) {
    super(props);
    this.state = props.location.state;
  }

  set = () => {
    var mailServiceList = [];
    var formDataValue = this.state.formDataValue;
    var index = 0;
    var industryText = "";
    var purposeText = "";

    // 希望のサービス
    for (var service in serviceList) {
      if (formDataValue.service[index]) {
        mailServiceList.push(serviceList[service]);
      }
      index++;
    }

    // 会員登録の目的が「その他」
    if (formDataValue.purpose == "3") {
      purposeText =
        purposeList[Number(formDataValue.purpose)] +
        "\n" +
        formDataValue.purposeOther;
    } else {
      purposeText = purposeList[Number(formDataValue.purpose)];
    }

    // 現在ステータスを取得
    var httpBody = {
      industry: industryList[Number(formDataValue.industry)],
      company: formDataValue.company,
      postcode: formDataValue.postCode ? formDataValue.postCode : "未入力",
      address: formDataValue.address,
      department: formDataValue.department,
      position: formDataValue.position,
      name: formDataValue.name,
      kana: formDataValue.kana ? formDataValue.kana : "未入力",
      email: formDataValue.email,
      telephone: formDataValue.telephone,
      fax: formDataValue.FAX ? formDataValue.FAX : "未入力",
      purpose: purposeText,
      service: mailServiceList,
    };

    // 申し込みメール送信APIを実行
    AwsAmplifyUtils.callAPI(true)
      .then((api) => {
        return api.API.post("api", api.apiPrefix + "/entrymanager/entry", {
          body: httpBody,
          headers: { "Content-Type": "application/json" },
        });
      })
      .then((responseJson) => {
        if (responseJson.errorCode != "OK") {
          alert(
            "申し込み処理でエラーが発生しました。時間をおいて再度操作をお願いします。"
          );
        }
        // 申し込み完了画面に遷移
        window.location.href = "../entryComplete";
      })
      .catch((error) => {
        alert(
          "申し込み処理でエラーが発生しました。時間をおいて再度操作をお願いします。"
        );
        // 申し込み完了画面に遷移
        window.location.href = "../entryComplete";
      });
  };

  changeInput = () => {
    this.returnTop();
    this.props.navigate("../entry", {
      state: this.state,
    });
  };

  returnTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  renderService() { }

  render() {
    var serviceRenderList = [];
      for (var i in serviceList) {
        serviceRenderList.push(
          <p>{this.state.formDataValue.service[i] ? serviceList[i] : ""}</p>
        );
      }

    return (
      <div
        style={{
          fontSize: "16px !important",
          height: "100%",
          maxWidth: "1440px !important",
          margin: "0 auto",
          textAlign: "center",
        }}
      >
        {/*パンクズリスト className={}*/}
        <div>
          <BreadcrumbHeader
            breadcrumb={this.state.breadcrumb}
          ></BreadcrumbHeader>
        </div>
        <div className={styles.entry_div_outside}>
          <div className={styles.entry_title}>
            ANALYSIS PORTAL&nbsp;&lt;X-chAIn&gt; サイト会員申し込み
          </div>
          <table className={styles.entry_state_table}>
            <tbody>
              <tr>
                <td className={styles.entry_state_td_state}>
                  1.必須情報の入力
                </td>
                <td>
                  <label className={styles.c_bulletLargeArrow_style01}></label>
                </td>
                <td
                  className={
                    styles.entry_state_td_state +
                    " " +
                    styles.entry_state_td_state_now
                  }
                >
                  2.確認
                </td>
                <td>
                  <label className={styles.c_bulletLargeArrow_style01}></label>
                </td>
                <td className={styles.entry_state_td_state}>3.完了</td>
              </tr>
            </tbody>
          </table>
          <div className={styles.entry_menu}>
            <div className={styles.entry_menu_title}>2.確認</div>
            <div className={styles.entry_menu_info}>
              <p>
                ご入力いただいた内容をご確認のうえ、よろしければページ下の「登録する」ボタンを押してください。
              </p>
              <p>
                入力内容を変更したい場合は、ページ下の「入力内容を変更する」ボタンを押して下さい。
              </p>
            </div>
            <table className={styles.entry_menu_name}>
              <tbody>
                <tr>
                  <td className={styles.entry_menu_td_left}>業種</td>
                  <td className={styles.entry_menu_td_right}>
                    <label>
                      {this.state.formDataValue.industry == "0"
                        ? "未入力"
                        : industryList[
                        Number(this.state.formDataValue.industry)
                        ]}
                    </label>
                  </td>
                </tr>
                <tr>
                  <td className={styles.entry_menu_td_left}>会社・団体名</td>
                  <td className={styles.entry_menu_td_right}>
                    <label>{this.state.formDataValue.company}</label>
                  </td>
                </tr>
                <tr className={styles.entry_menu_tr_2line}>
                  <td className={styles.entry_menu_td_left}>郵便番号</td>
                  <td className={styles.entry_menu_td_right}>
                    <label>
                      {this.state.formDataValue.postCode
                        ? this.state.formDataValue.postCode
                        : "未入力"}
                    </label>
                  </td>
                </tr>
                <tr>
                  <td className={styles.entry_menu_td_left}>ご住所</td>
                  <td className={styles.entry_menu_td_right}>
                    <label>{this.state.formDataValue.address}</label>
                  </td>
                </tr>
              </tbody>
            </table>
            <table className={styles.entry_menu_address}>
              <tbody>
                <tr>
                  <td className={styles.entry_menu_td_left}>部署名</td>
                  <td className={styles.entry_menu_td_right}>
                    <label>{this.state.formDataValue.department}</label>
                  </td>
                </tr>
                <tr>
                  <td className={styles.entry_menu_td_left}>役職名</td>
                  <td className={styles.entry_menu_td_right}>
                    <label>{this.state.formDataValue.position}</label>
                  </td>
                </tr>
                <tr>
                  <td className={styles.entry_menu_td_left}>お名前</td>
                  <td className={styles.entry_menu_td_right}>
                    <label>{this.state.formDataValue.name}</label>
                  </td>
                </tr>
                <tr className={styles.entry_menu_tr_2line}>
                  <td className={styles.entry_menu_td_left}>フリガナ</td>
                  <td className={styles.entry_menu_td_right}>
                    <label>
                      {this.state.formDataValue.kana
                        ? this.state.formDataValue.kana
                        : "未入力"}
                    </label>
                  </td>
                </tr>

                <tr className={styles.entry_menu_tr_2line}>
                  <td className={styles.entry_menu_td_left}>メールアドレス</td>
                  <td className={styles.entry_menu_td_right}>
                    <label>{this.state.formDataValue.email}</label>
                  </td>
                </tr>
                <tr className={styles.entry_menu_tr_2line}>
                  <td className={styles.entry_menu_td_left}>お電話番号</td>
                  <td className={styles.entry_menu_td_right}>
                    <label>{this.state.formDataValue.telephone}</label>
                  </td>
                </tr>
                <tr className={styles.entry_menu_tr_2line}>
                  <td className={styles.entry_menu_td_left}>FAX番号</td>
                  <td className={styles.entry_menu_td_right}>
                    <label>
                      {this.state.formDataValue.FAX
                        ? this.state.formDataValue.FAX
                        : "未入力"}
                    </label>
                  </td>
                </tr>
              </tbody>
            </table>
            <table className={styles.entry_menu_select}>
              <tbody>
                <tr className={styles.entry_menu_tr_2line}>
                  <td className={styles.entry_menu_td_left}>会員登録の目的</td>
                  <td className={styles.entry_menu_td_right}>
                    <label>
                      {this.state.formDataValue.purpose == "0" ? (
                        "未入力"
                      ) : this.state.formDataValue.purpose == "3" ? (
                        <React.Fragment>
                          <p>{"その他"}</p>
                          <p>{this.state.formDataValue.purposeOther}</p>
                        </React.Fragment>
                      ) : (
                        purposeList[Number(this.state.formDataValue.purpose)]
                      )}
                    </label>
                  </td>
                </tr>
                <tr className={styles.entry_menu_tr_2line}>
                  <td className={styles.entry_menu_td_left}>
                    ご希望のサービス
                  </td>
                  <td className={styles.entry_menu_td_right}>
                    {serviceRenderList}
                  </td>
                </tr>
              </tbody>
            </table>
            <table className={styles.entry_menu_privacy}>
              <tbody>
                <tr className={styles.entry_menu_tr_3line}>
                  <td className={styles.entry_menu_td_left}>
                    利用規約について
                  </td>
                  <td className={styles.entry_menu_td_right}>
                    <label>
                      {this.state.formDataValue.term
                        ? "同意する"
                        : "同意しない"}
                    </label>
                  </td>
                </tr>
              </tbody>
            </table>
            <table className={styles.entry_menu_privacy}>
              <tbody>
                <tr className={styles.entry_menu_tr_3line}>
                  <td className={styles.entry_menu_td_left}>
                    個人情報のお取り扱い
                  </td>
                  <td className={styles.entry_menu_td_right}>
                    <label>
                      {this.state.formDataValue.personalInfo
                        ? "同意する"
                        : "同意しない"}
                    </label>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className={styles.entry_menu_confirm}>
              <button className={styles.set} onClick={this.set}>
                登録する
              </button>
              <button className={styles.change} onClick={this.changeInput}>
                入力内容を変更する
              </button>
            </div>
            <div className={styles.entry_menu_pageTop}>
              <div>
                <input
                  type="button"
                  className={styles.pageTopButton}
                  value="▲ページトップへ戻る"
                  onClick={this.returnTop}
                />
              </div>
            </div>
            <div className={styles.entry_menu_ssl}>
              当ページはSSLによって保護されています。
            </div>
          </div>
        </div>
      </div>
    );
  }
}
